import FlowCSS from "../../../styles/pages/Flow.module.css";
import { Handle } from "@xyflow/react";
import { useStore } from "../store";
import SideCircle from "../components/SideCircle";
import { ButtonShow } from "../containerNodes/utils/Button";

export const selector = (id, data) => (store) => ({
  setExpand: () => {
    store.updateNode(id, { general: { ...data.general, expand: !data.general.expand } });
  },
  setView: () => {
    store.setView("view1", id);
  },
  setVisible: () => {
    store.setSelfEdgeVisible(id);
  },
  node: store.getNode(id),
  container: store.getContainer(id),
});

export default function ContContextNode({ id, data }) {
  const {
    // setExpand,
    setView,
    setVisible,
    container,
  } = useStore(selector(id, data));

  return (
    <div className={FlowCSS.node}>
      <Handle type="target" position="top" />

      <SideCircle container={container} />

      <div>
        <div className={FlowCSS.container_header}>
          <h2>{data.general.description}</h2>
          <ButtonShow onClick={setVisible} />
          {/* <button onClick={setExpand}>{data.general.expand ? "🔼" : "🔽"}</button> */}
        </div>

        <button onClick={() => setView()}>🔍</button>
      </div>

      <Handle type="source" position="bottom" />
    </div>
  );
}
