import { createContext, useCallback } from "react";
import { useDebounce } from "../hooks/useDebounce";
import { useAuthContext } from "../auth/useAuthContext";
import { useAxiosPrivate } from "../axios/useAxiosPrivate";

import { useStore } from "../pages/flow/store";
import { usePriorYearsStore } from "../stores/usePriorYearsStore";
import { useStatementsStore } from "../stores/useStatementsStore";
import { useStatementsAnnualStore } from "../stores/useStatementsAnnualStore";
import { useActualStore } from "../stores/useActualStore";
import { useDifferencesStore } from "../stores/useDifferencesStore";
import { useValuationStore } from "../stores/useValuationStore";
import { useDashboardStore } from "../stores/useDashboardStore";
import { useReportStore } from "../stores/useReportStore";

import { create } from "zustand";
export const useSaveStore = create((set, get) => ({
  isSaved: true,
  setIsSaved(isSaved) {
    if (!get().isSaved && !isSaved) return;
    set({ isSaved });
  },
}));

export const SaveContext = createContext(null);

export const SaveContextProvider = ({ children }) => {
  const { user } = useAuthContext();
  const axiosPrivate = useAxiosPrivate();

  const { isSaved, setIsSaved } = useSaveStore();

  const onSave = async () => {
    if (!user) {
      return;
    }

    const response1 = await axiosPrivate.post("/api/flows", {
      assumptions: useStore.getState().assumptions,
      nodes: useStore.getState().nodes,
      edges: useStore.getState().edges,
      priorYears: usePriorYearsStore.getState().priorYears,
    });
    const response2 = await axiosPrivate.post("/api/statements", {
      statements: useStatementsStore.getState().statements,
      statementsAnnual: useStatementsAnnualStore.getState().statementsAnnual,
      actual: useActualStore.getState().actual,
      differences: useDifferencesStore.getState().differences,
      valuation: useValuationStore.getState().valuation,
    });
    const response3 = await axiosPrivate.post("/api/dashboards", {
      dashboard: useDashboardStore.getState().dashboard,
    });
    const response4 = await axiosPrivate.post("/api/reports", {
      report: useReportStore.getState().report,
    });

    if (response1.status === 200 && response2.status === 200 && response3.status === 200 && response4.status === 200) {
      setIsSaved(true);
      console.log("Success - save");
      console.log("save1", response1.data);
      console.log("save2", response2.data);
      console.log("save3", response3.data);
      console.log("save4", response4.data);
    }
  };

  // auto save in 10 sec (in milliseconds)
  const debouncedSave = useDebounce(onSave, 10 * 1000);
  const autoSave = useCallback(() => {
    setIsSaved(false);
    debouncedSave();
  }, [debouncedSave, setIsSaved]);

  const contextValue = {
    isSaved,
    autoSave,
    onSave,
  };

  return <SaveContext.Provider value={contextValue}>{children}</SaveContext.Provider>;
};
