import styles from "./styles.module.css";
import { useEffect } from "react";
import { useStatementsStore } from "../../stores/useStatementsStore";
import { useGeneralStore } from "../../stores/useGeneralStore";

const SelectContext = () => {
  const statements = useStatementsStore((state) => state.statements);
  const contextId = useGeneralStore((state) => state.contextId);
  const setContextId = useGeneralStore.getState().setContextId;

  useEffect(() => {
    const contextIdExists = statements.some((statement) => statement.contextId === contextId);
    if (!contextIdExists) {
      if (statements.length > 0) {
        setContextId(statements[0].contextId);
      } else {
        setContextId(null);
      }
    }
  }, [statements, contextId, setContextId]);

  const handleSelectStatement = (contextId) => {
    setContextId(contextId === "" ? null : contextId);
  };

  return (
    <div className={styles.main}>
      {contextId === null ? (
        <span>No context</span>
      ) : (
        <>
          <span>Select Context: </span>
          <select name="select" value={contextId || ""} onChange={(e) => handleSelectStatement(e.target.value)}>
            {statements.map((statement) => (
              <option key={statement.contextId} value={statement.contextId}>
                {statement.description}
              </option>
            ))}
          </select>
        </>
      )}
    </div>
  );
};

export default SelectContext;
