import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

export const ButtonAdd = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      sx={{
        textTransform: "none",
        width: "100%",
        fontFamily: "Poppins",
      }}
    >
      Add
    </Button>
  );
};

export const ButtonPlus = ({ onClick }) => {
  return (
    <Tooltip title="Edit">
      <span>
        <IconButton
          onClick={onClick}
          sx={{
            height: "100%",
          }}
        >
          <AddRoundedIcon fontSize="inherit" />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export const ButtonDelete = ({ onClick, disabled }) => {
  return (
    <Tooltip title="Delete">
      <span>
        <IconButton
          onClick={onClick}
          disabled={disabled}
          sx={{
            height: "100%",
          }}
        >
          <DeleteRoundedIcon fontSize="inherit" />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export const ButtonEdit = ({ onClick, disabled }) => {
  return (
    <Tooltip title="Edit">
      <span>
        <IconButton
          onClick={onClick}
          disabled={disabled}
          sx={{
            height: "100%",
          }}
        >
          <EditRoundedIcon fontSize="inherit" />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export const ButtonDrag = () => {
  return (
    <Tooltip title="Drag">
      <IconButton>
        <DragIndicatorRoundedIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};

export const ButtonShow = ({ onClick }) => {
  return (
    <Tooltip show="Drag">
      <IconButton onClick={onClick}>
        <SearchRoundedIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};

export const ButtonView = ({ onClick, visibility }) => {
  return (
    <>
      {visibility ? (
        <Tooltip title="Hide">
          <span>
            <IconButton
              onClick={onClick}
              sx={{
                height: "100%",
              }}
            >
              <VisibilityOffRoundedIcon fontSize="inherit" />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <Tooltip title="View">
          <span>
            <IconButton
              onClick={onClick}
              sx={{
                height: "100%",
              }}
            >
              <VisibilityRoundedIcon fontSize="inherit" />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </>
  );
};

export const ButtonDrop = ({ onClick, visibility }) => {
  return (
    <>
      {visibility ? (
        <Tooltip title="Hide">
          <span>
            <IconButton
              onClick={onClick}
              sx={{
                height: "100%",
              }}
            >
              <ArrowDropUpRoundedIcon fontSize="inherit" />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <Tooltip title="View">
          <span>
            <IconButton
              onClick={onClick}
              sx={{
                height: "100%",
              }}
            >
              <ArrowDropDownRoundedIcon fontSize="inherit" />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </>
  );
};
