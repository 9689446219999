import StatementsCSS from "../../styles/pages/Statements.module.css";
import { useActualStore } from "../../stores/useActualStore";
import { selector } from "./SOFP";
import { useGeneralStore } from "../../stores/useGeneralStore";
import { useSaveContext } from "../../saveAndRestore/useSaveContext";

import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "../statements/reactgrid/styles.css";
import { getColumns } from "./reactgrid/getColumns";
import { getRowsSOCF } from "./reactgrid/getRows";

const SOCF = ({ monthsDetails, actual }) => {
  const contextId = useGeneralStore((state) => state.contextId);
  const { autoSave } = useSaveContext();
  const { updateData } = useActualStore(selector(contextId));

  const columns = getColumns({ monthsDetails });
  const rows = getRowsSOCF({ monthsDetails, actual });

  const handleChanges = (changes) => {
    updateData(changes);
    autoSave();
  };

  return (
    <div className={StatementsCSS.tableContainer}>
      <h1>Statement of Cash Flows</h1>
      <div className="outer">
        <div className="reactgrid">
          <ReactGrid
            rows={rows}
            columns={columns}
            onCellsChanged={handleChanges}
            stickyTopRows={3}
            stickyLeftColumns={1}
            enableRangeSelection
          />
        </div>
      </div>
    </div>
  );
};

export default SOCF;
