import StatementsCSS from "../../styles/pages/Statements.module.css";

import Header from "../dashboard/components/Header/Header";
import Route from "./Route";

const Main = () => {
  return (
    <div className={StatementsCSS.main}>
      <Header />
      <Route />
    </div>
  );
};

export default Main;
