import styles from "./styles.module.css";

const SideCircle = ({ container }) => {
  return (
    <div className={styles.side_circle}>
      {container === "context" && <span className={styles.side_circle_general}></span>}

      {container === "blank" && <span className={styles.side_circle_general}></span>}
      {container === "marketing" && <span className={styles.side_circle_marketing}></span>}
      {container === "sales" && <span className={styles.side_circle_revenue}></span>}
      {container === "revenue" && <span className={styles.side_circle_revenue}></span>}
      {container === "staff" && <span className={styles.side_circle_staff}></span>}
      {container === "directCosts" && <span className={styles.side_circle_expenses}></span>}
      {container === "indirectCosts" && <span className={styles.side_circle_expenses}></span>}
      {container === "expenses" && <span className={styles.side_circle_expenses}></span>}
      {container === "profit" && <span className={styles.side_circle_profit}></span>}
      {container === "capex" && <span className={styles.side_circle_capex}></span>}
      {container === "loan" && <span className={styles.side_circle_loan}></span>}
      {container === "receivables" && <span className={styles.side_circle_receivables}></span>}
      {container === "payables" && <span className={styles.side_circle_payables}></span>}
      {container === "shareCap" && <span className={styles.side_circle_equity}></span>}
      {container === "retained" && <span className={styles.side_circle_equity}></span>}
      {container === "cash" && <span className={styles.side_circle_cash}></span>}
      {container === "sofp" && <span className={styles.side_circle_sofp}></span>}
    </div>
  );
};

export default SideCircle;
