import styles from "./styles.module.css";
import Switch from "@mui/material/Switch";
import { useGeneralStore } from "../../stores/useGeneralStore";

const SwitchMonthly = () => {
  const monthly = useGeneralStore((state) => state.monthly);
  const setMonthly = useGeneralStore.getState().setMonthly;

  const handleSwitchMonthly = (event) => {
    setMonthly(event.target.checked);
  };

  return (
    <div className={styles.main}>
      <Switch name="switch" checked={monthly} onChange={handleSwitchMonthly} />
      <p>{monthly ? "Monthly" : "Annually"}</p>
    </div>
  );
};

export default SwitchMonthly;
