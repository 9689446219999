import { generateLong } from "./utilsGenerateStaff";
import { useStore } from "../store";

// export const calculateContStaff = (nodes, node, edges, assumptions, priorYears) => {
//   const { months, startDate, priorLength } = assumptions;
//   const totalMonths = months + priorLength;

//   // PRIORS
//   let priors = [];
//   priorYears.rows.forEach((row) => {
//     if (row[row.length - 1] === node.container) {
//       priors.push(row.slice(0, -1));
//     }
//   });

//   let inputs = [...node.data.inputs];
//   let assignments = [...node.data.assignments];
//   let formulas = [...node.data.formulas];
//   let individuals = [];
//   let outputs = [...node.data.outputs];

//   outputs.forEach((output) => {
//     output.long = Array(totalMonths).fill(0);
//   });

//   // update startDate if empty
//   assignments.forEach((assignment) => {
//     if (assignment.start === "") {
//       assignment.start = startDate;
//     }
//   });

//   // calculate individuals outputs
//   assignments.forEach((assignment, index) => {
//     const { number, salary, burdenRate, start } = assignment;
//     const staffCosts = number * salary * (1 + burdenRate / 100);

//     const [forecastStartYear, forecastStartMonth] = startDate.split("-").map(Number);
//     const [startYear, startMonth] = start.split("-").map(Number);
//     let monthDifference = (startYear - forecastStartYear) * 12 + (startMonth - forecastStartMonth);

//     let individual = {
//       id: assignment.id,
//       description: assignment.description,
//       outputs: [{ description: "Staff costs", long: Array(months).fill(0) }],
//     };

//     for (let i = 0; i < months; i++) {
//       if (i >= monthDifference) {
//         individual.outputs[0].long[i] += staffCosts;
//       }
//     }

//     individuals.push(individual);
//   });

//   // add priorLength b/f 0 into all individuals
//   individuals.forEach((individual) => {
//     individual.outputs.forEach((output) => {
//       const addedZeros = Array(priorLength).fill(0);
//       output.long = addedZeros.concat(output.long);
//     });
//   });

//   // calculate total outputs
//   individuals.forEach((individual) => {
//     individual.outputs.forEach((output, index) => {
//       outputs[index].long = outputs[index].long.map((value, i) => value + output.long[i]);
//     });
//   });

//   // Replace NaN and Infinity values with 0 for individuals
//   individuals.forEach((individual) => {
//     individual.outputs.forEach((output) => {
//       output.long = output.long.map((value) => (!isFinite(value) ? 0 : value));
//     });
//   });

//   // Replace NaN and Infinity values with 0 for outputs
//   outputs.forEach((output) => {
//     output.long = output.long.map((value) => (!isFinite(value) ? 0 : value));
//   });

//   // update bfLink
//   outputs.forEach((output) => {
//     if (output.bfLink !== null) {
//       const exists = priors.find((prior) => prior[0] === output.bfLink);
//       if (!exists) {
//         output.bfLink = null;
//       } else {
//         for (let i = 0; i < priorLength; i++) {
//           output.long[i] = exists[i + 1];
//         }
//       }
//     }
//   });

//   return [priors, inputs, assignments, formulas, individuals, outputs];
// };

export const calculateContStaff = (nodes, node, edges, assumptions, priorYearss) => {
  const { months, startDate } = assumptions;
  const priorYears = priorYearss.find((priorYear) => priorYear.contextId === node.contextId);
  const { priorLength } = priorYears;
  const totalMonths = months + priorLength;

  // let priors = [...node.data.priors];
  let inputs = [...node.data.inputs];
  let assignments = [...node.data.assignments];
  let formulas = [...node.data.formulas];
  // let individuals = [...node.data.individuals];
  let outputs = [...node.data.outputs];

  // PRIORS
  let priors = [];
  priorYears.rows.forEach((row) => {
    if (row[row.length - 1] === node.container) {
      priors.push(row.slice(0, -1));
    }
  });

  // ASSIGNMENTS
  // 0. find edges connecting to us
  const myEdges = edges.filter((edge) => edge.target === node.id);
  // 1. update startDate if empty
  assignments = updateStartDate(assignments, startDate);
  // 2. update linked values
  assignments = updateAssignLinked(assignments, nodes, myEdges);
  // 3. fill zero
  assignments.forEach((assignment) => {
    assignment = fillLong(assignment, totalMonths);
  });
  // 4. update custom values
  assignments.forEach((assignment) => {
    assignment = updateAssignCustom(assignment, myEdges, assumptions, priors, priorLength);
  });

  // INDIVIDUALS
  const individuals = calculateIndividuals(assignments, totalMonths);

  // OUTPUTS
  outputs = calculateOutputs(outputs, individuals, totalMonths);
  // update bfLink
  outputs.forEach((output) => {
    if (output.bfLink !== null) {
      const exists = priors.find((prior) => prior[0] === output.bfLink);
      if (!exists) {
        output.bfLink = null;
      } else {
        for (let i = 0; i < priorLength; i++) {
          output.long[i] = exists[i + 1];
        }
      }
    }
  });

  return [priors, inputs, assignments, formulas, individuals, outputs];
};

const updateStartDate = (assignments, startDate) => {
  assignments.forEach((assignment) => {
    if (assignment.custom.start === "") {
      assignment.custom.start = startDate;
    }
  });

  return assignments;
};

const updateAssignLinked = (assignments, nodes, myEdges) => {
  const deleteEdge = (id) => {
    const { onEdgesChange } = useStore.getState();
    onEdgesChange([{ id: id, type: "remove" }]);
  };

  const splitLast = (str) => {
    const lastIndex = str.lastIndexOf("_");
    const targetHandle = str.substring(0, lastIndex);
    const paramIndex = parseInt(str.substring(lastIndex + 1));
    return [targetHandle, paramIndex];
  };

  // Create a map to accumulate long arrays for each targetHandle
  const accumulatedLongs = {};

  myEdges.forEach((edge) => {
    const sourceId = edge.source;
    const sourceHandle = edge.sourceHandle;
    const sourceNode = nodes.find((node) => node.id === sourceId);
    const output = sourceNode.data.outputs.find((output) => output.id === sourceHandle);

    const [targetHandle] = splitLast(edge.targetHandle);
    const assignmentIndex = assignments.findIndex((assignment) => assignment.id === targetHandle);

    if (output && assignmentIndex !== -1) {
      const targetHandle = edge.targetHandle;
      if (!accumulatedLongs[targetHandle]) {
        accumulatedLongs[targetHandle] = Array(output.long.length).fill(0);
      }
      accumulatedLongs[targetHandle] = accumulatedLongs[targetHandle].map((sum, index) => sum + output.long[index]);
    } else {
      deleteEdge(edge.id);
    }
  });

  // Update assignments with the accumulated long arrays
  myEdges.forEach((edge) => {
    const [targetHandle, paramIndex] = splitLast(edge.targetHandle);
    const assignmentIndex = assignments.findIndex((assignment) => assignment.id === targetHandle);

    if (assignmentIndex !== -1) {
      const targetHandle = edge.targetHandle;
      assignments[assignmentIndex].params[paramIndex] = {
        ...assignments[assignmentIndex].params[paramIndex],
        bfLink: null,
        linkCustom: "Link",
        custom: {
          value: 0,
          min: 0,
          max: 0,
          change: 0,
        },
        long: accumulatedLongs[targetHandle],
      };
    }
  });

  return assignments;
};

const fillLong = (assignment, totalMonths) => {
  assignment.params.forEach((param) => {
    let { long, longCustom } = param;

    if (!long) long = [];
    long = long.map((value) => (value === undefined ? 0 : value));

    const filledLong =
      long.length >= totalMonths ? long.slice(0, totalMonths) : [...long, ...Array(totalMonths - long.length).fill(0)];

    const filledLongCustom =
      longCustom.length >= totalMonths
        ? longCustom.slice(0, totalMonths)
        : [...longCustom, ...Array(totalMonths - longCustom.length).fill(null)];

    param.long = filledLong;
    param.longCustom = filledLongCustom;
  });

  return assignment;
};

const updateAssignCustom = (assignment, myEdges, assumptions, priors, priorLength) => {
  const linkedAssign = myEdges.map((edge) => edge.targetHandle);
  assignment.params.forEach((param) => {
    if (!linkedAssign.includes(param.id)) {
      param.linkCustom = "Custom";
      const long = generateLong(param, assumptions, assignment.custom.start, priors, priorLength);
      const roundedLong = long.map((element) => Math.round(element));
      param.long = roundedLong;
    }
  });

  return assignment;
};

const calculateIndividuals = (assignments, totalMonths) => {
  let individuals = [];

  assignments.forEach((assignment) => {
    const numberOfStaffLong = assignment.params[0].long;
    const salaryLong = assignment.params[1].long;
    const burdenRateLong = assignment.params[2].long;

    const long = [];
    for (let i = 0; i < totalMonths; i++) {
      const result = numberOfStaffLong[i] * salaryLong[i] * (1 + burdenRateLong[i] / 100);
      long.push(Math.round(result));
    }

    individuals.push({ description: assignment.description, long: long });
  });

  return individuals;
};

const calculateOutputs = (outputs, individuals, totalMonths) => {
  let long = Array(totalMonths).fill(0);
  individuals.forEach((individual) => {
    for (let i = 0; i < individual.long.length; i++) {
      long[i] += individual.long[i];
    }
  });

  outputs[0].long = long;
  return outputs;
};
