import styles from "./styles.module.css";
import { Link } from "react-router-dom";
import MascotResting from "./MascotResting";

const NoSubscription = () => {
  return (
    <div className={styles.main}>
      <MascotResting />
      <h1>Sorry, this page isn't available for your account.</h1>
      <Link to="/subscription">
        <span>Go to Subscription page</span>
      </Link>
    </div>
  );
};

export default NoSubscription;
