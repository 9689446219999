import { create } from "zustand";

import { updateDashboard } from "../pages/flow/update/updateDashboard";

export const useDashboardStore = create((set, get) => ({
  dashboard: [],

  logout() {
    set({ dashboard: [] });
  },

  setDashboard(data) {
    set({ dashboard: data });

    updateDashboard();
  },
}));
