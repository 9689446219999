import styles from "../styles.module.css";

import HeaderSettings from "./HeaderSettings";
import HeaderGenerateFull from "./HeaderGenerateFull";
import HeaderGenerateEmptySaas from "./HeaderGenerateEmptySaas";
import HeaderTemplate from "./HeaderTemplate";
import HeaderTools from "./HeaderTools";
import HeaderSave from "./HeaderSave";

const Header = ({ view, contextId }) => {
  const style = { fontSize: "2rem" };

  return (
    <div className={styles.header}>
      <HeaderSettings style={style} />
      {view === "view0" && <HeaderGenerateFull />}
      {view === "view1" && <HeaderGenerateEmptySaas contextId={contextId} />}
      {view === "view1" && <HeaderTemplate contextId={contextId} />}
      <HeaderSave />
      <HeaderTools style={style} view={view} contextId={contextId} />
    </div>
  );
};

export default Header;
