const formatDate = (dateString) => {
  const [year, month] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, 1);
  return new Intl.DateTimeFormat("en-US", { month: "short", year: "2-digit" }).format(date);
};

const getDate = (year, month) => {
  const date = new Date(year, month - 1, 1);
  return new Intl.DateTimeFormat("en-US", { month: "short", year: "2-digit" }).format(date);
};

export const getMonthsNames2 = ({ monthsDetails }) => {
  const { startDate, periodEnd, priorYear, priorLength, totalMonths } = monthsDetails;
  let startYear = parseInt(startDate.split("-")[0]);
  const startMonth = parseInt(startDate.split("-")[1]);

  let monthsNames = [];
  let remaining = totalMonths;

  // b/f
  const dateStrings = priorYear.headers[0].slice(1, -1);
  const bfNames = dateStrings.map((dateString) => formatDate(dateString));
  monthsNames.push(...bfNames);
  remaining -= priorLength;

  // forecast - first
  if (remaining > 0) {
    let diff = 0;
    if (periodEnd >= startMonth) {
      diff = periodEnd - startMonth + 1;
    } else {
      diff = periodEnd + 12 - startMonth + 1;
    }

    if (remaining > diff) {
      if (periodEnd >= startMonth) {
        monthsNames.push(getDate(startYear, periodEnd));
      } else {
        startYear += 1;
        monthsNames.push(getDate(startYear, periodEnd));
      }
      startYear += 1;
      remaining -= diff;
    } else {
      monthsNames.push(getDate(startYear, startMonth + remaining - 1));
      remaining -= remaining;
    }
  }

  // forecast - middle
  while (remaining >= 12) {
    monthsNames.push(getDate(startYear, periodEnd));
    startYear += 1;
    remaining -= 12;
  }

  // forecast - last
  if (remaining > 0) {
    monthsNames.push(getDate(startYear, periodEnd + remaining));
    remaining -= remaining;
  }

  return monthsNames;
  // result: "Dec 21", "Dec 22", "Dec 23", "Dec 24", "Dec 24", "Jun 24"
};

export const getMonthsArray2 = ({ monthsDetails }) => {
  // b/f
  const { priorLength, totalPeriod } = monthsDetails;
  const bfArray = Array.from({ length: priorLength }, () => "b/f");

  // forecast
  const forecastArray = Array.from({ length: totalPeriod - priorLength }, (_, i) => (i + 1).toString());

  const monthsArray = [...bfArray, ...forecastArray];
  return monthsArray;
  // result: "b/f", "b/f", "b/f", "1", "2", "3"
};

export const getMonthsNumbers2 = ({ monthsDetails }) => {
  const { startDate, periodEnd, priorYear, priorLength, totalMonths } = monthsDetails;
  const startMonth = parseInt(startDate.split("-")[1]);

  let monthsNumbers = [];
  let remaining = totalMonths;

  // b/f
  const bfNumbers = priorYear.headers[1].slice(1, -1).map(String);
  monthsNumbers.push(...bfNumbers);
  remaining -= priorLength;

  // forecast - first
  if (remaining > 0) {
    let diff = 0;
    if (periodEnd >= startMonth) {
      diff = periodEnd - startMonth + 1;
    } else {
      diff = periodEnd + 12 - startMonth + 1;
    }

    if (remaining > diff) {
      monthsNumbers.push(diff.toString());
      remaining -= diff;
    } else {
      monthsNumbers.push(remaining.toString());
      remaining -= remaining;
    }
  }

  // forecast - middle
  while (remaining >= 12) {
    monthsNumbers.push("12");
    remaining -= 12;
  }

  // forecast - last
  if (remaining > 0) {
    monthsNumbers.push(remaining.toString());
    remaining -= remaining;
  }

  return monthsNumbers;
  // result: "12", "12", "12", "6", "12", "6"
};
