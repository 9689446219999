import RightBarCSS from "../../../../styles/pages/RightBar.module.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export const MonthPickerSimple = ({ item, ques, keyQues, setOutputs }) => {
  return (
    <div className={RightBarCSS.flex}>
      <span className={RightBarCSS.operators_item}>{ques}</span>
      <span className={RightBarCSS.operators_item}>=</span>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={["month", "year"]}
          value={dayjs(item[keyQues])}
          onChange={(date) => {
            const year = date.year();
            const month = date.month() + 1;
            setOutputs(keyQues, `${year}-${month}`);
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export const MonthPicker = ({ item, index, ques, keyQues, setOutputs }) => {
  return (
    <div className={RightBarCSS.flex}>
      <span className={RightBarCSS.operators_item}>{ques}</span>
      <span className={RightBarCSS.operators_item}>=</span>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={["month", "year"]}
          value={dayjs(item[keyQues])}
          onChange={(date) => {
            const year = date.year();
            const month = date.month() + 1;
            setOutputs(index, keyQues, `${year}-${month}`);
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export const MonthPickerNested = ({ item, depthNext, indicesNext, ques, keyQues, setOutputs }) => {
  return (
    <div className={RightBarCSS.flex}>
      <span className={RightBarCSS.operators_item}>{ques}</span>
      <span className={RightBarCSS.operators_item}>=</span>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={["month", "year"]}
          value={dayjs(item[keyQues])}
          onChange={(date) => {
            const year = date.year();
            const month = date.month() + 1;
            setOutputs(depthNext, indicesNext, keyQues, `${year}-${month}`);
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export const MonthPickerBasic = ({ item, index, setOutputs, disabled }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        views={["month", "year"]}
        value={dayjs(item.custom.start)}
        onChange={(date) => {
          const year = date.year();
          const month = date.month() + 1;
          setOutputs(index, "start", `${year}-${month}`);
        }}
        sx={{
          "& .MuiInputBase-root": {
            height: "2.6rem",
            width: "12rem",
          },
        }}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};
