import styles from "./styles.module.css";
import { useState } from "react";
import { useSignup } from "../auth/useSignup";

const Signup = ({ toLoginPage }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signup, error, isLoading } = useSignup();

  const handleSignup = async (e) => {
    e.preventDefault();
    await signup(email, password);
  };

  const handleClick = (e) => {
    e.preventDefault();
    toLoginPage();
  };

  return (
    <div className={styles.login}>
      <p className={styles.login_title}>Sign in to Simplyfi Forecast</p>
      <p className={styles.login_desc}>Create a profile to save your progress!</p>

      <form onSubmit={handleSignup}>
        <label>Sign Up</label>
        <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder="Email" />
        <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} placeholder="Password" />
        <button disabled={isLoading}>Sign Up</button>
        {error && <div className={styles.error}>{error}</div>}
      </form>

      <div className={styles.form_link}>
        <span>
          Have an account?&nbsp;
          <a href="/login" onClick={handleClick}>
            Login
          </a>
        </span>
      </div>
    </div>
  );
};

export default Signup;
