import RightBarCSS from "../../../../styles/pages/RightBar.module.css";
import { useStore } from "../../store";
import { usePriorYearsStore } from "../../../../stores/usePriorYearsStore";
import { nanoid } from "nanoid";
import { AssignmentsBoxCapex, OutputsBoxSpecial } from "./NodeUtils";
import { PriorsBox } from "../utils/RightBarBox";

const selector = (id, data) => (store) => ({
  assumptions: store.assumptions,
  setGeneral: (key, value) => {
    store.updateNode(id, { general: { ...data.general, [key]: value } });
  },
  setAssigns: (index, key, value) => {
    const updated = [...data.assignments];

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    if (key === "type") {
      updated[index] = { ...updated[index], [key]: value, custom: { start: "" } };
      store.updateNode(id, { assignments: updated });
      return;
    }

    if (key === "start") {
      updated[index] = {
        ...updated[index],
        custom: { ...updated[index].custom, [key]: value },
      };
      store.updateNode(id, { assignments: updated });
      return;
    }

    updated[index] = { ...updated[index], [key]: value };
    store.updateNode(id, { assignments: updated });
  },
  setAssignsArray: (index, key, colIndex, value) => {
    const updated = [...data.assignments];

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    updated[index][key][colIndex] = value;
    store.updateNode(id, { assignments: updated });
  },
  resetAssignsArray: (index, key) => {
    const updated = [...data.assignments];

    updated[index][key] = updated[index][key].map(() => 0);
    store.updateNode(id, { assignments: updated });
  },
  addAssign: () => {
    store.updateNode(id, {
      assignments: [
        ...data.assignments,
        {
          id: nanoid(),
          description: "",
          type: "Single",
          linkCustom: "Custom",
          quantitySingle: 0,
          quantityMultiple: [],
          value: 0,
          usefulLife: 0,
          residualValue: 0,
          custom: {
            start: "",
          },
        },
      ],
    });
  },
  deleteAssign: (index) => {
    const updated = [...data.assignments];
    updated.splice(index, 1);
    store.updateNode(id, { assignments: updated });
  },
  setOutputs: (index, key, value) => {
    const updated = [...data.outputs];

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    updated[index] = { ...updated[index], [key]: value };
    store.updateNode(id, { outputs: updated });
  },
});

const ContCapexDetail = ({ node }) => {
  const { id, data, contextId } = node;
  const {
    assumptions,
    setGeneral,
    setAssigns,
    setAssignsArray,
    resetAssignsArray,
    addAssign,
    deleteAssign,
    setOutputs,
  } = useStore(selector(id, data));

  const getPriorYears = usePriorYearsStore.getState().getPriorYears;
  const priorYears = getPriorYears(contextId);

  return (
    <div className={RightBarCSS.details}>
      <div>
        <div className={RightBarCSS.container_header}>
          <h2>CAPEX</h2>
        </div>

        <>
          <h3>General</h3>
          <div className={RightBarCSS.container}>
            <div className={RightBarCSS.general}>
              <div className={RightBarCSS.whole_line}>
                <span className={RightBarCSS.description}>Description</span>
                <input
                  type="text"
                  name="text"
                  className={RightBarCSS.inputTextLong}
                  value={data.general.description}
                  onChange={(e) => {
                    setGeneral("description", e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </>

        {data.priors.length > 0 && (
          <>
            <h3>Priors</h3>
            <div className={RightBarCSS.container}>
              <div className={RightBarCSS.priors}>
                <PriorsBox priors={data.priors} startDate={assumptions.startDate} priorYears={priorYears} />
              </div>
            </div>
          </>
        )}

        <>
          <h3>Inputs</h3>
          <div className={RightBarCSS.container}>
            <div className={RightBarCSS.assignments}>
              <AssignmentsBoxCapex
                assignments={data.assignments}
                setAssigns={setAssigns}
                setAssignsArray={setAssignsArray}
                resetAssignsArray={resetAssignsArray}
                addAssign={addAssign}
                deleteAssign={deleteAssign}
                assumptions={assumptions}
                priorYears={priorYears}
                individuals={data.individuals}
              />
            </div>
          </div>
        </>

        <>
          <h3>Outputs</h3>
          <div className={RightBarCSS.container}>
            <div className={RightBarCSS.outputs}>
              <OutputsBoxSpecial
                outputs={data.outputs}
                assumptions={assumptions}
                priorYears={priorYears}
                setOutputs={setOutputs}
              />
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default ContCapexDetail;
