import SubscriptionCSS from "../../styles/pages/Subscription.module.css";

const Success = () => {
  return (
    <div className={SubscriptionCSS.landingPage}>
      <h1>success payment</h1>
    </div>
  );
};

export default Success;
