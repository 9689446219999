import { useStatementsStore } from "../../../stores/useStatementsStore";
import { useReportStore } from "../../../stores/useReportStore";

export const updateReport = () => {
  const statements = useStatementsStore.getState().statements;
  const reports = useReportStore.getState().report;

  const contextIds = statements.map((statement) => statement.contextId);

  // remove deleted context
  for (let i = reports.length - 1; i >= 0; i--) {
    if (!contextIds.includes(reports[i].contextId)) {
      reports.splice(i, 1);
    }
  }

  // add new context
  contextIds.forEach((contextId) => {
    const reportIndex = reports.findIndex((difference) => difference.contextId === contextId);
    const description = statements.find((statement) => statement.contextId === contextId).description;
    if (reportIndex !== -1) {
      reports[reportIndex].description = description;
    } else {
      reports.push({
        contextId: contextId,
        description: description,
        companyName: "",
        summary: "",
      });
    }
  });
};
