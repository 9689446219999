import { nonEditable, showZero, emptyTextCell, textCell, numberCell } from "./cells";
import { getMonthsNames, getMonthsArray, getMonthsNumbers } from "./getMonths";
import { getMonthsNames2, getMonthsArray2, getMonthsNumbers2 } from "./getMonthsAnnual";
import { nanoid } from "nanoid";

export const ROW_HEIGHT_28 = 28;
export const ROW_HEIGHT_34 = 34;
export const ROW_HEIGHT_40 = 40;
export const ROW_HEIGHT_44 = 44;

// same as actual

export const getBlankRow = ({ months }) => {
  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_28,
      cells: [...Array.from({ length: months + 1 }, () => nonEditable(emptyTextCell()))],
    },
  ];
};

export const getHeaderRow = ({ monthly, monthsDetails }) => {
  const { months, startDate, priorYear } = monthsDetails;
  let monthsNames = [];
  let monthsArray = [];
  let monthsNumbers = [];

  if (monthly) {
    monthsNames = getMonthsNames({ months, startDate, priorYear });
    monthsArray = getMonthsArray({ months, priorYear });
    monthsNumbers = getMonthsNumbers({ months, priorYear });
  } else {
    monthsNames = getMonthsNames2({ monthsDetails });
    monthsArray = getMonthsArray2({ monthsDetails });
    monthsNumbers = getMonthsNumbers2({ monthsDetails });
  }
  const className1 = "text-lg font-weight-500 border-none bg-header text-white";
  const className2 = "text-lg font-weight-500 border-none bg-header text-white justify-content-center";

  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_40,
      cells: [
        nonEditable(textCell("monthsNames", className1)),
        ...monthsNames.map((month) => nonEditable(textCell(month, className2))),
      ],
    },
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_28,
      cells: [
        nonEditable(textCell("monthsArray", className1)),
        ...monthsArray.map((month) => nonEditable(textCell(month, className2))),
      ],
    },
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_28,
      cells: [
        nonEditable(textCell("monthsNumbers", className1)),
        ...monthsNumbers.map((month) => nonEditable(textCell(month, className2))),
      ],
    },
  ];
};

export const getSubheaderRow = ({ months, title }) => {
  const className1 = "text-lg font-weight-600";

  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_40,
      cells: [
        nonEditable(textCell(title, className1)),
        ...Array.from({ length: months }, () => nonEditable(emptyTextCell())),
      ],
    },
  ];
};

export const getSubSubheaderRow = ({ months, title }) => {
  const className1 = "text-md font-weight-600";

  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_34,
      cells: [
        nonEditable(textCell(title, className1)),
        ...Array.from({ length: months }, () => nonEditable(emptyTextCell())),
      ],
    },
  ];
};

export const getSubtotalRow = ({ title, long }) => {
  const className1 = "text-md font-weight-500 bg-subtotal border-none border-subtotal";

  const row = [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_34,
      cells: [
        nonEditable(textCell(title, className1)),
        ...long.map((value) => nonEditable(showZero(numberCell(value, className1)))),
      ],
    },
  ];

  return row;
};

export const getTotalRow = ({ title, long }) => {
  const className1 = "text-lg font-weight-600 bg-total border-none border-total";

  const row = [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_40,
      cells: [
        nonEditable(textCell(title, className1)),
        ...long.map((value) => nonEditable(showZero(numberCell(value, className1)))),
      ],
    },
  ];

  return row;
};

export const getCheckRow = ({ title, long }) => {
  const className1 = "text-md font-weight-400 border-none check";

  const row = [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_28,
      cells: [
        nonEditable(textCell(title, className1)),
        ...long.map((value) => {
          const isCheck = value !== 0 && "notCheck";
          return nonEditable(showZero(numberCell(value, `${className1} ${isCheck}`)));
        }),
      ],
    },
  ];

  return row;
};

// special

const getContentRow = ({ category, description, longs }) => {
  const className1 = "text-md font-weight-400 padding-left-2";

  const rows = longs.map((long) => ({
    rowId: `${category}|${description}|${long.id}`,
    height: ROW_HEIGHT_28,
    cells: [
      nonEditable(textCell(long.description, className1)),
      ...long.long.map((value) => nonEditable(showZero(numberCell(value, className1)))),
    ],
  }));

  return rows;
};

export const getRowsSOFP = ({ monthly, monthsDetails, statement }) => {
  const { totalMonths, totalPeriod } = monthsDetails;

  let months = 0;
  if (monthly) {
    months = totalMonths;
  } else {
    months = totalPeriod;
  }

  const longProperties = statement.sofp.reduce((acc, item) => {
    acc[item.description] = item.long;
    return acc;
  }, {});
  const {
    assetsLongs,
    equityLongs,
    liabilitiesLongs,
    nonCurrentAssetsLongs,
    currentAssetsLongs,
    nonCurrentLiabilitiesLongs,
    currentLiabilitiesLongs,
    totalAssetsLong,
    totalNonCurrentAssetsLong,
    totalCurrentAssetsLong,
    totalEquityLong,
    totalLiabilitiesLong,
    totalNonCurrentLiabilitiesLong,
    totalCurrentLiabilitiesLong,
    checkLong,
  } = longProperties;

  let rows = [...getHeaderRow({ monthly, monthsDetails })];

  if (assetsLongs.length > 0) {
    rows = [...rows, ...getSubheaderRow({ months, title: "ASSETS" })];
    if (nonCurrentAssetsLongs.length > 0) {
      rows = [
        ...rows,
        ...getSubSubheaderRow({ months, title: "Non-current assets" }),
        ...getContentRow({ category: "sofp", description: "nonCurrentAssetsLongs", longs: nonCurrentAssetsLongs }),
        ...getSubtotalRow({ title: "Non-current assets", long: totalNonCurrentAssetsLong }),
        ...getBlankRow({ months }),
      ];
    }
    if (currentAssetsLongs.length > 0) {
      rows = [
        ...rows,
        ...getSubSubheaderRow({ months, title: "Current assets" }),
        ...getContentRow({ category: "sofp", description: "currentAssetsLongs", longs: currentAssetsLongs }),
        ...getSubtotalRow({ title: "Current assets", long: totalCurrentAssetsLong }),
      ];
    }
    rows = [...rows, ...getTotalRow({ title: "Total Assets", long: totalAssetsLong }), ...getBlankRow({ months })];
  }

  if (equityLongs.length > 0) {
    rows = [
      ...rows,
      ...getSubheaderRow({ months, title: "EQUITY" }),
      ...getContentRow({ category: "sofp", description: "equityLongs", longs: equityLongs }),
      ...getTotalRow({ title: "Total Equity", long: totalEquityLong }),
      ...getBlankRow({ months }),
    ];
  }

  if (liabilitiesLongs.length > 0) {
    rows = [...rows, ...getSubheaderRow({ months, title: "LIABILITIES" })];
    if (nonCurrentLiabilitiesLongs.length > 0) {
      rows = [
        ...rows,
        ...getSubSubheaderRow({ months, title: "Non-current liabilities" }),
        ...getContentRow({
          category: "sofp",
          description: "nonCurrentLiabilitiesLongs",
          longs: nonCurrentLiabilitiesLongs,
        }),
        ...getSubtotalRow({ title: "Non-current liabilities", long: totalNonCurrentLiabilitiesLong }),
        ...getBlankRow({ months }),
      ];
    }
    if (currentLiabilitiesLongs.length > 0) {
      rows = [
        ...rows,
        ...getSubSubheaderRow({ months, title: "Current liabilities" }),
        ...getContentRow({ category: "sofp", description: "currentLiabilitiesLongs", longs: currentLiabilitiesLongs }),
        ...getSubtotalRow({ title: "Current liabilities", long: totalCurrentLiabilitiesLong }),
      ];
    }
    rows = [
      ...rows,
      ...getTotalRow({ title: "Total Liabilities", long: totalLiabilitiesLong }),
      ...getBlankRow({ months }),
    ];
  }

  if (assetsLongs.length > 0 || equityLongs.length > 0 || liabilitiesLongs.length > 0) {
    rows = [...rows, ...getCheckRow({ title: "Check", long: checkLong }), ...getBlankRow({ months })];
  }

  return rows;
};

export const getRowsSOPL = ({ monthly, monthsDetails, statement }) => {
  const { totalMonths, totalPeriod } = monthsDetails;

  let months = 0;
  if (monthly) {
    months = totalMonths;
  } else {
    months = totalPeriod;
  }

  const longProperties = statement.sopl.reduce((acc, item) => {
    acc[item.description] = item.long;
    return acc;
  }, {});
  const {
    revenueLongs,
    expensesLongs,
    intExpLongs,
    taxLongs,
    totalRevenueLong,
    totalExpensesLong,
    totalIntExpLong,
    totalTaxLong,
    totalPbitLong,
    totalPbtLong,
    totalProfitLong,
  } = longProperties;

  let rows = [...getHeaderRow({ monthly, monthsDetails })];

  totalRevenueLong.length > 0 &&
    (rows = [...rows, ...getContentRow({ category: "sopl", description: "revenueLongs", longs: revenueLongs })]);
  totalExpensesLong.length > 0 &&
    (rows = [...rows, ...getContentRow({ category: "sopl", description: "expensesLongs", longs: expensesLongs })]);
  totalPbitLong.length > 0 && (rows = [...rows, ...getSubtotalRow({ title: "PBIT", long: totalPbitLong })]);

  totalIntExpLong.length > 0 &&
    (rows = [...rows, ...getContentRow({ category: "sopl", description: "intExpLongs", longs: intExpLongs })]);
  totalPbtLong.length > 0 && (rows = [...rows, ...getSubtotalRow({ title: "PBT", long: totalPbtLong })]);

  totalTaxLong.length > 0 &&
    (rows = [...rows, ...getContentRow({ category: "sopl", description: "taxLongs", longs: taxLongs })]);
  totalProfitLong.length > 0 &&
    (rows = [...rows, ...getTotalRow({ title: "Profit/(Loss)", long: totalProfitLong }), ...getBlankRow({ months })]);

  return rows;
};

export const getRowsSOCF = ({ monthly, monthsDetails, statement }) => {
  const { totalMonths, totalPeriod } = monthsDetails;

  let months = 0;
  if (monthly) {
    months = totalMonths;
  } else {
    months = totalPeriod;
  }

  const longProperties = statement.socf.reduce((acc, item) => {
    acc[item.description] = item.long;
    return acc;
  }, {});
  const {
    operatingLongs,
    investingLongs,
    financingLongs,
    totalOperatingLong,
    totalInvestingLong,
    totalFinancingLong,
    totalLong,
  } = longProperties;

  let rows = [...getHeaderRow({ monthly, monthsDetails })];

  if (operatingLongs.length > 0) {
    rows = [
      ...rows,
      ...getSubSubheaderRow({ months, title: "Cash flow from operating activities" }),
      ...getContentRow({ category: "socf", description: "operatingLongs", longs: operatingLongs }),
      ...getSubtotalRow({ title: "Cash flow from operating activities", long: totalOperatingLong }),
      ...getBlankRow({ months }),
    ];
  }

  if (investingLongs.length > 0) {
    rows = [
      ...rows,
      ...getSubSubheaderRow({ months, title: "Cash flow from investing activities" }),
      ...getContentRow({ category: "socf", description: "investingLongs", longs: investingLongs }),
      ...getSubtotalRow({ title: "Cash flow from investing activities", long: totalInvestingLong }),
      ...getBlankRow({ months }),
    ];
  }

  if (financingLongs.length > 0) {
    rows = [
      ...rows,
      ...getSubSubheaderRow({ months, title: "Cash flow from financing activities" }),
      ...getContentRow({ category: "socf", description: "financingLongs", longs: financingLongs }),
      ...getSubtotalRow({ title: "Cash flow from financing activities", long: totalFinancingLong }),
      ...getBlankRow({ months }),
    ];
  }

  if (operatingLongs.length > 0 || investingLongs.length > 0 || financingLongs.length > 0) {
    rows = [
      ...rows,
      ...getTotalRow({ title: "Total cash flow movement", long: totalLong }),
      ...getBlankRow({ months }),
    ];
  }

  return rows;
};
