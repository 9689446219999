import styles from "./styles.module.css";
import { InputNumber } from "primereact/inputnumber";

export const InputNumberSimple = ({ item, ques, keyQues, setOutputs }) => {
  return (
    <div className={styles.line}>
      <span className={styles.question}>{ques}</span>
      <InputNumber
        name="InputNumberSimple"
        className={styles.inputNumber}
        value={item[keyQues]}
        onValueChange={(e) => setOutputs(keyQues, parseInt(e.value))}
        minFractionDigits={0}
        maxFractionDigits={2}
      />
    </div>
  );
};
