export const calc = (arr1, operator, arr2, totalMonths) => {
  const result = [];
  for (let i = 0; i < totalMonths; i++) {
    let calc;
    switch (operator) {
      case "+":
        calc = arr1[i] + arr2[i];
        break;
      case "-":
        calc = arr1[i] - arr2[i];
        break;
      case "*":
        calc = arr1[i] * arr2[i];
        break;
      case "/":
        calc = arr1[i] / arr2[i];
        break;
      default:
        calc = NaN;
    }
    result.push(isFinite(calc) ? calc : 0);
  }
  return result;
};

export const calcPerc = (arr1, operator, arr2, totalMonths) => {
  const result = [];
  for (let i = 0; i < totalMonths; i++) {
    let calc;
    switch (operator) {
      case "+":
        calc = arr1[i] + arr2[i];
        break;
      case "-":
        calc = arr1[i] - arr2[i];
        break;
      case "*":
        calc = arr1[i] * arr2[i];
        break;
      case "/":
        calc = arr1[i] / arr2[i];
        break;
      default:
        calc = NaN;
    }
    result.push(isFinite(calc) ? calc * 100 : 0);
  }
  return result;
};
