import styles from "../styles.module.css";

const LogoWord = () => (
  <svg
    className={styles.logo}
    width="140"
    height="38"
    viewBox="0 0 140 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.03268 29.0209C7.43229 29.0209 5.94109 28.7594 4.56591 28.2363C3.19074 27.7133 1.99982 26.9151 1 25.8452L2.05101 24.0518C3.1532 25.0741 4.2588 25.8146 5.37464 26.2731C6.48707 26.735 7.67115 26.9626 8.92349 26.9626C10.4488 26.9626 11.6875 26.6603 12.6395 26.0557C13.5916 25.4512 14.0659 24.5816 14.0659 23.4438C14.0659 22.6762 13.8339 22.0886 13.3698 21.6776C12.9057 21.27 12.2437 20.9337 11.3804 20.6756C10.5171 20.4141 9.48653 20.1355 8.28538 19.84C6.93409 19.4698 5.80118 19.0792 4.88667 18.6682C3.97216 18.2607 3.28628 17.741 2.8222 17.1092C2.35812 16.4775 2.12608 15.6555 2.12608 14.6399C2.12608 13.3764 2.44343 12.3303 3.08154 11.5015C3.71965 10.6728 4.59662 10.041 5.70905 9.60624C6.82148 9.17148 8.06699 8.9541 9.44216 8.9541C10.9436 8.9541 12.271 9.19186 13.421 9.66398C14.5709 10.1361 15.5093 10.7984 16.2362 11.6442L14.9975 13.3628C14.2979 12.5646 13.4653 11.9736 12.503 11.5898C11.5408 11.2026 10.4693 11.009 9.29202 11.009C8.49012 11.009 7.72917 11.1143 7.00233 11.3249C6.2755 11.5355 5.68175 11.8819 5.21767 12.3642C4.75359 12.8465 4.52496 13.5089 4.52496 14.3512C4.52496 15.0441 4.69899 15.5842 5.05047 15.968C5.40194 16.3518 5.92744 16.6745 6.62697 16.9326C7.32651 17.1941 8.18983 17.4727 9.21695 17.7682C10.6945 18.1655 11.981 18.5663 13.0831 18.9739C14.1853 19.3815 15.035 19.908 15.6356 20.5499C16.2362 21.1919 16.5364 22.0954 16.5364 23.2604C16.5364 25.0673 15.8472 26.4803 14.472 27.4925C13.0968 28.508 11.2814 29.0141 9.02927 29.0141L9.03268 29.0209Z"
      fill="#003E7D"
      stroke="#003E7D"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M20.7439 28.6483V9.18262H23.2963V28.6483H20.7439Z"
      fill="#003E7D"
      stroke="#003E7D"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M58.0168 28.6473H55.4644V17.8429C55.4644 15.591 55.1027 13.9334 54.3758 12.8703C53.649 11.8072 52.5741 11.2739 51.1478 11.2739C49.7214 11.2739 48.3496 11.7936 47.186 12.833C46.0224 13.8723 45.2034 15.2072 44.7257 16.8409V28.6473H42.1733V17.8429C42.1733 15.5672 41.8218 13.9029 41.1223 12.8499C40.4227 11.797 39.3444 11.2705 37.8942 11.2705C36.4439 11.2705 35.1302 11.7766 33.9529 12.7888C32.7756 13.801 31.9499 15.1358 31.4755 16.7933V28.6439H28.9231V9.253H31.2503V13.6719C32.0761 12.1604 33.1339 10.9954 34.4204 10.1803C35.7103 9.36169 37.14 8.9541 38.7166 8.9541C40.2931 8.9541 41.658 9.41264 42.6578 10.3297C43.6576 11.2468 44.2719 12.4458 44.4971 13.9334C45.3979 12.2997 46.4797 11.06 47.7422 10.2176C49.0048 9.37527 50.438 8.9541 52.0384 8.9541C53.1406 8.9541 54.0721 9.1511 54.8365 9.5485C55.6009 9.94589 56.2117 10.5097 56.6758 11.24C57.1399 11.9702 57.4777 12.8499 57.6892 13.8757C57.9008 14.9049 58.0066 16.0495 58.0066 17.313V28.6439L58.0168 28.6473Z"
      fill="#003E7D"
      stroke="#003E7D"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M73.403 29.0209C71.7753 29.0209 70.3182 28.5997 69.0318 27.7574C67.7419 26.9151 66.7216 25.8757 65.9743 24.636V36.6054H63.4219V9.25639H65.7116V13.0435C66.4862 11.831 67.5269 10.846 68.827 10.0885C70.1271 9.33452 71.516 8.9541 72.9935 8.9541C74.3448 8.9541 75.5835 9.23262 76.7096 9.78965C77.8357 10.3467 78.8116 11.0973 79.6374 12.0382C80.4632 12.979 81.1081 14.0489 81.5722 15.2513C82.0363 16.4537 82.2683 17.6968 82.2683 18.9841C82.2683 20.7945 81.8998 22.4588 81.1593 23.9804C80.4222 25.5055 79.3883 26.7249 78.0643 27.6419C76.7369 28.559 75.1877 29.0175 73.4098 29.0175L73.403 29.0209ZM72.6898 26.7792C73.7647 26.7792 74.7304 26.5618 75.5801 26.1305C76.4298 25.6991 77.1634 25.1115 77.7776 24.3676C78.3919 23.6272 78.8594 22.7916 79.1835 21.8644C79.5077 20.9371 79.6715 19.9793 79.6715 18.9909C79.6715 17.9516 79.4906 16.9632 79.1289 16.0223C78.7672 15.0815 78.2485 14.2527 77.5695 13.536C76.8938 12.8194 76.1192 12.2488 75.2423 11.831C74.3653 11.4098 73.4167 11.1992 72.3895 11.1992C71.7378 11.1992 71.0621 11.3215 70.3626 11.5694C69.6631 11.8174 68.9942 12.1706 68.3561 12.6258C67.718 13.0843 67.1857 13.5972 66.7626 14.1644C66.336 14.7316 66.0733 15.3396 65.9743 15.9816V21.9255C66.3736 22.8392 66.9127 23.6612 67.5884 24.3914C68.264 25.1217 69.0454 25.7025 69.9361 26.1339C70.8233 26.5652 71.7446 26.7826 72.6932 26.7826L72.6898 26.7792Z"
      fill="#003E7D"
      stroke="#003E7D"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M86.6912 1.37402H89.2436V24.3755C89.2436 25.3537 89.4176 25.9922 89.7691 26.2911C90.1206 26.5934 90.5437 26.7429 91.0453 26.7429C91.4958 26.7429 91.9462 26.6919 92.3966 26.5934C92.847 26.4949 93.2361 26.393 93.5602 26.2945L94.0107 28.3494C93.5363 28.5498 92.9528 28.7095 92.2635 28.8351C91.5742 28.9608 90.9805 29.0219 90.4823 29.0219C89.3323 29.0219 88.411 28.6823 87.7251 28.0098C87.0358 27.3339 86.6912 26.4066 86.6912 25.228V1.37402Z"
      fill="#003E7D"
      stroke="#003E7D"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M97.2754 34.6257C97.6269 34.6494 97.9647 34.6698 98.2889 34.6834C98.6131 34.697 98.852 34.6766 99.0021 34.6257C99.3024 34.5747 99.589 34.4015 99.8654 34.106C100.142 33.8071 100.466 33.2365 100.841 32.3941C101.217 31.5484 101.742 30.3087 102.418 28.6715L93.8972 9.25684H96.5998L103.769 26.063L110.375 9.25684H112.928L102.793 34.2962C102.619 34.7683 102.356 35.2099 102.005 35.6175C101.654 36.0284 101.224 36.3511 100.712 36.5855C100.2 36.8198 99.5651 36.9523 98.8144 36.9761C98.5892 36.9999 98.3572 37.0067 98.1217 36.9931C97.8828 36.9795 97.603 36.9489 97.2754 36.898V34.6189V34.6257Z"
      fill="#003E7D"
      stroke="#003E7D"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M117.433 11.1998H114.73V9.18229H117.433V8.65922C117.433 7.73876 117.569 6.82848 117.846 5.93179C118.122 5.0351 118.542 4.21314 119.101 3.4659C119.664 2.71865 120.378 2.12086 121.241 1.67252C122.104 1.22417 123.135 1 124.339 1C125.264 1 126.097 1.0985 126.834 1.2989C127.571 1.49929 128.216 1.73365 128.769 2.00878C129.318 2.2839 129.731 2.53184 130.007 2.75602L128.844 4.58676C128.444 4.21314 127.847 3.89386 127.059 3.63572C126.271 3.37419 125.414 3.24172 124.486 3.24172C123.435 3.24172 122.579 3.51005 121.913 4.04331C121.251 4.57997 120.763 5.25928 120.449 6.08124C120.135 6.90321 119.978 7.73876 119.978 8.5845V9.18229H129.775V28.6479H127.223V11.1998H119.978V28.6479H117.426V11.1998H117.433Z"
      fill="#003E7D"
      stroke="#003E7D"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M22.0199 6.77431C22.9697 6.77431 23.7397 6.00788 23.7397 5.06245C23.7397 4.11701 22.9697 3.35059 22.0199 3.35059C21.07 3.35059 20.3 4.11701 20.3 5.06245C20.3 6.00788 21.07 6.77431 22.0199 6.77431Z"
      fill="#EE6E00"
    />
    <path d="M135.49 4.33159H134.651V4.0293H136.695V4.33159H135.852V6.77711H135.49V4.33159Z" fill="white" />
    <path
      d="M139.326 5.56793C139.305 5.18412 139.282 4.72559 139.285 4.38254H139.271C139.176 4.70521 139.063 5.04826 138.923 5.42528L138.435 6.75673H138.166L137.719 5.44906C137.589 5.06185 137.476 4.70861 137.398 4.38254H137.391C137.384 4.72559 137.364 5.18412 137.336 5.59511L137.261 6.77371H136.92L137.111 4.0293H137.565L138.036 5.35735C138.152 5.697 138.244 5.9959 138.312 6.28121H138.326C138.394 6.00269 138.493 5.7038 138.616 5.35735L139.107 4.0293H139.561L139.732 6.77371H139.384L139.316 5.56793H139.326Z"
      fill="white"
    />
  </svg>
);

export default LogoWord;
