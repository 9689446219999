import { create } from "zustand";

import { updateActual } from "../pages/flow/update/updateActual";
import { updateDifferences } from "../pages/flow/update/updateDifferences";

export const useActualStore = create((set, get) => ({
  actual: [],

  logout() {
    set({ actual: [] });
  },

  updateActualDetail(data) {
    set({ actual: data });

    updateActual("others");
    updateDifferences();
  },
}));
