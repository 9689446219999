import { create } from "zustand";

export const useStore = create((set, get) => ({
  status: "",
  setStatus: (data) => set({ status: data }),

  name: "",
  setName: (data) => set({ name: data }),

  id: "",
  setId: (data) => set({ id: data }),

  adAccountIds: [],
  setAdAccountIds: (data) => set({ adAccountIds: data }),

  adAccountId: "",
  setAdAccountId: (data) => set({ adAccountId: data }),

  insights: {},
  setInsights: (data) => set({ insights: data }),
}));

export const selector = (store) => {
  return {
    status: store.status,
    setStatus: store.setStatus,

    name: store.name,
    setName: store.setName,

    id: store.id,
    setId: store.setId,

    adAccountIds: store.adAccountIds,
    setAdAccountIds: store.setAdAccountIds,

    adAccountId: store.adAccountId,
    setAdAccountId: store.setAdAccountId,

    insights: store.insights,
    setInsights: store.setInsights,
  };
};
