import styles from "../../styles.module.css";

const Entrepreneur = () => (
  <svg
    className={styles.nav_svg}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 12.75C9 14.8211 10.6789 16.5 12.75 16.5C14.8211 16.5 16.5 14.8211 16.5 12.75C16.5 10.6789 14.8211 9 12.75 9C10.6789 9 9 10.6789 9 12.75ZM9 12.75C9 11.9056 9.27907 11.1264 9.75 10.4996V3.75M9 12.75C9 13.369 9.14998 13.953 9.4156 14.4676C8.78375 15.0013 7.32438 15.375 5.625 15.375C3.34683 15.375 1.5 14.7034 1.5 13.875V3.75M9.75 3.75C9.75 4.57843 7.90317 5.25 5.625 5.25C3.34683 5.25 1.5 4.57843 1.5 3.75M9.75 3.75C9.75 2.92157 7.90317 2.25 5.625 2.25C3.34683 2.25 1.5 2.92157 1.5 3.75M1.5 10.5C1.5 11.3284 3.34683 12 5.625 12C7.26675 12 8.68449 11.6512 9.34848 11.1463M9.75 7.125C9.75 7.95343 7.90317 8.625 5.625 8.625C3.34683 8.625 1.5 7.95343 1.5 7.125"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Entrepreneur;
