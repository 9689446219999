import FlowCSS from "../../styles/pages/Flow.module.css";
import { useStore } from "./store";

import Header from "./components/Header/Header";
import View from "./View";
import View0Panel from "./components/View0Panel";
import View1Panel from "./components/View1Panel";

const Main = () => {
  const view = useStore((state) => state.view);
  const contextId = useStore((state) => state.contextId);

  return (
    <div className={FlowCSS.main}>
      <Header view={view} contextId={contextId} />
      {view === "view0" && <View view={view} contextId={contextId} Panel={View0Panel} />}
      {view === "view1" && <View view={view} contextId={contextId} Panel={View1Panel} />}
    </div>
  );
};

export default Main;
