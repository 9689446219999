import { nonEditable, showZero, textCell, numberCell } from "../../statements/reactgrid/cells";

import {
  ROW_HEIGHT_28,
  // ROW_HEIGHT_34,
  // ROW_HEIGHT_40,
  // ROW_HEIGHT_44
} from "../../statements/reactgrid/getRows";

import {
  getBlankRow,
  getHeaderRow,
  getSubheaderRow,
  getSubSubheaderRow,
  getSubtotalRow,
  getTotalRow,
  getCheckRow,
} from "../../statements/reactgrid/getRows";

const getContentRow = ({ monthsDetails, category, description, longs }) => {
  const { priorLength } = monthsDetails;
  const className1 = "text-md font-weight-400 padding-left-2";

  const rows = longs.map((long) => ({
    rowId: `${category}|${description}|${long.id}`,
    height: ROW_HEIGHT_28,
    cells: [
      nonEditable(textCell(long.description, className1)),
      ...long.long.map((value, index) =>
        index < priorLength
          ? nonEditable(showZero(numberCell(value, className1)))
          : showZero(numberCell(value, className1))
      ),
    ],
  }));

  return rows;
};

export const getRowsSOFP = ({ monthsDetails, actual }) => {
  const { totalMonths } = monthsDetails;
  const months = totalMonths;

  const longProperties = actual.sofp.reduce((acc, item) => {
    acc[item.description] = item.long;
    return acc;
  }, {});
  const {
    assetsLongs,
    equityLongs,
    liabilitiesLongs,
    nonCurrentAssetsLongs,
    currentAssetsLongs,
    nonCurrentLiabilitiesLongs,
    currentLiabilitiesLongs,
    totalAssetsLong,
    totalNonCurrentAssetsLong,
    totalCurrentAssetsLong,
    totalEquityLong,
    totalLiabilitiesLong,
    totalNonCurrentLiabilitiesLong,
    totalCurrentLiabilitiesLong,
    checkLong,
  } = longProperties;

  let rows = [...getHeaderRow({ monthly: true, monthsDetails })];

  if (assetsLongs.length > 0) {
    rows = [...rows, ...getSubheaderRow({ months, title: "ASSETS" })];
    if (nonCurrentAssetsLongs.length > 0) {
      rows = [
        ...rows,
        ...getSubSubheaderRow({ months, title: "Non-current assets" }),
        ...getContentRow({
          monthsDetails,
          category: "sofp",
          description: "nonCurrentAssetsLongs",
          longs: nonCurrentAssetsLongs,
        }),
        ...getSubtotalRow({ title: "Non-current assets", long: totalNonCurrentAssetsLong }),
        ...getBlankRow({ months }),
      ];
    }
    if (currentAssetsLongs.length > 0) {
      rows = [
        ...rows,
        ...getSubSubheaderRow({ months, title: "Current assets" }),
        ...getContentRow({
          monthsDetails,
          category: "sofp",
          description: "currentAssetsLongs",
          longs: currentAssetsLongs,
        }),
        ...getSubtotalRow({ title: "Current assets", long: totalCurrentAssetsLong }),
      ];
    }
    rows = [...rows, ...getTotalRow({ title: "Total Assets", long: totalAssetsLong }), ...getBlankRow({ months })];
  }

  if (equityLongs.length > 0) {
    rows = [
      ...rows,
      ...getSubheaderRow({ months, title: "EQUITY" }),
      ...getContentRow({
        monthsDetails,
        category: "sofp",
        description: "equityLongs",
        longs: equityLongs,
      }),
      ...getTotalRow({ title: "Total Equity", long: totalEquityLong }),
      ...getBlankRow({ months }),
    ];
  }

  if (liabilitiesLongs.length > 0) {
    rows = [...rows, ...getSubheaderRow({ months, title: "LIABILITIES" })];
    if (nonCurrentLiabilitiesLongs.length > 0) {
      rows = [
        ...rows,
        ...getSubSubheaderRow({ months, title: "Non-current liabilities" }),
        ...getContentRow({
          monthsDetails,
          category: "sofp",
          description: "nonCurrentLiabilitiesLongs",
          longs: nonCurrentLiabilitiesLongs,
        }),
        ...getSubtotalRow({ title: "Non-current liabilities", long: totalNonCurrentLiabilitiesLong }),
        ...getBlankRow({ months }),
      ];
    }
    if (currentLiabilitiesLongs.length > 0) {
      rows = [
        ...rows,
        ...getSubSubheaderRow({ months, title: "Current liabilities" }),
        ...getContentRow({
          monthsDetails,
          category: "sofp",
          description: "currentLiabilitiesLongs",
          longs: currentLiabilitiesLongs,
        }),
        ...getSubtotalRow({ title: "Current liabilities", long: totalCurrentLiabilitiesLong }),
      ];
    }
    rows = [
      ...rows,
      ...getTotalRow({ title: "Total Liabilities", long: totalLiabilitiesLong }),
      ...getBlankRow({ months }),
    ];
  }

  if (assetsLongs.length > 0 || equityLongs.length > 0 || liabilitiesLongs.length > 0) {
    rows = [...rows, ...getCheckRow({ title: "Check", long: checkLong }), ...getBlankRow({ months })];
  }

  return rows;
};

export const getRowsSOPL = ({ monthsDetails, actual }) => {
  const { totalMonths } = monthsDetails;
  const months = totalMonths;

  const longProperties = actual.sopl.reduce((acc, item) => {
    acc[item.description] = item.long;
    return acc;
  }, {});
  const {
    revenueLongs,
    expensesLongs,
    intExpLongs,
    taxLongs,
    totalRevenueLong,
    totalExpensesLong,
    totalIntExpLong,
    totalTaxLong,
    totalPbitLong,
    totalPbtLong,
    totalProfitLong,
  } = longProperties;

  let rows = [...getHeaderRow({ monthly: true, monthsDetails })];

  totalRevenueLong.length > 0 &&
    (rows = [
      ...rows,
      ...getContentRow({ monthsDetails, category: "sopl", description: "revenueLongs", longs: revenueLongs }),
    ]);
  totalExpensesLong.length > 0 &&
    (rows = [
      ...rows,
      ...getContentRow({ monthsDetails, category: "sopl", description: "expensesLongs", longs: expensesLongs }),
    ]);
  totalPbitLong.length > 0 && (rows = [...rows, ...getSubtotalRow({ title: "PBIT", long: totalPbitLong })]);

  totalIntExpLong.length > 0 &&
    (rows = [
      ...rows,
      ...getContentRow({ monthsDetails, category: "sopl", description: "intExpLongs", longs: intExpLongs }),
    ]);
  totalPbtLong.length > 0 && (rows = [...rows, ...getSubtotalRow({ title: "PBT", long: totalPbtLong })]);

  totalTaxLong.length > 0 &&
    (rows = [...rows, ...getContentRow({ monthsDetails, category: "sopl", description: "taxLongs", longs: taxLongs })]);
  totalProfitLong.length > 0 &&
    (rows = [...rows, ...getTotalRow({ title: "Profit/(Loss)", long: totalProfitLong }), ...getBlankRow({ months })]);

  return rows;
};

export const getRowsSOCF = ({ monthsDetails, actual }) => {
  const { totalMonths } = monthsDetails;
  const months = totalMonths;

  const longProperties = actual.socf.reduce((acc, item) => {
    acc[item.description] = item.long;
    return acc;
  }, {});
  const {
    operatingLongs,
    investingLongs,
    financingLongs,
    totalOperatingLong,
    totalInvestingLong,
    totalFinancingLong,
    totalLong,
  } = longProperties;

  let rows = [...getHeaderRow({ monthly: true, monthsDetails })];

  if (operatingLongs.length > 0) {
    rows = [
      ...rows,
      ...getSubSubheaderRow({ months, title: "Cash flow from operating activities" }),
      ...getContentRow({
        monthsDetails,
        category: "socf",
        description: "operatingLongs",
        longs: operatingLongs,
      }),
      ...getSubtotalRow({ title: "Cash flow from operating activities", long: totalOperatingLong }),
      ...getBlankRow({ months }),
    ];
  }

  if (investingLongs.length > 0) {
    rows = [
      ...rows,
      ...getSubSubheaderRow({ months, title: "Cash flow from investing activities" }),
      ...getContentRow({
        monthsDetails,
        category: "socf",
        description: "investingLongs",
        longs: investingLongs,
      }),
      ...getSubtotalRow({ title: "Cash flow from investing activities", long: totalInvestingLong }),
      ...getBlankRow({ months }),
    ];
  }

  if (financingLongs.length > 0) {
    rows = [
      ...rows,
      ...getSubSubheaderRow({ months, title: "Cash flow from financing activities" }),
      ...getContentRow({
        monthsDetails,
        category: "socf",
        description: "financingLongs",
        longs: financingLongs,
      }),
      ...getSubtotalRow({ title: "Cash flow from financing activities", long: totalFinancingLong }),
      ...getBlankRow({ months }),
    ];
  }

  if (operatingLongs.length > 0 || investingLongs.length > 0 || financingLongs.length > 0) {
    rows = [
      ...rows,
      ...getTotalRow({ title: "Total cash flow movement", long: totalLong }),
      ...getBlankRow({ months }),
    ];
  }

  return rows;
};
