import RightBarCSS from "../../../../styles/pages/RightBar.module.css";
import { InputNumber } from "primereact/inputnumber";

export const InputNumberSimple = ({ item, ques, keyQues, setOutputs }) => {
  return (
    <div className={RightBarCSS.flex}>
      <span className={RightBarCSS.operators_item}>{ques}</span>
      <span className={RightBarCSS.operators_item}>=</span>
      {/* className={RightBarCSS.inputNumberShort} */}
      <InputNumber
        name="InputNumberSimple"
        className={RightBarCSS.numberInput}
        value={item[keyQues]}
        onValueChange={(e) => setOutputs(keyQues, parseFloat(e.value))}
        minFractionDigits={0}
        maxFractionDigits={2}
      />
    </div>
  );
};

export const InputNumberNormal = ({ item, index, ques, keyQues, setOutputs }) => {
  return (
    <div className={RightBarCSS.flex}>
      <span className={RightBarCSS.operators_item}>{ques}</span>
      <span className={RightBarCSS.operators_item}>=</span>
      {/* className={RightBarCSS.inputNumberShort} */}
      <InputNumber
        name="InputNumberNormal"
        className={RightBarCSS.numberInput}
        value={item[keyQues]}
        onValueChange={(e) => setOutputs(index, keyQues, parseFloat(e.value))}
        minFractionDigits={0}
        maxFractionDigits={2}
      />
    </div>
  );
};

export const InputNumberNested = ({ item, depthNext, indicesNext, ques, keyQues, setOutputs }) => {
  return (
    <div className={RightBarCSS.flex}>
      <span className={RightBarCSS.operators_item}>{ques}</span>
      <span className={RightBarCSS.operators_item}>=</span>
      {/* className={RightBarCSS.inputNumberShort} */}
      <InputNumber
        name="InputNumberNested"
        className={RightBarCSS.numberInput}
        value={item[keyQues]}
        onValueChange={(e) => setOutputs(depthNext, indicesNext, keyQues, parseFloat(e.value))}
        minFractionDigits={0}
        maxFractionDigits={2}
      />
    </div>
  );
};

export const InputNumberBlank = ({ value, onValueChange, disabled }) => {
  return (
    <InputNumber
      name="InputNumberBlank"
      className={RightBarCSS.numberInput}
      value={value}
      onValueChange={onValueChange}
      disabled={disabled}
      minFractionDigits={0}
      maxFractionDigits={2}
    />
  );
};

export const InputNumberStaff = ({ item, index, paramIndex, ques, keyQues, setOutputs }) => {
  return (
    <div className={RightBarCSS.flex}>
      <span className={RightBarCSS.operators_item}>{ques}</span>
      <span className={RightBarCSS.operators_item}>=</span>
      {/* className={RightBarCSS.inputNumberShort} */}
      <InputNumber
        name="InputNumberStaff"
        className={RightBarCSS.numberInput}
        value={item[keyQues]}
        onValueChange={(e) => setOutputs(index, paramIndex, keyQues, parseInt(e.value))}
      />
    </div>
  );
};

export const InputNumberTable = ({ value, onValueChange, red, disabled }) => {
  return (
    <InputNumber
      name="InputNumberBlank"
      className={`${RightBarCSS.numberInput} ${red && RightBarCSS.red}`}
      value={value}
      onValueChange={onValueChange}
      disabled={disabled}
      minFractionDigits={0}
      maxFractionDigits={2}
      style={{ color: "red" }}
    />
  );
};

export const InputNumberTableStaff = ({ value, onValueChange, red, disabled }) => {
  return (
    <InputNumber
      name="InputNumberBlank"
      className={`${RightBarCSS.numberInput} ${red && RightBarCSS.red}`}
      value={value}
      onValueChange={onValueChange}
      disabled={disabled}
      minFractionDigits={0}
      maxFractionDigits={0}
      style={{ color: "red" }}
    />
  );
};
