import StatementsCSS from "../../styles/pages/Statements.module.css";
import { useState } from "react";

import { useGeneralStore } from "../../stores/useGeneralStore";
import { useStore } from "../flow/store";
import { usePriorYearsStore } from "../../stores/usePriorYearsStore";
import { useDifferencesStore } from "../../stores/useDifferencesStore";

import SOFP from "./SOFP";
import SOPL from "./SOPL";
import SOCF from "./SOCF";

const Route = () => {
  const [page, setPage] = useState("SOFP");

  const contextId = useGeneralStore((state) => state.contextId);

  const assumptions = useStore((state) => state.assumptions);
  const priorYears = usePriorYearsStore((state) => state.priorYears);
  const differencess = useDifferencesStore((state) => state.differences);

  const priorYear = priorYears.find((item) => item.contextId === contextId);
  const differences = differencess.find((item) => item.contextId === contextId);

  if (!differences) {
    return <div>Select a contextId.</div>;
  }
  if (differences.sofp.length === 0) {
    return <div>SOFP node is not present in the context.</div>;
  }
  if (differences.sopl.length === 0) {
    return <div>SOPL node is not present in the context.</div>;
  }
  if (differences.socf.length === 0) {
    return <div>SOCF node is not present in the context.</div>;
  }

  const { months, startDate } = assumptions;
  const { priorLength } = priorYear;
  const totalMonths = months + priorLength;
  const monthsDetails = { months, startDate, priorYear, priorLength, totalMonths };

  return (
    <div className={StatementsCSS.statements}>
      <div className={StatementsCSS.routes}>
        <button
          className={`${StatementsCSS.route} ${page === "SOFP" ? StatementsCSS.active : ""}`}
          onClick={() => setPage("SOFP")}
        >
          SOFP
        </button>
        <button
          className={`${StatementsCSS.route} ${page === "SOPL" ? StatementsCSS.active : ""}`}
          onClick={() => setPage("SOPL")}
        >
          SOPL
        </button>
        <button
          className={`${StatementsCSS.route} ${page === "SOCF" ? StatementsCSS.active : ""}`}
          onClick={() => setPage("SOCF")}
        >
          SOCF
        </button>
      </div>

      {page === "SOFP" && <SOFP monthsDetails={monthsDetails} differences={differences} />}
      {page === "SOPL" && <SOPL monthsDetails={monthsDetails} differences={differences} />}
      {page === "SOCF" && <SOCF monthsDetails={monthsDetails} differences={differences} />}
    </div>
  );
};

export default Route;
