import styles from "../styles.module.css";

const Icon = () => (
  <svg
    className={styles.hub_svg}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.95833 15.1667C6.38931 15.1667 6.80263 14.9955 7.10738 14.6907C7.41213 14.386 7.58333 13.9726 7.58333 13.5417C7.58862 13.4876 7.58862 13.4332 7.58333 13.3792L10.6058 10.3567H10.855H11.1042L12.8483 12.1008C12.8483 12.1008 12.8483 12.155 12.8483 12.1875C12.8483 12.6185 13.0195 13.0318 13.3243 13.3365C13.629 13.6413 14.0424 13.8125 14.4733 13.8125C14.9043 13.8125 15.3176 13.6413 15.6224 13.3365C15.9271 13.0318 16.0983 12.6185 16.0983 12.1875V12.1008L20.0417 8.125C20.3631 8.125 20.6772 8.0297 20.9445 7.85114C21.2117 7.67258 21.42 7.41879 21.543 7.12186C21.666 6.82493 21.6981 6.4982 21.6354 6.18298C21.5727 5.86776 21.418 5.57821 21.1907 5.35095C20.9635 5.12369 20.6739 4.96892 20.3587 4.90622C20.0435 4.84352 19.7167 4.8757 19.4198 4.9987C19.1229 5.12169 18.8691 5.32997 18.6905 5.5972C18.512 5.86443 18.4167 6.17861 18.4167 6.5C18.4114 6.55404 18.4114 6.60846 18.4167 6.6625L14.5058 10.5733H14.3325L12.4583 8.66667C12.4583 8.23569 12.2871 7.82236 11.9824 7.51762C11.6776 7.21287 11.2643 7.04167 10.8333 7.04167C10.4024 7.04167 9.98903 7.21287 9.68428 7.51762C9.37954 7.82236 9.20833 8.23569 9.20833 8.66667L5.95833 11.9167C5.52736 11.9167 5.11403 12.0879 4.80928 12.3926C4.50454 12.6974 4.33333 13.1107 4.33333 13.5417C4.33333 13.9726 4.50454 14.386 4.80928 14.6907C5.11403 14.9955 5.52736 15.1667 5.95833 15.1667V15.1667ZM20.5833 19.5H2.16667V1.08333C2.16667 0.796015 2.05253 0.520465 1.84937 0.317301C1.6462 0.114137 1.37065 0 1.08333 0C0.796015 0 0.520465 0.114137 0.317301 0.317301C0.114137 0.520465 0 0.796015 0 1.08333V20.5833C0 20.8707 0.114137 21.1462 0.317301 21.3494C0.520465 21.5525 0.796015 21.6667 1.08333 21.6667H20.5833C20.8706 21.6667 21.1462 21.5525 21.3494 21.3494C21.5525 21.1462 21.6667 20.8707 21.6667 20.5833C21.6667 20.296 21.5525 20.0205 21.3494 19.8173C21.1462 19.6141 20.8706 19.5 20.5833 19.5Z"
      fill="currentColor"
    />
  </svg>
);

const Investor = ({ active }) => (
  <div className={`${styles.hub} ${active && styles.active}`}>
    <Icon />
    <span className={`${styles.hub_span} ${active && styles.active}`}>Simplyfi Investor</span>
  </div>
);

export default Investor;
