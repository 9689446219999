import styles from "./styles.module.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export const MonthPickerSimple = ({ item, ques, keyQues, setOutputs }) => {
  return (
    <div className={styles.line}>
      <span className={styles.question}>{ques}</span>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          views={["month", "year"]}
          value={dayjs(item[keyQues])}
          onChange={(date) => {
            const year = date.year();
            const month = date.month() + 1;
            setOutputs(keyQues, `${year}-${month}`);
          }}
          className={styles.monthPicker}
        />
      </LocalizationProvider>
    </div>
  );
};
