export const containersList = [
  "N/A",
  "blank",
  "marketing",
  "sales",
  "revenue",
  "staff",
  "directCosts",
  "indirectCosts",
  "expenses",
  "profit",
  "capex",
  "loan",
  "receivables",
  "payables",
  "shareCap",
  "retained",
  "cash",
  "sofp",
];
