import { useEffect } from "react";

const Redirect = () => {
  useEffect(() => {
    // create state
    const state = crypto.randomUUID();
    sessionStorage.setItem("state", state);

    const query = new URLSearchParams({
      client_id: process.env.REACT_APP_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      response_type: "code",
      scope: "",
      state: state,
      // prompt: "" // "none", "consent", or "login"
    }).toString();

    // GET /oauth/authorize
    window.location.href = `https://dev.simplyfi.co/oauth/authorize?${query}`;
  }, []);

  return <div>Redirecting to Simplyfi Hub...</div>;
};

export default Redirect;
