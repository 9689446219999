import { getCellProperty } from "@silevis/reactgrid";

class ButtonDeleteColCellTemplate {
  getCompatibleCell(uncertainCell) {
    const label = getCellProperty(uncertainCell, "label", "string");
    return { ...uncertainCell, label };
  }

  render(cell) {
    const { onClick } = this.props;
    return <button onClick={() => onClick(cell)}>{cell.label}</button>;
  }
}

export default ButtonDeleteColCellTemplate;
