import { nonEditable, showZero, emptyTextCell, textCell, numberCell } from "../../statements/reactgrid/cells";
import { getMonthsNames } from "../../statements/reactgrid/getMonths";
import { nanoid } from "nanoid";

import {
  ROW_HEIGHT_28,
  ROW_HEIGHT_34,
  ROW_HEIGHT_40,
  // ROW_HEIGHT_44
} from "../../statements/reactgrid/getRows";

const getBlankRow = ({ monthsDetails }) => {
  const { months } = monthsDetails;

  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_28,
      cells: [...Array.from({ length: months * 4 + 1 }, () => nonEditable(emptyTextCell()))],
    },
  ];
};

const getHeaderRow = ({ monthsDetails }) => {
  const { months, startDate, priorYear, priorLength } = monthsDetails;
  const monthsNames = getMonthsNames({ months, startDate, priorYear });
  const className1 = "text-lg font-weight-500 border-none bg-header text-white";
  const className2 = "text-lg font-weight-500 border-none bg-header text-white justify-content-center";

  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_40,
      cells: [
        nonEditable(textCell("monthsNames", className1)),
        ...monthsNames.reduce((acc, month, index) => {
          if (index >= priorLength) {
            return [
              ...acc,
              nonEditable(textCell(month, className2)),
              nonEditable(textCell(month, className2)),
              nonEditable(textCell(month, className2)),
              nonEditable(textCell(month, className2)),
            ];
          } else {
            return acc;
          }
        }, []),
      ],
    },
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_40,
      cells: [
        nonEditable(textCell("", className1)),
        ...monthsNames.reduce((acc, month, index) => {
          if (index >= priorLength) {
            return [
              ...acc,
              nonEditable(textCell("Projection", className2)),
              nonEditable(textCell("Actual", className2)),
              nonEditable(textCell("Difference", className2)),
              nonEditable(textCell("Difference (%)", className2)),
            ];
          } else {
            return acc;
          }
        }, []),
      ],
    },
  ];
};

const getSubheaderRow = ({ monthsDetails, title }) => {
  const { months } = monthsDetails;
  const className1 = "text-lg font-weight-600";

  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_40,
      cells: [
        nonEditable(textCell(title, className1)),
        ...Array.from({ length: months * 4 }, () => nonEditable(emptyTextCell())),
      ],
    },
  ];
};

const getSubSubheaderRow = ({ monthsDetails, title }) => {
  const { months } = monthsDetails;
  const className1 = "text-md font-weight-600";

  return [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_34,
      cells: [
        nonEditable(textCell(title, className1)),
        ...Array.from({ length: months * 4 }, () => nonEditable(emptyTextCell())),
      ],
    },
  ];
};

const getSubtotalRow = ({ monthsDetails, title, long }) => {
  const { priorLength } = monthsDetails;
  const className1 = "text-md font-weight-500 bg-subtotal border-none border-subtotal";

  const row = [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_34,
      cells: [
        nonEditable(textCell(title, className1)),
        ...long.statements.reduce((acc, _, index) => {
          if (index >= priorLength) {
            const className2 = long.differenceM[index] > 0 ? "text-green" : "text-red";
            const className3 = long.differenceP[index] > 0 ? "text-green" : "text-red";
            return [
              ...acc,
              nonEditable(showZero(numberCell(long.statements[index], className1))),
              nonEditable(showZero(numberCell(long.actual[index], className1))),
              nonEditable(showZero(numberCell(long.differenceM[index], `${className1} ${className2}`))),
              nonEditable(showZero(numberCell(long.differenceP[index], `${className1} ${className3}`))),
            ];
          } else {
            return acc;
          }
        }, []),
      ],
    },
  ];

  return row;
};

const getTotalRow = ({ monthsDetails, title, long }) => {
  const { priorLength } = monthsDetails;
  const className1 = "text-lg font-weight-600 bg-total border-none border-total";

  const row = [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_40,
      cells: [
        nonEditable(textCell(title, className1)),
        ...long.statements.reduce((acc, _, index) => {
          if (index >= priorLength) {
            const className2 = long.differenceM[index] > 0 ? "text-green" : "text-red";
            const className3 = long.differenceP[index] > 0 ? "text-green" : "text-red";
            return [
              ...acc,
              nonEditable(showZero(numberCell(long.statements[index], className1))),
              nonEditable(showZero(numberCell(long.actual[index], className1))),
              nonEditable(showZero(numberCell(long.differenceM[index], `${className1} ${className2}`))),
              nonEditable(showZero(numberCell(long.differenceP[index], `${className1} ${className3}`))),
            ];
          } else {
            return acc;
          }
        }, []),
      ],
    },
  ];

  return row;
};

const getCheckRow = ({ monthsDetails, title, long }) => {
  const { priorLength } = monthsDetails;
  const className1 = "text-md font-weight-400 border-none check";

  const row = [
    {
      rowId: nanoid(),
      height: ROW_HEIGHT_28,
      cells: [
        nonEditable(textCell(title, className1)),
        ...long.statements.reduce((acc, _, index) => {
          if (index >= priorLength) {
            const isCheck1 = long.statements[index] !== 0 ? "notCheck" : "";
            const isCheck2 = long.actual[index] !== 0 ? "notCheck" : "";
            const isCheck3 = long.differenceM[index] !== 0 ? "notCheck" : "";
            const isCheck4 = long.differenceP[index] !== 0 ? "notCheck" : "";
            return [
              ...acc,
              nonEditable(showZero(numberCell(long.statements[index], `${className1} ${isCheck1}`))),
              nonEditable(showZero(numberCell(long.actual[index], `${className1} ${isCheck2}`))),
              nonEditable(showZero(numberCell(long.differenceM[index], `${className1} ${isCheck3}`))),
              nonEditable(showZero(numberCell(long.differenceP[index], `${className1} ${isCheck4}`))),
            ];
          } else {
            return acc;
          }
        }, []),
      ],
    },
  ];

  return row;
};

const getContentRow = ({ monthsDetails, category, description, longs }) => {
  const { priorLength } = monthsDetails;
  const className1 = "text-md font-weight-400 padding-left-2";

  const rows = longs.map((long) => ({
    rowId: `${category}|${description}|${long.id}`,
    height: ROW_HEIGHT_28,
    cells: [
      nonEditable(textCell(long.description, className1)),
      ...long.statements.reduce((acc, _, index) => {
        if (index >= priorLength) {
          const className2 = long.differenceM[index] > 0 ? "text-green" : "text-red";
          const className3 = long.differenceP[index] > 0 ? "text-green" : "text-red";
          return [
            ...acc,
            nonEditable(showZero(numberCell(long.statements[index], className1))),
            showZero(numberCell(long.actual[index], className1)),
            nonEditable(showZero(numberCell(long.differenceM[index], `${className1} ${className2}`))),
            nonEditable(showZero(numberCell(long.differenceP[index], `${className1} ${className3}`))),
          ];
        } else {
          return acc;
        }
      }, []),
    ],
  }));

  return rows;
};

export const getRowsSOFP = ({ monthsDetails, differences }) => {
  const longProperties = differences.sofp.reduce((acc, item) => {
    if (item.type === "array") {
      acc[item.description] = item;
    } else if (item.type === "object") {
      acc[item.description] = item.long;
    }
    return acc;
  }, {});
  const {
    assetsLongs,
    equityLongs,
    liabilitiesLongs,
    nonCurrentAssetsLongs,
    currentAssetsLongs,
    nonCurrentLiabilitiesLongs,
    currentLiabilitiesLongs,
    totalAssetsLong,
    totalNonCurrentAssetsLong,
    totalCurrentAssetsLong,
    totalEquityLong,
    totalLiabilitiesLong,
    totalNonCurrentLiabilitiesLong,
    totalCurrentLiabilitiesLong,
    checkLong,
  } = longProperties;

  let rows = [...getHeaderRow({ monthsDetails })];

  if (assetsLongs.length > 0) {
    rows = [...rows, ...getSubheaderRow({ monthsDetails, title: "ASSETS" })];
    if (nonCurrentAssetsLongs.length > 0) {
      rows = [
        ...rows,
        ...getSubSubheaderRow({ monthsDetails, title: "Non-current assets" }),
        ...getContentRow({
          monthsDetails,
          category: "sofp",
          description: "nonCurrentAssetsLongs",
          longs: nonCurrentAssetsLongs,
        }),
        ...getSubtotalRow({ monthsDetails, title: "Non-current assets", long: totalNonCurrentAssetsLong }),
        ...getBlankRow({ monthsDetails }),
      ];
    }
    if (currentAssetsLongs.length > 0) {
      rows = [
        ...rows,
        ...getSubSubheaderRow({ monthsDetails, title: "Current assets" }),
        ...getContentRow({
          monthsDetails,
          category: "sofp",
          description: "currentAssetsLongs",
          longs: currentAssetsLongs,
        }),
        ...getSubtotalRow({ monthsDetails, title: "Current assets", long: totalCurrentAssetsLong }),
      ];
    }
    rows = [
      ...rows,
      ...getTotalRow({ monthsDetails, title: "Total Assets", long: totalAssetsLong }),
      ...getBlankRow({ monthsDetails }),
    ];
  }

  if (equityLongs.length > 0) {
    rows = [
      ...rows,
      ...getSubheaderRow({ monthsDetails, title: "EQUITY" }),
      ...getContentRow({ monthsDetails, category: "sofp", description: "equityLongs", longs: equityLongs }),
      ...getTotalRow({ monthsDetails, title: "Total Equity", long: totalEquityLong }),
      ...getBlankRow({ monthsDetails }),
    ];
  }

  if (liabilitiesLongs.length > 0) {
    rows = [...rows, ...getSubheaderRow({ monthsDetails, title: "LIABILITIES" })];
    if (nonCurrentLiabilitiesLongs.length > 0) {
      rows = [
        ...rows,
        ...getSubSubheaderRow({ monthsDetails, title: "Non-current liabilities" }),
        ...getContentRow({
          monthsDetails,
          category: "sofp",
          description: "nonCurrentLiabilitiesLongs",
          longs: nonCurrentLiabilitiesLongs,
        }),
        ...getSubtotalRow({ monthsDetails, title: "Non-current liabilities", long: totalNonCurrentLiabilitiesLong }),
        ...getBlankRow({ monthsDetails }),
      ];
    }
    if (currentLiabilitiesLongs.length > 0) {
      rows = [
        ...rows,
        ...getSubSubheaderRow({ monthsDetails, title: "Current liabilities" }),
        ...getContentRow({
          monthsDetails,
          category: "sofp",
          description: "currentLiabilitiesLongs",
          longs: currentLiabilitiesLongs,
        }),
        ...getSubtotalRow({ monthsDetails, title: "Current liabilities", long: totalCurrentLiabilitiesLong }),
      ];
    }
    rows = [
      ...rows,
      ...getTotalRow({ monthsDetails, title: "Total Liabilities", long: totalLiabilitiesLong }),
      ...getBlankRow({ monthsDetails }),
    ];
  }

  if (assetsLongs.length > 0 || equityLongs.length > 0 || liabilitiesLongs.length > 0) {
    rows = [
      ...rows,
      ...getCheckRow({ monthsDetails, title: "Check", long: checkLong }),
      ...getBlankRow({ monthsDetails }),
    ];
  }

  return rows;
};

export const getRowsSOPL = ({ monthsDetails, differences }) => {
  const longProperties = differences.sopl.reduce((acc, item) => {
    if (item.type === "array") {
      acc[item.description] = item;
    } else if (item.type === "object") {
      acc[item.description] = item.long;
    }
    return acc;
  }, {});
  const {
    revenueLongs,
    expensesLongs,
    intExpLongs,
    taxLongs,
    totalRevenueLong,
    totalExpensesLong,
    totalIntExpLong,
    totalTaxLong,
    totalPbitLong,
    totalPbtLong,
    totalProfitLong,
  } = longProperties;

  let rows = [...getHeaderRow({ monthsDetails })];

  totalRevenueLong.statements.length > 0 &&
    (rows = [
      ...rows,
      ...getContentRow({ monthsDetails, category: "sopl", description: "revenueLongs", longs: revenueLongs }),
    ]);
  totalExpensesLong.statements.length > 0 &&
    (rows = [
      ...rows,
      ...getContentRow({ monthsDetails, category: "sopl", description: "expensesLongs", longs: expensesLongs }),
    ]);
  totalPbitLong.statements.length > 0 &&
    (rows = [...rows, ...getSubtotalRow({ monthsDetails, title: "PBIT", long: totalPbitLong })]);

  totalIntExpLong.statements.length > 0 &&
    (rows = [
      ...rows,
      ...getContentRow({ monthsDetails, category: "sopl", description: "intExpLongs", longs: intExpLongs }),
    ]);
  totalPbtLong.statements.length > 0 &&
    (rows = [...rows, ...getSubtotalRow({ monthsDetails, title: "PBT", long: totalPbtLong })]);

  totalTaxLong.statements.length > 0 &&
    (rows = [...rows, ...getContentRow({ monthsDetails, category: "sopl", description: "taxLongs", longs: taxLongs })]);
  totalProfitLong.statements.length > 0 &&
    (rows = [
      ...rows,
      ...getTotalRow({ monthsDetails, title: "Profit/(Loss)", long: totalProfitLong }),
      ...getBlankRow({ monthsDetails }),
    ]);

  return rows;
};

export const getRowsSOCF = ({ monthsDetails, differences }) => {
  const longProperties = differences.socf.reduce((acc, item) => {
    if (item.type === "array") {
      acc[item.description] = item;
    } else if (item.type === "object") {
      acc[item.description] = item.long;
    }
    return acc;
  }, {});
  const {
    operatingLongs,
    investingLongs,
    financingLongs,
    totalOperatingLong,
    totalInvestingLong,
    totalFinancingLong,
    totalLong,
  } = longProperties;

  let rows = [...getHeaderRow({ monthsDetails })];

  if (operatingLongs.length > 0) {
    rows = [
      ...rows,
      ...getSubSubheaderRow({ monthsDetails, title: "Cash flow from operating activities" }),
      ...getContentRow({ monthsDetails, category: "socf", description: "operatingLongs", longs: operatingLongs }),
      ...getSubtotalRow({ monthsDetails, title: "Cash flow from operating activities", long: totalOperatingLong }),
      ...getBlankRow({ monthsDetails }),
    ];
  }

  if (investingLongs.length > 0) {
    rows = [
      ...rows,
      ...getSubSubheaderRow({ monthsDetails, title: "Cash flow from investing activities" }),
      ...getContentRow({ monthsDetails, category: "socf", description: "investingLongs", longs: investingLongs }),
      ...getSubtotalRow({ monthsDetails, title: "Cash flow from investing activities", long: totalInvestingLong }),
      ...getBlankRow({ monthsDetails }),
    ];
  }

  if (financingLongs.length > 0) {
    rows = [
      ...rows,
      ...getSubSubheaderRow({ monthsDetails, title: "Cash flow from financing activities" }),
      ...getContentRow({ monthsDetails, category: "socf", description: "financingLongs", longs: financingLongs }),
      ...getSubtotalRow({ monthsDetails, title: "Cash flow from financing activities", long: totalFinancingLong }),
      ...getBlankRow({ monthsDetails }),
    ];
  }

  if (operatingLongs.length > 0 || investingLongs.length > 0 || financingLongs.length > 0) {
    rows = [
      ...rows,
      ...getTotalRow({ monthsDetails, title: "Total cash flow movement", long: totalLong }),
      ...getBlankRow({ monthsDetails }),
    ];
  }

  return rows;
};
