import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { useAuthContext } from "./auth/useAuthContext";
import { useLogout } from "./auth/useLogout";
import { useRestore } from "./saveAndRestore/useRestore";

import Redirect from "./authComponents/Redirect";
import Callback from "./authComponents/Callback";
import PersistLogin from "./authComponents/PersistLogin";
import PremiumRoute from "./authComponents/PremiumRoute";

import Navbar from "./components/navbar/Navbar";
import Sidebar from "./components/sidebar/Sidebar";

import DashboardRoutes from "./pages/dashboard/index";
import FlowRoutes from "./pages/flow/index";
import StatementsRoutes from "./pages/statements/index";
import ActualRoutes from "./pages/actual/index";
import CompareRoutes from "./pages/compare/index";
import PriorRoutes from "./pages/prior/index";
import ValuationRoutes from "./pages/valuation/index";
import ReportRoutes from "./pages/report/index";
import SubscriptionRoutes from "./pages/subscription/index";
import FacebookRoutes from "./pages/facebook/index";

function App() {
  const [hasSidebar, setHasSidebar] = useState(true);

  const { user } = useAuthContext();
  const { logout } = useLogout();
  useRestore(user);

  useEffect(() => {
    const broadcast = new BroadcastChannel("logout");
    broadcast.onmessage = (event) => {
      if (event.data === "logout") {
        logout(false);
      }
    };
    return () => {
      broadcast.close();
    };
  }, [logout]);

  return (
    <div className={`App ${!hasSidebar ? "noSidebar" : ""}`}>
      <BrowserRouter>
        <Navbar hasSidebar={hasSidebar} setHasSidebar={setHasSidebar} />
        <div className="desktop">
          {hasSidebar && <Sidebar />}
          <div className="main">
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="/redirect" element={<Redirect />} />
              <Route path="/callback" element={<Callback />} />

              <Route element={<PersistLogin />}>
                <Route element={<PremiumRoute />}>
                  <Route path="/dashboard/*" element={<DashboardRoutes />} />
                  <Route path="/flow/*" element={<FlowRoutes />} />
                  <Route path="/statements/*" element={<StatementsRoutes />} />
                  <Route path="/actual/*" element={<ActualRoutes />} />
                  <Route path="/compare/*" element={<CompareRoutes />} />
                  <Route path="/prior/*" element={<PriorRoutes />} />
                  <Route path="/valuation/*" element={<ValuationRoutes />} />
                  <Route path="/report/*" element={<ReportRoutes />} />
                </Route>
                <Route path="/subscription/*" element={<SubscriptionRoutes />} />

                <Route path="/facebook/*" element={<FacebookRoutes />} />
              </Route>
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
