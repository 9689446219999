import { create } from "zustand";

import { updateFlow } from "../pages/flow/update/updateFlow";
import { updateStatements } from "../pages/flow/update/updateStatements";
import { updateStatementsAnnual } from "../pages/flow/update/updateStatementsAnnual";
import { updateActual } from "../pages/flow/update/updateActual";
import { updateDifferences } from "../pages/flow/update/updateDifferences";
import { updateValuation } from "../pages/flow/update/updateValuation";
import { updateDashboard } from "../pages/flow/update/updateDashboard";
import { updateReport } from "../pages/flow/update/updateReport";

export const usePriorYearsStore = create((set, get) => ({
  priorYears: [],

  logout() {
    set({ priorYears: [] });
  },

  getPriorYears(contextId) {
    const priorYears = get().priorYears.find((priorYear) => priorYear.contextId === contextId);
    return priorYears;
  },

  getPriorLength(node) {
    if (!node) return;

    const priorYears = get().priorYears.find((priorYear) => priorYear.contextId === node.contextId);
    const priorLength = priorYears.priorLength;
    return priorLength;
  },

  updatePriorYearsDetail(data) {
    data.forEach((priorYear) => (priorYear.priorLength = priorYear.headers[0].length - 2));
    set({ priorYears: data });

    updateFlow();
    updateStatements();
    updateStatementsAnnual();
    updateActual("prior");
    updateDifferences();
    updateValuation();
    updateDashboard();
    updateReport();
  },
}));
