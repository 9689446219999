import styles from "./styles.module.css";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";

import Expand from "./svg/outer/Expand";
import Entrepreneur from "./svg/outer/Entrepreneur";
import Forecast from "./svg/outer/Forecast";
import Financing from "./svg/outer/Financing";

import Dashboard from "./svg/forecast/Dashboard";
import Flow from "./svg/forecast/Flow";
import Statements from "./svg/forecast/Statements";
import Actual from "./svg/forecast/Actual";
import Compare from "./svg/forecast/Compare";
import Prior from "./svg/forecast/Prior";
import Valuation from "./svg/forecast/Valuation";
import Report from "./svg/forecast/Report";
import Subscription from "./svg/forecast/Subscription";

const Sidebar = () => {
  const location = useLocation();
  const getLink = (path) => {
    return location.pathname.startsWith(path) ? `${styles.active}` : "";
  };

  const [expand, setExpand] = useState({
    entrepreneur: false,
    forecast: true,
    financing: false,
  });
  const toggleExpand = (section) => {
    setExpand((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <div className={styles.desktop}>
      <>
        <span className={styles.nav_label_big}>Simplyfi Biz</span>
      </>

      <>
        <div className={styles.nav_item_expand} onClick={() => toggleExpand("entrepreneur")}>
          <Expand expand={expand.entrepreneur} />
          <Entrepreneur />
          <span className={styles.nav_label_small}>Entrepreneur FP</span>
        </div>
      </>

      <>
        <div className={styles.nav_item_expand} onClick={() => toggleExpand("forecast")}>
          <Expand expand={expand.forecast} />
          <Forecast />
          <span className={styles.nav_label_small}>Forecast</span>
        </div>
        {expand.forecast && (
          <>
            <Link to="/dashboard" className={`${styles.nav_link} ${getLink("/dashboard")}`}>
              <Dashboard />
              <span className={styles.nav_label_small}>Dashboard</span>
            </Link>
            <Link to="/flow" className={`${styles.nav_link} ${getLink("/flow")}`}>
              <Flow />
              <span className={styles.nav_label_small}>Modeling</span>
            </Link>
            <Link to="/statements" className={`${styles.nav_link} ${getLink("/statements")}`}>
              <Statements />
              <span className={styles.nav_label_small}>Projection</span>
            </Link>
            <Link to="/actual" className={`${styles.nav_link} ${getLink("/actual")}`}>
              <Actual />
              <span className={styles.nav_label_small}>Actual</span>
            </Link>
            <Link to="/compare" className={`${styles.nav_link} ${getLink("/compare")}`}>
              <Compare />
              <span className={styles.nav_label_small}>Compare</span>
            </Link>
            <Link to="/prior" className={`${styles.nav_link} ${getLink("/prior")}`}>
              <Prior />
              <span className={styles.nav_label_small}>Past Data</span>
            </Link>
            <Link to="/valuation" className={`${styles.nav_link} ${getLink("/valuation")}`}>
              <Valuation />
              <span className={styles.nav_label_small}>Valuation</span>
            </Link>
            <Link to="/report" className={`${styles.nav_link} ${getLink("/report")}`}>
              <Report />
              <span className={styles.nav_label_small}>Report</span>
            </Link>
            <Link to="/subscription" className={`${styles.nav_link} ${getLink("/subscription")}`}>
              <Subscription />
              <span className={styles.nav_label_small}>Subscription</span>
            </Link>
          </>
        )}
      </>

      <>
        <div className={styles.nav_item_expand} onClick={() => toggleExpand("financing")}>
          <Expand expand={expand.financing} />
          <Financing />
          <span className={styles.nav_label_small}>Financing</span>
        </div>
      </>
    </div>
  );
};

export default Sidebar;
