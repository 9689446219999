import styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useAxiosPrivate } from "../../axios/useAxiosPrivate";

import { useAuthContext } from "../../auth/useAuthContext";
import { useLogout } from "../../auth/useLogout";
import { useGeneralStore } from "../../stores/useGeneralStore";

import Burger from "./logo/Burger";
import LogoPic from "./logo/LogoPic";
import LogoWord from "./logo/LogoWord";

import Hub from "./link/Hub";
import Personal from "./link/Personal";
import Biz from "./link/Biz";
import Investor from "./link/Investor";
import Partner from "./link/Partner";

const Navbar = ({ hasSidebar, setHasSidebar }) => {
  const { user } = useAuthContext();
  const { logout } = useLogout();

  const axiosPrivate = useAxiosPrivate();
  const subscription = useGeneralStore((state) => state.subscription);
  const setSubscription = useGeneralStore.getState().setSubscription;

  useEffect(() => {
    const getSubscription = async () => {
      const response = await axiosPrivate.get("/api/subscriptions");
      setSubscription(response.data);
    };

    if (user) {
      getSubscription();
    }
  }, [user, axiosPrivate, setSubscription]);

  return (
    <div className={styles.desktop}>
      {/* <p>Version 1.0</p> */}
      <div className={styles.nav_left}>
        <div className={styles.nav_left_item}>
          <Burger hasSidebar={hasSidebar} setHasSidebar={setHasSidebar} />
        </div>

        <div className={styles.nav_left_item}>
          <Link to="/">
            <LogoPic />
          </Link>
          <Link to="/">
            <LogoWord />
          </Link>
        </div>

        <div className={styles.nav_left_item}>
          <Link to="/">
            <Hub active={false} />
          </Link>
          <Link to="/">
            <Personal active={false} />
          </Link>
          <Link to="/">
            <Biz active={true} />
          </Link>
          <Link to="/">
            <Investor active={false} />
          </Link>
          <Link to="/">
            <Partner active={false} />
          </Link>
        </div>
      </div>

      {user && (
        <div className={styles.nav_right}>
          {user.email && (
            <>
              {subscription && <span className={styles.subscription}>{subscription.name}</span>}
              <span>{user.email}</span>
              <span className={styles.auth} onClick={() => logout(true)}>
                Logout
              </span>
            </>
          )}
          {!user.email && (
            <>
              {subscription && <span className={styles.subscription}>{subscription.name}</span>}
              <span>Guest</span>
              <Link to="/signupGuest">
                <span className={styles.auth}>Signup</span>
              </Link>
              <span className={styles.auth} onClick={() => logout(true)}>
                Logout
              </span>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Navbar;
