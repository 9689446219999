import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export const SelectCustom = ({ item, keyQues, onChange, choices, disabled }) => {
  return (
    <FormControl>
      <Select
        name={keyQues}
        value={item[keyQues]}
        onChange={onChange}
        disabled={disabled}
        sx={{
          fontFamily: "Poppins",
          height: "100%",
          width: "6rem",
        }}
      >
        {choices.map((choice, index) => (
          <MenuItem
            key={index}
            value={choice}
            sx={{
              fontFamily: "Poppins",
            }}
          >
            {choice}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
