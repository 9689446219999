import { useStore } from "../store";
import { assignModel, formulaModelEasy, formulaModelMedium, formulaModelHard } from "./marketingModel";
import { generateLong, calculate } from "./utilsGenerate";
import { sortById, topologicalSortFormulas } from "../utilsFunctions/topologicalSortFormulas";

export const calculateContMarketing = (nodes, node, edges, assumptions, priorYears) => {
  const { months, startDate } = assumptions;
  const priorYear = priorYears.find((priorYear) => priorYear.contextId === node.contextId);
  const { priorLength } = priorYear;
  const totalMonths = months + priorLength;

  // PRIORS
  const priors = calulatePriors(priorYear.rows, node.container);

  // ASSIGNMENTS
  let assignments = [];
  let tempAssign = [];
  // 1. if (core) leadsCalcType changed
  //   - delete old default, add new default
  tempAssign = leadsCalcTypeAssign(tempAssign, node);
  //   - update empty startDate (due to added new default)
  tempAssign = updateStartDate(tempAssign, startDate);
  // 2. if priors changed
  //   - delete if bfLink no longer there
  tempAssign = updateBfLinkAssign(tempAssign, priors);
  // 3. if values changed
  //   - linked
  let myEdges = edges.filter((edge) => edge.target === node.id);
  myEdges = deleteOldEdges(tempAssign, myEdges, nodes);
  tempAssign = updateAssignLinked(tempAssign, myEdges, nodes);
  //   - custom
  tempAssign = fillLong(tempAssign, totalMonths);
  tempAssign = updateAssignCustom(tempAssign, myEdges, assumptions, priors, priorLength);
  assignments = [...tempAssign];

  // FORMULAS
  let formulas = [];
  let tempFormulas = [];
  // 1. if (core) leadsCalcType changed
  //   - delete old default, add new default
  tempFormulas = leadsCalcTypeFormulas(tempFormulas, node);
  // 2. if priors changed
  //   - delete if bfLink no longer there
  tempFormulas = updateBfLinkFormulas(tempFormulas, priors);
  // 3. param level - update all linked values - params: {description, value, long}
  tempFormulas = updateFormulasParams(tempFormulas, assignments, totalMonths);
  formulas = [...tempFormulas];

  // OUTPUTS
  const outputs = calculateOutputs(formulas, months, priorLength, priors, priorYear);

  return [priors, assignments, formulas, outputs];
};

// PRIORS

export const calulatePriors = (rows, container) => {
  const priors = [];

  rows.forEach((row) => {
    if (row[row.length - 1] === container) {
      priors.push(row.slice(0, -1));
    }
  });

  return priors;
};

// ASSIGNMENTS

const leadsCalcTypeAssign = (tempAssign, node) => {
  const defaultMustAssign = node.data.assignments.filter(
    (item) => item.type === "Default" && item.description === "Budget"
  );
  const defaultOptionalAssign = node.data.assignments.filter(
    (item) => item.type === "Default" && item.description !== "Budget"
  );
  const customAssign = node.data.assignments.filter((item) => item.type !== "Default");

  if (defaultMustAssign.length === 0) {
    tempAssign.push(assignModel("default_assignments_budget", "Budget"));
  }
  tempAssign.push(...defaultMustAssign); // 1

  const leadsCalcType = node.data.core.leadsCalcType;
  if (leadsCalcType === "Easy") {
    const wasEasy = node.data.assignments.some((item) => item.description === "CPL");
    if (wasEasy) {
      tempAssign.push(...defaultOptionalAssign); // 2
    } else {
      tempAssign.push(assignModel("default_assignments_cpl", "CPL"));
    }
  }
  if (leadsCalcType === "Medium") {
    const wasMedium = node.data.assignments.some((item) => item.description === "CPC");
    if (wasMedium) {
      tempAssign.push(...defaultOptionalAssign); // 2
    } else {
      tempAssign.push(assignModel("default_assignments_cpc", "CPC"));
      tempAssign.push(assignModel("default_assignments_convRate", "Conv Rate"));
    }
  }
  if (leadsCalcType === "Hard") {
    const wasHard = node.data.assignments.some((item) => item.description === "CPM");
    if (wasHard) {
      tempAssign.push(...defaultOptionalAssign); // 2
    } else {
      tempAssign.push(assignModel("default_assignments_cpm", "CPM"));
      tempAssign.push(assignModel("default_assignments_ctr", "CTR"));
      tempAssign.push(assignModel("default_assignments_convRate", "Conv Rate"));
    }
  }
  tempAssign.push(...customAssign); // 3

  return tempAssign;
};

export const updateStartDate = (tempAssign, startDate) => {
  tempAssign.forEach((assignment) => {
    if (assignment.custom.start === "") {
      assignment.custom.start = startDate;
    }
  });

  return tempAssign;
};

export const updateBfLinkAssign = (tempAssign, priors) => {
  tempAssign.forEach((assignment) => {
    if (assignment.bfLink !== null) {
      const exists = priors.find((prior) => prior[0] === assignment.bfLink);
      if (!exists) {
        assignment.bfLink = null;
      }
    }
  });

  return tempAssign;
};

export const deleteOldEdges = (tempAssign, myEdges, nodes) => {
  const deleteEdge = (id) => {
    const { onEdgesChange } = useStore.getState();
    onEdgesChange([{ id: id, type: "remove" }]);
  };

  const edgesToRemove = [];

  myEdges.forEach((edge) => {
    const sourceId = edge.source;
    const sourceHandle = edge.sourceHandle;
    const sourceNode = nodes.find((node) => node.id === sourceId);

    const output = sourceNode.data.outputs.find((output) => output.id === sourceHandle);
    const assignmentIndex = tempAssign.findIndex((assignment) => assignment.id === edge.targetHandle);

    // previously linked output deleted
    // previously linked assignment deleted
    if (!output || assignmentIndex === -1) {
      deleteEdge(edge.id);
      edgesToRemove.push(edge.id);
      return;
    }
  });

  myEdges = myEdges.filter((edge) => !edgesToRemove.includes(edge.id));
  return myEdges;
};

export const updateAssignLinked = (tempAssign, myEdges, nodes) => {
  // sum up all the long arrays
  const accumulatedLongs = {};

  myEdges.forEach((edge) => {
    const sourceId = edge.source;
    const sourceHandle = edge.sourceHandle;
    const sourceNode = nodes.find((node) => node.id === sourceId);
    const output = sourceNode.data.outputs.find((output) => output.id === sourceHandle);

    const targetHandle = edge.targetHandle;
    if (!accumulatedLongs[targetHandle]) {
      accumulatedLongs[targetHandle] = Array(output.long.length).fill(0);
    }
    accumulatedLongs[targetHandle] = accumulatedLongs[targetHandle].map((sum, index) => sum + output.long[index]);
  });

  // update tempAssign with the accumulated long arrays
  myEdges.forEach((edge) => {
    const assignmentIndex = tempAssign.findIndex((assignment) => assignment.id === edge.targetHandle);

    const targetHandle = edge.targetHandle;
    tempAssign[assignmentIndex] = {
      ...tempAssign[assignmentIndex],
      bfLink: null,
      linkCustom: "Link",
      custom: {
        value: 0,
        min: 0,
        max: 0,
        change: 0,
        start: "",
      },
      long: accumulatedLongs[targetHandle],
    };
  });

  return tempAssign;
};

export const fillLong = (tempAssign, totalMonths) => {
  tempAssign.forEach((assignment) => {
    let { long, longCustom } = assignment;

    const filledLong =
      long.length >= totalMonths //
        ? long.slice(0, totalMonths) //
        : [...long, ...Array(totalMonths - long.length).fill(0)];

    const filledLongCustom =
      longCustom.length >= totalMonths
        ? longCustom.slice(0, totalMonths)
        : [...longCustom, ...Array(totalMonths - longCustom.length).fill(null)];

    assignment.long = filledLong;
    assignment.longCustom = filledLongCustom;
  });

  return tempAssign;
};

export const updateAssignCustom = (tempAssign, myEdges, assumptions, priors, priorLength) => {
  const linkedAssign = myEdges.map((edge) => edge.targetHandle);

  tempAssign.forEach((assignment) => {
    if (!linkedAssign.includes(assignment.id)) {
      assignment.linkCustom = "Custom";
      const long = generateLong(assignment, assumptions, priors, priorLength);
      const roundedLong = long.map((element) => Math.round(element * 100) / 100);
      assignment.long = roundedLong;
    }
  });

  return tempAssign;
};

// FORMULAS

const leadsCalcTypeFormulas = (tempFormulas, node) => {
  const defaultFormula = node.data.formulas.filter((item) => item.type === "Default");
  const customFormula = node.data.formulas.filter((item) => item.type === "Custom");

  const leadsCalcType = node.data.core.leadsCalcType;
  if (leadsCalcType === "Easy") {
    const wasEasy = node.data.assignments.some((item) => item.description === "CPL");
    if (wasEasy) {
      tempFormulas.push(...defaultFormula);
    } else {
      tempFormulas.push(...formulaModelEasy);
    }
  }
  if (leadsCalcType === "Medium") {
    const wasMedium = node.data.assignments.some((item) => item.description === "CPC");
    if (wasMedium) {
      tempFormulas.push(...defaultFormula);
    } else {
      tempFormulas.push(...formulaModelMedium);
    }
  }
  if (leadsCalcType === "Hard") {
    const wasHard = node.data.assignments.some((item) => item.description === "CPM");
    if (wasHard) {
      tempFormulas.push(...defaultFormula);
    } else {
      tempFormulas.push(...formulaModelHard);
    }
  }
  tempFormulas.push(...customFormula);

  return tempFormulas;
};

export const updateBfLinkFormulas = (tempFormulas, priors) => {
  tempFormulas.forEach((formula) => {
    if (formula.bfLink !== null) {
      const exists = priors.find((prior) => prior[0] === formula.bfLink);
      if (!exists) {
        formula.bfLink = null;
      }
    }
  });

  return tempFormulas;
};

export const updateFormulasParams = (tempFormulas, assignments, totalMonths) => {
  let formulas = [];

  tempFormulas.forEach((formula) => {
    let updatedParams = [];
    formula.params.forEach((param) => {
      if (param.linkType === "linkAssign") {
        const assignMatch = assignments.find((assign) => assign.id === param.link);
        if (assignMatch) {
          // copy from assignments
          param.description = assignMatch.description;
          param.long = assignMatch.long;
          updatedParams.push(param);
        } else {
          // deleted from assignments
        }
      } else if (param.linkType === "linkFormula") {
        const formulaMatch = tempFormulas.find((formula) => formula.id === param.link);
        if (formulaMatch) {
          // copy from formulas
          param.description = formulaMatch.description;
          updatedParams.push(param);
        } else {
          // deleted from formulas
        }
      } else if (param.linkType === "operator") {
        // update operator
        param.long = Array(totalMonths).fill(param.description);
        updatedParams.push(param);
      } else if (param.linkType === "constant") {
        // update constant
        param.long = Array(totalMonths).fill(param.value);
        updatedParams.push(param);
      }
    });
    formulas.push({ ...formula, params: updatedParams });
  });

  return formulas;
};

// OUTPUTS

export const calculateOutputs = (formulas, months, priorLength, priors, priorYears) => {
  let outputs = [];
  let tempOutputs = [];

  // separate formulas with link to another formula and those without
  const formulasWithLink = formulas.filter((formula) =>
    formula.params.some((param) => param.linkType === "linkFormula")
  );
  const formulasWithoutLink = formulas.filter((formula) =>
    formula.params.every((param) => param.linkType !== "linkFormula")
  );

  // remember the sequence for formulas
  const originalFormulasIds = formulas.map((formula) => formula.id);
  // sort based on topological
  const sortedFormulasIds = topologicalSortFormulas(formulas);

  // calculate those without link first
  const totalMonths = months + priorLength;
  for (let i = 0; i < totalMonths; i++) {
    formulasWithoutLink.forEach((formula) => {
      let operation = [];
      let skipNext = false;
      let value = 0;

      // initialise
      if (i === 0) {
        tempOutputs.push({
          id: formula.id,
          description: formula.description,
          impact: formula.impact,
          long: [],
        });
      }

      // calculation
      if (i < priorLength) {
        // prior year
        if (formula.bfLink === null) {
          value = 0;
        } else {
          const match = priors.find((prior) => prior[0] === formula.bfLink).slice(1);
          value = match[i];
        }
      } else {
        // forecast
        formula.params.forEach((param, index) => {
          if (skipNext) {
            skipNext = false;
            return;
          }

          const nextParam = formula.params[index + 1];
          if (param.long[i] === "last12MonthsOf" && nextParam) {
            const need = 12;
            const last12Values = nextParam.long.slice(Math.max(0, i - 11), i + 1);
            const [numberOfMonths, position] = midCalc(last12Values, i, priorYears, priorLength);

            let last12Total = last12Values[last12Values.length - 1];
            last12Total += calculateLast12Total(last12Values, numberOfMonths, position - 1, need - 1);
            operation.push(last12Total);
            skipNext = true;
          } else {
            operation.push(param.long[i]);
          }
        });
        value = Math.round(calculate(operation));
      }

      const tempOutput = tempOutputs.find((output) => output.id === formula.id);
      tempOutput.long[i] = value;
    });
  }

  // calculate those with link - B/F then Basic then C/F
  const sortedFormulasLink = sortById(formulasWithLink, sortedFormulasIds);
  for (let i = 0; i < totalMonths; i++) {
    sortedFormulasLink.forEach((formula) => {
      let operation = [];
      let skipNext = false;
      let value = 0;

      if (i === 0) {
        tempOutputs.push({
          id: formula.id,
          description: formula.description,
          impact: formula.impact,
          long: [],
        });
      }

      if (i < priorLength) {
        if (formula.bfLink === null) {
          value = 0;
        } else {
          const match = priors.find((prior) => prior[0] === formula.bfLink).slice(1);
          value = match[i];
        }
      } else {
        formula.params.forEach((param, index) => {
          if (skipNext) {
            skipNext = false;
            return;
          }

          if (param.linkType === "linkAssign") {
            operation.push(param.long[i]);
          } else if (param.linkType === "linkFormula") {
            const linkedOutput = tempOutputs.find((output) => output.id === param.link);
            const linkedFormula = formulas.find((f) => f.id === param.link);

            if (formula.calcType === "B/F" && linkedFormula.calcType === "C/F") {
              if (i === 0) {
                operation.push(0);
              } else {
                operation.push(linkedOutput.long[i - 1]);
              }
            } else {
              operation.push(linkedOutput.long[i]);
            }
          } else {
            // linkType === operator and constant
            const nextParam = formula.params[index + 1];
            if (param.long[i] === "last12MonthsOf" && nextParam) {
              if (nextParam.linkType === "linkFormula") {
                const need = 12;
                const linkedOutput = tempOutputs.find((output) => output.id === nextParam.link);
                const last12Values = linkedOutput.long.slice(Math.max(0, i - 11), i + 1);
                const [numberOfMonths, position] = midCalc(last12Values, i, priorYears, priorLength);

                let last12Total = last12Values[last12Values.length - 1];
                last12Total += calculateLast12Total(last12Values, numberOfMonths, position - 1, need - 1);

                operation.push(last12Total);
                skipNext = true;
              }
              if (nextParam.linkType === "linkAssign") {
                const need = 12;
                const last12Values = nextParam.long.slice(Math.max(0, i - 11), i + 1);
                const [numberOfMonths, position] = midCalc(last12Values, i, priorYears, priorLength);

                let last12Total = last12Values[last12Values.length - 1];
                last12Total += calculateLast12Total(last12Values, numberOfMonths, position - 1, need - 1);
                operation.push(last12Total);
                skipNext = true;
              }
            } else {
              operation.push(param.long[i]);
            }
          }
        });
        value = Math.round(calculate(operation));
      }

      const tempOutput = tempOutputs.find((output) => output.id === formula.id);
      tempOutput.long[i] = value;
    });
  }

  // sort outputs back to the original sequence
  outputs = sortById(tempOutputs, originalFormulasIds);

  return outputs;
};

// return numberOfMonths and position - for last12MonthsOf
function midCalc(last12Values, i, priorYears, priorLength) {
  const length = last12Values.length;
  const priorNumberOfMonths = priorYears.headers[1].slice(1, -1);
  const numberOfMonths = Array(length).fill(1);
  if (i === 12) {
  } else if (i < 12) {
    for (let j = 0; j < priorLength; j++) {
      numberOfMonths[j] = priorNumberOfMonths[j];
    }
  } else if (i > 12) {
    const diff = 12 - i + 1;
    for (let j = 0; j < diff; j++) {
      numberOfMonths[j] = priorNumberOfMonths[j];
    }
  }
  let difference = last12Values.length - numberOfMonths.length;
  while (difference > 0) {
    numberOfMonths.push(1);
    difference--;
  }

  const position = last12Values.length - 1;

  return [numberOfMonths, position];
}

// return sum of last 12 - for last12MonthsOf
function calculateLast12Total(last12Values, numberOfMonths, position, need) {
  if (need <= 0) {
    return 0;
  }
  if (position < 0) {
    if (need > 0) {
      return need * last12Values[last12Values.length - 1];
    } else {
      return 0;
    }
  }

  const prevNumberOfMonths = numberOfMonths[position];
  const prevValue = last12Values[position];

  if (prevNumberOfMonths <= 0) {
    return calculateLast12Total(last12Values, numberOfMonths, position - 1, need);
  }

  if (need > prevNumberOfMonths) {
    return prevValue + calculateLast12Total(last12Values, numberOfMonths, position - 1, need - prevNumberOfMonths);
  } else {
    return (prevValue / prevNumberOfMonths) * need;
  }
}
