import styles from "../styles.module.css";
import { useStore } from "../../store";
import { useSaveContext } from "../../../../saveAndRestore/useSaveContext";

import Button from "@mui/material/Button";

// import FileSaver from "file-saver";

const HeaderGenerateEmptySaas = ({ contextId }) => {
  const generateEmptySaas = useStore.getState().generateEmptySaas;

  // save
  const { autoSave } = useSaveContext();
  const handleGenerate = (contextId) => {
    generateEmptySaas(contextId);
    autoSave();
  };

  // const onSaveToFile = () => {
  //   const nodes = store.nodes
  //     .filter((node) => node.contextId === contextId)
  //     .map((node) => ({ ...node, contextId: "" }));
  //   const edges = store.edges
  //     .filter((edge) => edge.contextId === contextId)
  //     .map((edge) => ({ ...edge, contextId: "" }));
  //   const flow = { nodes: nodes, edges: edges };
  //   const blob = new Blob([JSON.stringify(flow, null, 2)], { type: "application/json" });
  //   FileSaver.saveAs(blob, "flow-data.json");
  // };

  return (
    <div className={styles.header_info}>
      {/* <button onClick={onSaveToFile}>Save to File</button> */}
      <CustomButton description="Generate Empty SAAS model" onClick={() => handleGenerate(contextId)} />
    </div>
  );
};

export default HeaderGenerateEmptySaas;

const CustomButton = ({ description, onClick }) => {
  return (
    <Button role={undefined} variant="outlined" tabIndex={-1} sx={{ textTransform: "none" }} onClick={onClick}>
      {description}
    </Button>
  );
};
