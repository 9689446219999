import { usePriorYearsStore } from "../../../stores/usePriorYearsStore";
import { useStatementsAnnualStore } from "../../../stores/useStatementsAnnualStore";
import { useValuationStore } from "../../../stores/useValuationStore";

export const updateValuation = () => {
  const priorYears = usePriorYearsStore.getState().priorYears;
  const annual = useStatementsAnnualStore.getState().statementsAnnual;
  const valuation = useValuationStore.getState().valuation;

  const contextIds = annual.map((item) => item.contextId);

  // remove deleted context
  for (let i = valuation.length - 1; i >= 0; i--) {
    if (!contextIds.includes(valuation[i].contextId)) {
      valuation.splice(i, 1);
    }
  }

  // add new context
  contextIds.forEach((contextId) => {
    const valuationIndex = valuation.findIndex((valuation) => valuation.contextId === contextId);
    const description = annual.find((item) => item.contextId === contextId).description;
    if (valuationIndex !== -1) {
      valuation[valuationIndex].description = description;
    } else {
      valuation.push({
        contextId: contextId,
        description: description,
        fcf: {},
        wacc: {},
        discounted: {},
        tv: {},
        enterprise: {},
      });
    }
  });

  // update fcf
  annual.forEach((_, index) => {
    if (annual[index].sofp.length === 0 || annual[index].sopl.length === 0 || annual[index].socf.length === 0) {
      return;
    }

    const { totalPeriod } = annual[index];
    const { priorLength } = priorYears[index];
    updateFcf(valuation[index], annual[index], totalPeriod);
    updateWacc(valuation[index]);
    updateDiscounted(valuation[index], totalPeriod, priorLength);
    updateTv(valuation[index]);
    updateEnterprise(valuation[index]);
  });
};

const updateFcf = (valuation, annual, totalPeriod) => {
  // wc
  const ca = annual.sofp.find((item) => item.description === "totalCurrentAssetsLong").long;
  const cash = annual.sofp
    .find((item) => item.description === "assetsLongs")
    .long.filter((item) => item.container === "cash")
    .reduce((acc, curr) => {
      return acc.map((num, idx) => num - curr.long[idx]);
    }, Array(totalPeriod).fill(0));
  const cl = annual.sofp
    .find((item) => item.description === "totalCurrentLiabilitiesLong")
    .long.map((value) => negate(value));
  const wc = Array.from({ length: totalPeriod }).map((_, index) => {
    const caI = ca[index] || 0;
    const cashI = cash[index] || 0;
    const clI = cl[index] || 0;
    return caI + cashI + clI;
  });
  const incWc = Array.from({ length: totalPeriod }).map((_, index) => {
    if (index === 0) {
      return wc[index];
    } else {
      return wc[index] - wc[index - 1];
    }
  });

  // fcf
  const ebit = annual.sopl.find((item) => item.description === "totalPbitLong").long;
  const tax = annual.sopl.find((item) => item.description === "totalTaxLong").long.map((value) => negate(value));
  const addBackDep = annual.socf
    .find((item) => item.description === "operatingLongs")
    .long.filter((item) => item.container === "capex")
    .reduce((acc, curr) => {
      return acc.map((num, idx) => num + curr.long[idx]);
    }, Array(totalPeriod).fill(0));
  const purchaseCapex = annual.socf
    .find((item) => item.description === "investingLongs")
    .long.filter((item) => item.container === "capex")
    .reduce((acc, curr) => {
      return acc.map((num, idx) => num + curr.long[idx]);
    }, Array(totalPeriod).fill(0));
  const incWcNeg = incWc.map((value) => negate(value));
  const fcf = Array.from({ length: totalPeriod }).map((_, index) => {
    const ebitI = ebit[index] || 0;
    const taxI = tax[index] || 0;
    const addBackDepI = addBackDep[index] || 0;
    const purchaseCapexI = purchaseCapex[index] || 0;
    const incWcNegI = incWcNeg[index] || 0;
    return ebitI + taxI + addBackDepI + purchaseCapexI + incWcNegI;
  });

  valuation.fcf = { ca, cash, cl, wc, incWc, ebit, tax, addBackDep, purchaseCapex, incWcNeg, fcf };
};

const updateWacc = (valuation) => {
  if (Object.keys(valuation.wacc).length === 0) {
    valuation.wacc = {
      equity: 0,
      debt: 0,
      equityP: 0,
      debtP: 0,
      riskFreeRate: 0,
      marketReturn: 0,
      marketPremium: 0,
      beta: 0,
      costOfEquity: 0,
      costOfDebt: 0,
      taxRate: 0,
      costOfDebtAfterTax: 0,
      wacc: 0,
    };
  } else {
    const { equity, debt, riskFreeRate, marketReturn, beta, costOfDebt, taxRate } = valuation.wacc;

    const equityP = (equity / (debt + equity)) * 100 || 0;
    const debtP = (debt / (debt + equity)) * 100 || 0;
    const marketPremium = marketReturn - riskFreeRate;
    const costOfEquity = riskFreeRate + beta * marketPremium;
    const costOfDebtAfterTax = costOfDebt * (1 - taxRate / 100);
    const wacc = (equityP / 100) * costOfEquity + (debtP / 100) * costOfDebtAfterTax;

    valuation.wacc = { ...valuation.wacc, equityP, debtP, marketPremium, costOfEquity, costOfDebtAfterTax, wacc };
  }

  // 2 decimal places
  for (let key in valuation.wacc) {
    if (typeof valuation.wacc[key] === "number") {
      valuation.wacc[key] = Math.round(valuation.wacc[key] * 100) / 100 || 0;
    }
  }
};

const updateDiscounted = (valuation, totalPeriod, priorLength) => {
  const { fcf } = valuation.fcf;
  const { wacc } = valuation.wacc;

  const df = [];
  for (let i = 0; i < totalPeriod; i++) {
    if (i < priorLength) {
      df.push(0);
    } else {
      const period = i - priorLength + 1;
      const result = 1 / Math.pow(1 + wacc / 100, period);
      df.push(result);
    }
  }

  const pv = [];
  let fcfPvSum = 0;
  for (let i = 0; i < totalPeriod; i++) {
    if (i < priorLength) {
      pv.push(0);
    } else {
      const result = fcf[i] * df[i];
      pv.push(result);
      fcfPvSum += result;
    }
  }

  for (let i = 0; i < totalPeriod; i++) {
    df[i] = Math.round(df[i] * 1000) / 1000;
    pv[i] = Math.round(pv[i]);
  }

  valuation.discounted = { fcf, df, pv, fcfPvSum };
};

const updateTv = (valuation) => {
  if (Object.keys(valuation.tv).length === 0) {
    valuation.tv = {
      wacc: 0,
      growth: 0,
      terminal: 0,
      terminalPv: 0,
    };
  } else {
    const { wacc } = valuation.wacc;
    const growth = Math.round(valuation.tv.growth * 100) / 100 || 0;
    const { df } = valuation.discounted;
    const { fcf } = valuation.fcf;

    let terminal = 0;
    if (growth - wacc !== 0) {
      terminal = Math.round(fcf[fcf.length - 1] * (1 + growth / 100)) / (wacc / 100 - growth / 100);
    }
    const terminalPv = Math.round(terminal * df[df.length - 1]);

    valuation.tv = { wacc, growth, terminal, terminalPv };
  }
};

const updateEnterprise = (valuation) => {
  if (Object.keys(valuation.enterprise).length === 0) {
    valuation.enterprise = {
      fcfPvSum: 0,
      terminalPv: 0,
      enterprise: 0,
    };
  } else {
    const { fcfPvSum } = valuation.discounted;
    const { terminalPv } = valuation.tv;
    const enterprise = fcfPvSum + terminalPv;

    valuation.enterprise = { fcfPvSum, terminalPv, enterprise };
  }
};

const negate = (value) => (value === 0 ? 0 : -value);
