import RightBarCSS from "../../../../styles/pages/RightBar.module.css";

export const InputString = ({ item, ques, keyQues, setOutputs }) => {
  return (
    <div className={RightBarCSS.flex}>
      <span className={RightBarCSS.operators_item}>{ques}</span>
      <span className={RightBarCSS.operators_item}>=</span>
      <input
        type="text"
        name="text"
        min="0"
        className={RightBarCSS.inputTextLong}
        value={item[keyQues]}
        onChange={(e) => setOutputs(keyQues, e.target.value)}
      />
    </div>
  );
};

export const InputStringBlank = ({ value, onChange }) => {
  return <input type="text" name="text" min="0" className={RightBarCSS.inputText} value={value} onChange={onChange} />;
};
