import RightBarCSS from "../../../styles/pages/RightBar.module.css";
import { useStore } from "../store";

const selector = (id, data) => (store) => ({
  setGeneral: (key, value) => {
    store.updateNode(id, { general: { ...data.general, [key]: value } });
  },
});

const ContContextDetail = ({ node }) => {
  const { id, data } = node;
  const { setGeneral } = useStore(selector(id, data));

  return (
    <div className={RightBarCSS.details}>
      <div>
        <div className={RightBarCSS.container_header}>
          <h2>Context</h2>
        </div>

        <>
          <h3>General</h3>
          <div className={RightBarCSS.container}>
            <div className={RightBarCSS.general}>
              <div className={RightBarCSS.whole_line}>
                <span className={RightBarCSS.description}>Description</span>
                <input
                  type="text"
                  name="text"
                  className={RightBarCSS.inputTextLong}
                  value={data.general.description}
                  onChange={(e) => {
                    setGeneral("description", e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default ContContextDetail;
