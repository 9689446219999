import StatementsCSS from "../../styles/pages/Statements.module.css";
import { useState } from "react";

import { useGeneralStore } from "../../stores/useGeneralStore";
import { useStore } from "../flow/store";
import { usePriorYearsStore } from "../../stores/usePriorYearsStore";
import { useStatementsAnnualStore } from "../../stores/useStatementsAnnualStore";
import { useValuationStore } from "../../stores/useValuationStore";

import Valuation from "./Valuation";

const Route = () => {
  const [page, setPage] = useState("Valuation");

  const contextId = useGeneralStore((state) => state.contextId);

  const assumptions = useStore((state) => state.assumptions);
  const priorYears = usePriorYearsStore((state) => state.priorYears);
  const statementsAnnual = useStatementsAnnualStore((state) => state.statementsAnnual);
  const valuations = useValuationStore((state) => state.valuation);

  const priorYear = priorYears.find((item) => item.contextId === contextId);
  const annual = statementsAnnual.find((item) => item.contextId === contextId);
  const valuation = valuations.find((item) => item.contextId === contextId);

  if (!valuation) {
    return <div>Select a contextId.</div>;
  }
  if (Object.keys(valuation.fcf).length === 0) {
    return <div>Context is empty.</div>;
  }

  const { months, startDate, periodEnd } = assumptions;
  const { priorLength } = priorYear;
  const totalMonths = months + priorLength;
  const totalPeriod = annual.totalPeriod;
  const monthsDetails = { months, startDate, periodEnd, priorYear, priorLength, totalMonths, totalPeriod };

  return (
    <div className={StatementsCSS.statements}>
      <div className={StatementsCSS.routes}>
        <button
          className={`${StatementsCSS.route} ${page === "Valuation" ? StatementsCSS.active : ""}`}
          onClick={() => setPage("Valuation")}
        >
          Valuation
        </button>
      </div>

      {page === "Valuation" && <Valuation monthsDetails={monthsDetails} valuation={valuation} />}
    </div>
  );
};

export default Route;
