export const formatValue = (value) => {
  if (value === 0) {
    return "-";
  }

  const absValue = Math.abs(value);
  const formattedValue = absValue.toLocaleString("en-US");
  return value < 0 ? `(${formattedValue})` : formattedValue;
};

export const formatValue2Dp = (value) => {
  if (value === 0) {
    return "-";
  }

  const absValue = Math.abs(value);
  const formattedValue = absValue.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return value < 0 ? `(${formattedValue})` : formattedValue;
};

export const formatDate = (dateString) => {
  const [year, month] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, 1);
  return new Intl.DateTimeFormat("en-US", { month: "short", year: "2-digit" }).format(date);
};
