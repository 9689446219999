import { generateLong } from "./utilsGenerate";

export const calculateContDirectCosts = (nodes, node, edges, assumptions, priorYearss) => {
  const { months, startDate } = assumptions;
  const priorYears = priorYearss.find((priorYear) => priorYear.contextId === node.contextId);
  const { priorLength } = priorYears;
  const totalMonths = months + priorLength;

  // inputsLink
  let inputsLink = [];

  const myEdges = edges.filter((edge) => edge.target === node.id);
  myEdges.forEach((edge) => {
    const sourceId = edge.source;
    const sourceHandle = edge.sourceHandle;

    const sourceNode = nodes.find((node) => node.id === sourceId);
    const output = sourceNode.data.outputs.find((output) => output.id === sourceHandle);
    if (output) {
      inputsLink.push({
        id: output.id,
        description: output.description,
        long: output.long,
        impact: "",
      });
    }
  });

  // inputs
  let inputsSelf = [...node.data.inputsSelf];
  inputsSelf = inputsSelf.map((item) => updateStartDate(item, startDate));
  inputsSelf = inputsSelf.map((item) => fillLong(item, totalMonths));
  inputsSelf = inputsSelf.map((item) => updateValues(item, assumptions, totalMonths, priorLength));

  // outputs
  let outputs = [];
  inputsLink.forEach((input) => {
    outputs = [...outputs, input];
  });
  inputsSelf.forEach((input) => {
    const { children, ...rest } = input;
    outputs = [...outputs, rest];
  });

  const outputsBefore = node.data.outputs;
  const total = outputsBefore[outputsBefore.length - 1];

  const initialTotalLong = Array(totalMonths).fill(0);
  total.long = [...inputsLink, ...inputsSelf].reduce((acc, child) => {
    return acc.map((num, idx) => num + child.long[idx]);
  }, initialTotalLong);

  outputs = [...outputs, total];

  return [inputsLink, inputsSelf, outputs];
};

const updateStartDate = (item, startDate) => {
  if (item.children.length === 0) {
    if (item.custom.start === "") {
      item.custom.start = startDate;
    }

    return { ...item };
  }
  if (item.children.length > 0) {
    const children = item.children.map((child) => updateStartDate(child, startDate));

    return { ...item, children: children };
  }
};

const fillLong = (item, months) => {
  if (item.children.length === 0) {
    let { long, longCustom } = item;
    if (!long) long = [];
    long = long.map((value) => (value === undefined ? 0 : value));
    item.long = long.length >= months ? long.slice(0, months) : [...long, ...Array(months - long.length).fill(0)];
    item.longCustom =
      longCustom.length >= months
        ? longCustom.slice(0, months)
        : [...longCustom, ...Array(months - longCustom.length).fill(null)];

    return { ...item };
  }
  if (item.children.length > 0) {
    const children = item.children.map((child) => fillLong(child, months));

    return { ...item, children: children };
  }
};

const updateValues = (item, assumptions, totalMonths, priorLength) => {
  if (item.children.length === 0) {
    const long = generateLong(item, assumptions, [], priorLength);
    const roundedLong = long.map((element) => Math.round(element));

    return { ...item, long: roundedLong };
  }
  if (item.children.length > 0) {
    const children = item.children.map((child) => updateValues(child, assumptions, totalMonths, priorLength));

    const initialLong = Array(totalMonths).fill(0);
    const long = children.reduce((acc, child) => {
      return acc.map((num, idx) => num + child.long[idx]);
    }, initialLong);

    return { ...item, long: long, children: children };
  }
};
