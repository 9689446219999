import {
  nonEditable,
  // showZero,
  emptyTextCell,
  textCell,
  numberCell,
  dateCell,
  dropdownCell,
  deleteColButtonCell,
  deleteRowButtonCell,
} from "../../statements/reactgrid/cells";

import {
  // ROW_HEIGHT_28,
  // ROW_HEIGHT_34,
  ROW_HEIGHT_40,
  // ROW_HEIGHT_44
} from "../../statements/reactgrid/getRows";

const getDeleteColButtonRow = ({ monthsDetails }) => {
  const { priorLength } = monthsDetails;

  const cells = [];
  for (let colIndex = 1; colIndex < priorLength + 1; colIndex++) {
    cells.push(deleteColButtonCell("delete col", colIndex));
  }

  const rows = [
    {
      rowId: "deleteButton",
      height: ROW_HEIGHT_40,
      cells: [nonEditable(emptyTextCell()), ...cells, nonEditable(emptyTextCell()), nonEditable(emptyTextCell())],
    },
  ];

  return rows;
};

const getHeaderRow = ({ monthsDetails, headersData }) => {
  const { priorLength } = monthsDetails;

  const className1 = "text-lg font-weight-500 border-none bg-header text-white";
  const className2 = "text-lg font-weight-500 border-none bg-header-edit text-white justify-content-center";

  const rows = [
    {
      rowId: 0,
      height: ROW_HEIGHT_40,
      cells: [
        ...headersData[0].map((data, index) => {
          if (index === 0) {
            return nonEditable(textCell(data, className1));
          } else if (index === priorLength + 1) {
            return nonEditable(textCell("", className1));
          } else {
            return dateCell(data, className2);
          }
        }),
        nonEditable(textCell("", className1)),
      ],
    },
    {
      rowId: 1,
      height: ROW_HEIGHT_40,
      cells: [
        ...headersData[1].map((data, index) => {
          if (index === 0) {
            return nonEditable(textCell(data, className1));
          } else if (index === priorLength + 1) {
            return nonEditable(textCell("", className1));
          } else {
            return numberCell(data, className2);
          }
        }),
        nonEditable(textCell("", className1)),
      ],
    },
    {
      rowId: 2,
      height: ROW_HEIGHT_40,
      cells: [
        ...headersData[2].map((data) => {
          return nonEditable(textCell(data, className1));
        }),
        nonEditable(textCell("", className1)),
      ],
    },
  ];

  return rows;
};

const getContentRow = ({ monthsDetails, rowsData }) => {
  const { priorLength } = monthsDetails;
  const className1 = "text-md font-weight-400";

  const rows = rowsData.map((rowData, rowIndex) => ({
    rowId: rowIndex + 3,
    height: ROW_HEIGHT_40,
    cells: [
      ...rowData.map((data, index) => {
        if (index === 0) {
          return textCell(data, className1);
        } else if (index === priorLength + 1) {
          return dropdownCell(data, className1);
        } else {
          return numberCell(data, className1);
        }
      }),
      deleteRowButtonCell("delete row", rowIndex),
    ],
  }));

  return rows;
};

export const getRows = ({ monthsDetails, headersData, rowsData }) => {
  const rows = [
    ...getDeleteColButtonRow({ monthsDetails }),
    ...getHeaderRow({ monthsDetails, headersData }),
    ...getContentRow({ monthsDetails, rowsData }),
  ];

  return rows;
};
