import ContContextNode from "./contextNodes/ContContextNode";

import ContBlankNode from "./containerNodes/SOPL/ContBlankNode";
import ContMarketingNode from "./containerNodes/SOPL/ContMarketingNode";
import ContSalesNode from "./containerNodes/SOPL/ContSalesNode";
import ContRevenueNode from "./containerNodes/SOPL/ContRevenueNode";
import ContStaffNode from "./containerNodes/SOPL/ContStaffNode";
import ContExpensesNode from "./containerNodes/SOPL/ContExpensesNode";
import ContDirectCostsNode from "./containerNodes/SOPL/ContDirectCostsNode";
import ContIndirectCostsNode from "./containerNodes/SOPL/ContIndirectCostsNode";
import ContProfitNode from "./containerNodes/SOPL/ContProfitNode";
import ContCapexNode from "./containerNodes/SOFP/ContCapexNode";
import ContLoanNode from "./containerNodes/SOFP/ContLoanNode";
import ContReceivablesNode from "./containerNodes/SOFP/ContReceivablesNode";
import ContPayablesNode from "./containerNodes/SOFP/ContPayablesNode";
import ContShareCapNode from "./containerNodes/SOFP/ContShareCapNode";
import ContRetainedNode from "./containerNodes/SOFP/ContRetainedNode";
import ContCashNode from "./containerNodes/SOFP/ContCashNode";
import ContSofpNode from "./containerNodes/SOFP/ContSofpNode";

import ButtonEdge from "./edges/ButtonEdge";
export const edgeTypes = {
  buttonedge: ButtonEdge,
};

export const nodeTypes = {
  contContext: ContContextNode,

  contBlank: ContBlankNode,
  contMarketing: ContMarketingNode,
  contSales: ContSalesNode,
  contRevenue: ContRevenueNode,
  contStaff: ContStaffNode,
  contExpenses: ContExpensesNode,
  contDirectCosts: ContDirectCostsNode,
  contIndirectCosts: ContIndirectCostsNode,
  contProfit: ContProfitNode,
  contCapex: ContCapexNode,
  contLoan: ContLoanNode,
  contReceivables: ContReceivablesNode,
  contPayables: ContPayablesNode,
  contShareCap: ContShareCapNode,
  contRetained: ContRetainedNode,
  contCash: ContCashNode,
  contSofp: ContSofpNode,
};

export const selector = (view, contextId) => (store) => {
  return {
    ...(view === "view0" && {
      addContContext: (x, y) => store.createNode("contContext", view, "context", contextId, x, y),
    }),
    ...(view === "view1" && {
      addContBlank: (x, y) => store.createNode("contBlank", view, "blank", contextId, x, y),
      addContMarketing: (x, y) => store.createNode("contMarketing", view, "marketing", contextId, x, y),
      addContSales: (x, y) => store.createNode("contSales", view, "sales", contextId, x, y),
      addContRevenue: (x, y) => store.createNode("contRevenue", view, "revenue", contextId, x, y),
      addContStaff: (x, y) => store.createNode("contStaff", view, "staff", contextId, x, y),
      addContExpenses: (x, y) => store.createNode("contExpenses", view, "expenses", contextId, x, y),
      addContDirectCosts: (x, y) => store.createNode("contDirectCosts", view, "directCosts", contextId, x, y),
      addContIndirectCosts: (x, y) => store.createNode("contIndirectCosts", view, "indirectCosts", contextId, x, y),
      addContProfit: (x, y) => store.createNode("contProfit", view, "profit", contextId, x, y),
      addContCapex: (x, y) => store.createNode("contCapex", view, "capex", contextId, x, y),
      addContLoan: (x, y) => store.createNode("contLoan", view, "loan", contextId, x, y),
      addContReceivables: (x, y) => store.createNode("contReceivables", view, "receivables", contextId, x, y),
      addContPayables: (x, y) => store.createNode("contPayables", view, "payables", contextId, x, y),
      addContShareCap: (x, y) => store.createNode("contShareCap", view, "shareCap", contextId, x, y),
      addContRetained: (x, y) => store.createNode("contRetained", view, "retained", contextId, x, y),
      addContCash: (x, y) => store.createNode("contCash", view, "cash", contextId, x, y),
      addContSofp: (x, y) => store.createNode("contSofp", view, "sofp", contextId, x, y),
    }),
  };
};
