import styles from "../styles.module.css";

const Burger = ({ hasSidebar, setHasSidebar }) => (
  <div onClick={() => setHasSidebar(!hasSidebar)}>
    {hasSidebar ? (
      <svg className={styles.burger} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M20 20L4 4m16 0L4 20" />
      </svg>
    ) : (
      <svg className={styles.burger} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3 6h18M3 12h18M3 18h18"
        />
      </svg>
    )}
  </div>
);

export default Burger;
