import styles from "./styles.module.css";
import { memo } from "react";
import { useStore } from "../store";
import { selector } from "../constants";
import { Panel } from "@xyflow/react";

const View1Panel = ({ view, contextId, onDragStart }) => {
  const store = useStore(selector(view, contextId));

  return (
    <Panel className={styles.panel}>
      <p>Containers</p>
      <button
        className={styles.general}
        onClick={() => store.addContBlank()}
        onDragStart={(event) => onDragStart(event, "addContBlank")}
        draggable
      >
        <span></span>Blank
      </button>
      <button
        className={styles.marketing}
        onClick={() => store.addContMarketing()}
        onDragStart={(event) => onDragStart(event, "addContMarketing")}
        draggable
      >
        <span></span>Marketing
      </button>
      <button
        className={styles.revenue}
        onClick={() => store.addContSales()}
        onDragStart={(event) => onDragStart(event, "addContSales")}
        draggable
      >
        <span></span>Sales
      </button>
      <button
        className={styles.revenue}
        onClick={() => store.addContRevenue()}
        onDragStart={(event) => onDragStart(event, "addContRevenue")}
        draggable
      >
        <span></span>Revenue
      </button>
      <button
        className={styles.staff}
        onClick={() => store.addContStaff()}
        onDragStart={(event) => onDragStart(event, "addContStaff")}
        draggable
      >
        <span></span>Staff
      </button>
      <button
        className={styles.expenses}
        onClick={() => store.addContDirectCosts()}
        onDragStart={(event) => onDragStart(event, "addContDirectCosts")}
        draggable
      >
        <span></span>Direct Costs
      </button>
      <button
        className={styles.expenses}
        onClick={() => store.addContIndirectCosts()}
        onDragStart={(event) => onDragStart(event, "addContIndirectCosts")}
        draggable
      >
        <span></span>Indirect Costs
      </button>
      <button
        className={styles.expenses}
        onClick={() => store.addContExpenses()}
        onDragStart={(event) => onDragStart(event, "addContExpenses")}
        draggable
      >
        <span></span>Expenses
      </button>
      <button
        className={styles.profit}
        onClick={() => store.addContProfit()}
        onDragStart={(event) => onDragStart(event, "addContProfit")}
        draggable
      >
        <span></span>Profit / (Loss)
      </button>
      <button
        className={styles.capex}
        onClick={() => store.addContCapex()}
        onDragStart={(event) => onDragStart(event, "addContCapex")}
        draggable
      >
        <span></span>CAPEX
      </button>
      <button
        className={styles.loan}
        onClick={() => store.addContLoan()}
        onDragStart={(event) => onDragStart(event, "addContLoan")}
        draggable
      >
        <span></span>Loan
      </button>
      <button
        className={styles.receivables}
        onClick={() => store.addContReceivables()}
        onDragStart={(event) => onDragStart(event, "addContReceivables")}
        draggable
      >
        <span></span>Receivables
      </button>
      <button
        className={styles.payables}
        onClick={() => store.addContPayables()}
        onDragStart={(event) => onDragStart(event, "addContPayables")}
        draggable
      >
        <span></span>Payables
      </button>
      <button
        className={styles.equity}
        onClick={() => store.addContShareCap()}
        onDragStart={(event) => onDragStart(event, "addContShareCap")}
        draggable
      >
        <span></span>Share capital
      </button>
      <button
        className={styles.equity}
        onClick={() => store.addContRetained()}
        onDragStart={(event) => onDragStart(event, "addContRetained")}
        draggable
      >
        <span></span>Retained Earnings
      </button>
      <button
        className={styles.cash}
        onClick={() => store.addContCash()}
        onDragStart={(event) => onDragStart(event, "addContCash")}
        draggable
      >
        <span></span>Cash
      </button>
      <button
        className={styles.sofp}
        onClick={() => store.addContSofp()}
        onDragStart={(event) => onDragStart(event, "addContSofp")}
        draggable
      >
        <span></span>SOFP
      </button>
    </Panel>
  );
};

export default memo(View1Panel);
