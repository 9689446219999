import FlowCSS from "../../../../styles/pages/Flow.module.css";
import { Handle } from "@xyflow/react";
import { formatValue } from "./Format";

export const InputNode = ({ name, value, handleId }) => {
  const leftStyle = { top: "50%", left: "-1rem", width: "1rem", height: "1rem", backgroundColor: "pink" };

  if (value === undefined) {
    value = 0;
  }

  return (
    <div className={FlowCSS.inOutNode}>
      <div className={FlowCSS.handle}>
        <Handle id={handleId} type="target" position="left" style={leftStyle} />
      </div>
      <div className={FlowCSS.display}>
        <span>
          {name} : {formatValue(value)}
        </span>
      </div>
    </div>
  );
};

export const OutputNode = ({ name, value, handleId }) => {
  const rightStyle = { top: "50%", right: "-1rem", width: "1rem", height: "1rem", backgroundColor: "pink" };

  if (value === undefined) {
    value = 0;
  }

  return (
    <div className={FlowCSS.inOutNode}>
      <div className={FlowCSS.display}>
        <span>
          {name}: {formatValue(value)}
        </span>
      </div>
      <Handle id={handleId} type="source" position="right" style={rightStyle} />
    </div>
  );
};

export const DisplayOutputs = ({ outputs, priorLength }) => {
  const outputsNone = outputs.filter((output) => output.impact === "");
  const outputsSOFP = outputs.filter((output) => output.impact === "SOFP");
  const outputsSOPL = outputs.filter((output) => output.impact === "SOPL");
  const outputsSOCF = outputs.filter((output) => output.impact === "SOCF");

  return (
    <>
      <h3>Outputs</h3>
      {outputsNone.length > 0 && (
        <>
          <div className={FlowCSS.big}>
            {outputsNone.map((item) => (
              <OutputNode key={item.id} name={item.description} value={item.long[priorLength]} handleId={item.id} />
            ))}
          </div>
        </>
      )}
      {outputsSOFP.length > 0 && (
        <>
          <h4>SOFP</h4>
          <div className={FlowCSS.big}>
            {outputsSOFP.map((item) => (
              <OutputNode key={item.id} name={item.description} value={item.long[priorLength]} handleId={item.id} />
            ))}
          </div>
        </>
      )}
      {outputsSOPL.length > 0 && (
        <>
          <h4>SOPL</h4>
          <div className={FlowCSS.big}>
            {outputsSOPL.map((item) => (
              <OutputNode key={item.id} name={item.description} value={item.long[priorLength]} handleId={item.id} />
            ))}
          </div>
        </>
      )}
      {outputsSOCF.length > 0 && (
        <>
          <h4>SOCF</h4>
          <div className={FlowCSS.big}>
            {outputsSOCF.map((item) => (
              <OutputNode key={item.id} name={item.description} value={item.long[priorLength]} handleId={item.id} />
            ))}
          </div>
        </>
      )}
    </>
  );
};
