const COL_HEADER_WIDTH = 360;
const COL_CONTENT_WIDTH = 240; // 120

export const getColumns = ({ monthsDetails }) => {
  const { priorLength } = monthsDetails;

  const columns = [];

  for (let i = 0; i <= priorLength + 2; i++) {
    if (i === 0) {
      columns.push({
        columnId: i,
        width: COL_HEADER_WIDTH,
      });
    } else if (i === priorLength + 2) {
      columns.push({
        columnId: i,
        width: COL_CONTENT_WIDTH,
      });
    } else {
      columns.push({
        columnId: i,
        width: COL_CONTENT_WIDTH,
      });
    }
  }

  return columns;
};
