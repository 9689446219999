import { useStatementsStore } from "../../../stores/useStatementsStore";
import { useActualStore } from "../../../stores/useActualStore";
import { useDifferencesStore } from "../../../stores/useDifferencesStore";

export const updateDifferences = () => {
  const { setDifferences } = useDifferencesStore.getState();
  setDifferences([]);

  const statements = useStatementsStore.getState().statements;
  const actual = useActualStore.getState().actual;
  const differences = useDifferencesStore.getState().differences;

  const contextIds = statements.map((statement) => statement.contextId);

  // remove deleted context
  for (let i = differences.length - 1; i >= 0; i--) {
    if (!contextIds.includes(differences[i].contextId)) {
      differences.splice(i, 1);
    }
  }

  // add new context
  contextIds.forEach((contextId) => {
    const differencesIndex = differences.findIndex((difference) => difference.contextId === contextId);
    const description = statements.find((statement) => statement.contextId === contextId).description;
    if (differencesIndex !== -1) {
      differences[differencesIndex].description = description;
    } else {
      differences.push({
        contextId: contextId,
        description: description,
        sofp: [],
        sopl: [],
        socf: [],
      });
    }
  });

  // copy over and calculate
  statements.forEach((_, index) => {
    copyOver("sofp", statements[index], actual[index], differences[index]);
    copyOver("sopl", statements[index], actual[index], differences[index]);
    copyOver("socf", statements[index], actual[index], differences[index]);
  });
};

const copyOver = (section, statement, actual, differences) => {
  const statementSection = statement[section];
  const actualSection = actual[section];

  differences[section] = statementSection.map((layer1, layer1Index) => {
    const statementLayer1 = statementSection[layer1Index];
    const actualLayer1 = actualSection[layer1Index];

    return {
      description: layer1.description,
      type: layer1.type,

      ...(layer1.type === "array" && {
        statements: statementLayer1.long,
        actual: actualLayer1.long,
        differenceM: actualLayer1.long.map((actual, index) => actual - statementLayer1.long[index]),
        differenceP: actualLayer1.long.map((actual, index) => {
          const statementsValue = statementLayer1.long[index];
          const diffP = ((actual - statementsValue) / statementsValue) * 100;
          return isNaN(diffP) || !isFinite(diffP) ? 0 : Math.round(diffP);
        }),
      }),

      ...(layer1.type === "object" && {
        long: layer1.long.map((layer2, layer2Index) => {
          const statementLayer2 = statementLayer1.long[layer2Index];
          const actualLayer2 = actualLayer1.long[layer2Index];

          return {
            id: layer2.id,
            container: layer2.container,
            description: layer2.description,
            statements: statementLayer2.long,
            actual: actualLayer2.long,
            differenceM: actualLayer2.long.map((actual, index) => actual - statementLayer2.long[index]),
            differenceP: actualLayer2.long.map((actual, index) => {
              const statementsValue = statementLayer2.long[index];
              const diffP = ((actual - statementsValue) / statementsValue) * 100;
              return isNaN(diffP) || !isFinite(diffP) ? 0 : Math.round(diffP);
            }),
          };
        }),
      }),
    };
  });
};
