import RightBarCSS from "../../../../styles/pages/RightBar.module.css";
import { useStore } from "../../store";
import { usePriorYearsStore } from "../../../../stores/usePriorYearsStore";
import { nanoid } from "nanoid";
import { AssignmentsBoxStaff, OutputsBoxSpecial, BreakdownBox } from "../SOFP/NodeUtils";
import { PriorsBox } from "../utils/RightBarBox";

const selector = (id, data) => (store) => ({
  assumptions: store.assumptions,
  setGeneral: (key, value) => {
    store.updateNode(id, { general: { ...data.general, [key]: value } });
  },
  setAssigns: (index, key, value) => {
    const updated = [...data.assignments];

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    updated[index] = { ...updated[index], [key]: value };
    store.updateNode(id, { assignments: updated });
  },
  setAssignsParam: (index, paramIndex, key, value) => {
    const updated = [...data.assignments];

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    if (
      key === "value" &&
      updated[index].params[paramIndex].custom.value === updated[index].params[paramIndex].custom.max
    ) {
      updated[index].params[paramIndex].custom = {
        ...updated[index].params[paramIndex].custom,
        value: value,
        max: value,
      };
      store.updateNode(id, { assignments: updated });
      return;
    }

    updated[index].params[paramIndex].custom = { ...updated[index].params[paramIndex].custom, [key]: value };
    store.updateNode(id, { assignments: updated });
  },
  setCustomLong: (index, paramIndex, colIndex, value) => {
    const updated = [...data.assignments];

    if (isNaN(value) || !isFinite(value)) {
      value = null;
    }

    if (value === null) {
      updated[index].params[paramIndex].longCustom[colIndex] = value;
      store.updateNode(id, { assignments: updated });
      return;
    }

    if (updated[index].params[paramIndex].long[colIndex] !== value) {
      updated[index].params[paramIndex].longCustom[colIndex] = value;
      store.updateNode(id, { assignments: updated });
    }
  },
  resetCustomLong: (index, paramIndex) => {
    const updated = [...data.assignments];

    updated[index].params[paramIndex].longCustom = updated[index].params[paramIndex].longCustom.map(() => null);
    store.updateNode(id, { assignments: updated });
  },
  addAssign: () => {
    const assignmentId = nanoid();

    store.updateNode(id, {
      assignments: [
        ...data.assignments,
        {
          id: assignmentId,
          description: "",
          custom: {
            start: "",
          },
          long: [],
          params: [
            {
              id: `${assignmentId}_0`,
              description: "Number of Staff",
              bfLink: null,
              linkCustom: "Custom",
              custom: {
                value: 0,
                min: 0,
                max: 0,
                change: 0,
              },
              long: [],
              longCustom: [],
            },
            {
              id: `${assignmentId}_1`,
              description: "Salary",
              bfLink: null,
              linkCustom: "Custom",
              custom: {
                value: 0,
                min: 0,
                max: 0,
                change: 0,
              },
              long: [],
              longCustom: [],
            },
            {
              id: `${assignmentId}_2`,
              description: "Burden Rate (%)",
              bfLink: null,
              linkCustom: "Custom",
              custom: {
                value: 0,
                min: 0,
                max: 0,
                change: 0,
              },
              long: [],
              longCustom: [],
            },
          ],
        },
      ],
    });
  },
  deleteAssign: (index) => {
    const updated = [...data.assignments];
    updated.splice(index, 1);
    store.updateNode(id, { assignments: updated });
  },
  setOutputs: (index, key, value) => {
    const updated = [...data.outputs];

    if (typeof value === "number") {
      if (isNaN(value) || !isFinite(value)) {
        value = 0;
      }
    }

    updated[index] = { ...updated[index], [key]: value };
    store.updateNode(id, { outputs: updated });
  },
});

const ContStaffDetail = ({ node }) => {
  const { id, data, contextId } = node;
  const {
    assumptions,
    setGeneral,
    setAssigns,
    setAssignsParam,
    setCustomLong,
    resetCustomLong,
    addAssign,
    deleteAssign,
    setOutputs,
  } = useStore(selector(id, data));

  const getPriorYears = usePriorYearsStore.getState().getPriorYears;
  const priorYears = getPriorYears(contextId);

  return (
    <div className={RightBarCSS.details}>
      <div>
        <div className={RightBarCSS.container_header}>
          <h2>Staff</h2>
        </div>

        <>
          <h3>General</h3>
          <div className={RightBarCSS.container}>
            <div className={RightBarCSS.general}>
              <div className={RightBarCSS.whole_line}>
                <span className={RightBarCSS.description}>Description</span>
                <input
                  type="text"
                  name="text"
                  className={RightBarCSS.inputTextLong}
                  value={data.general.description}
                  onChange={(e) => {
                    setGeneral("description", e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </>

        {data.priors.length > 0 && (
          <>
            <h3>Priors</h3>
            <div className={RightBarCSS.container}>
              <div className={RightBarCSS.priors}>
                <PriorsBox priors={data.priors} startDate={assumptions.startDate} priorYears={priorYears} />
              </div>
            </div>
          </>
        )}

        <>
          <h3>Inputs</h3>
          <div className={RightBarCSS.container}>
            <div className={RightBarCSS.assignments}>
              <AssignmentsBoxStaff
                assignments={data.assignments}
                setAssigns={setAssigns}
                setAssignsParam={setAssignsParam}
                setCustomLong={setCustomLong}
                resetCustomLong={resetCustomLong}
                addAssign={addAssign}
                deleteAssign={deleteAssign}
                assumptions={assumptions}
                priorYears={priorYears}
                individuals={data.individuals}
              />
            </div>
          </div>
        </>

        <>
          <h3>Breakdown</h3>
          <div className={RightBarCSS.container}>
            <div className={RightBarCSS.individuals}>
              <BreakdownBox
                assignments={data.assignments}
                assumptions={assumptions}
                priorYears={priorYears}
                individuals={data.individuals}
              />
            </div>
          </div>
        </>

        <>
          <h3>Outputs</h3>
          <div className={RightBarCSS.container}>
            <div className={RightBarCSS.outputs}>
              <OutputsBoxSpecial
                outputs={data.outputs}
                assumptions={assumptions}
                priorYears={priorYears}
                setOutputs={setOutputs}
              />
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default ContStaffDetail;
