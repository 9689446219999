import { nanoid } from "nanoid";

const COL_HEADER_WIDTH = 360;
const COL_CONTENT_WIDTH = 120;

export const getColumns = ({ monthly, monthsDetails }) => {
  const { totalMonths, totalPeriod } = monthsDetails;

  let months = 0;
  if (monthly) {
    months = totalMonths;
  } else {
    months = totalPeriod;
  }

  const columns = [
    {
      columnId: nanoid(),
      width: COL_HEADER_WIDTH,
    },
  ];

  for (let i = 0; i < months; i++) {
    columns.push({
      columnId: nanoid(),
      width: COL_CONTENT_WIDTH,
    });
  }

  return columns;
};
