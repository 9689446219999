import { create } from "zustand";
import { nanoid } from "nanoid";
import { applyNodeChanges, applyEdgeChanges } from "@xyflow/react";
import cloneDeep from "lodash/cloneDeep";
import { topologicalSortNodes } from "./utilsFunctions/topologicalSortNodes";

import { updateFlow } from "./update/updateFlow";
import { updatePriorYears } from "./update/updatePriorYears";
import { updateStatements } from "./update/updateStatements";
import { updateStatementsAnnual } from "./update/updateStatementsAnnual";
import { updateActual } from "./update/updateActual";
import { updateDifferences } from "./update/updateDifferences";
import { updateValuation } from "./update/updateValuation";
import { updateDashboard } from "./update/updateDashboard";
import { updateReport } from "./update/updateReport";

import sampleData from "./samples/sample.json";
import saasData from "./samples/saas.json";

const dataItems = {
  contContext: {
    general: { description: "Context", expand: true },
  },

  contCapex: {
    general: { description: "CAPEX", expand: true },
    core: {},
    priors: [],
    inputs: [],
    assignments: [],
    formulas: [],
    individuals: [],
    outputs: [
      { id: "default_capex_cost", impact: "", description: "Cost", bfLink: null, long: [] },
      { id: "default_capex_accDep", impact: "", description: "Accumulated Depreciation", bfLink: null, long: [] },
      { id: "default_capex_capexcf", impact: "SOFP", description: "CAPEX c/f", bfLink: null, long: [] },
      { id: "default_capex_depExp", impact: "SOPL", description: "Depreciation Expense", bfLink: null, long: [] },
      { id: "default_capex_addBack", impact: "SOCF", description: "Add back depreciation", bfLink: null, long: [] },
      { id: "default_capex_purchase", impact: "SOCF", description: "Purchase of CAPEX", bfLink: null, long: [] },
    ],
  },
  contLoan: {
    general: { description: "Loan", expand: true },
    core: {},
    priors: [],
    inputs: [],
    assignments: [],
    formulas: [],
    individuals: [],
    outputs: [
      { id: "default_loan_loancf", impact: "SOFP", description: "Loan c/f", bfLink: null, long: [] },
      { id: "default_loan_intExp", impact: "SOPL", description: "Interest Expense", bfLink: null, long: [] },
      { id: "default_loan_addBack", impact: "SOCF", description: "Add back interest", bfLink: null, long: [] },
      { id: "default_loan_raise", impact: "SOCF", description: "Raise of Loan", bfLink: null, long: [] },
      { id: "default_loan_repayment", impact: "SOCF", description: "Repayment of Loan", bfLink: null, long: [] },
    ],
  },
  contShareCap: {
    general: { description: "Share Capital", expand: true },
    core: {},
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_raised",
        description: "Capital raised",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_capitalbf",
        description: "Share capital b/f",
        type: "Default",
        bfLink: null,
        calcType: "B/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_capitalcf",
            description: "Share capital c/f",
          },
        ],
      },
      {
        id: "default_formulas_capitalcf",
        description: "Share capital c/f",
        type: "Default",
        bfLink: null,
        calcType: "C/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_capitalbf",
            description: "Share capital b/f",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "+",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_raised",
            description: "Capital raised",
          },
        ],
      },
      {
        id: "default_formulas_raised",
        description: "Capital raised",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "SOCF",
        params: [
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_raised",
            description: "Capital raised",
          },
        ],
      },
    ],
    outputs: [
      { id: "default_formulas_capitalbf", impact: "SOFP", description: "Share capital b/f", long: [] },
      { id: "default_formulas_capitalcf", impact: "SOFP", description: "Share capital c/f", long: [] },
      { id: "default_formulas_raised", impact: "SOCF", description: "Capital raised", long: [] },
    ],
  },
  contRetained: {
    general: { description: "Retained Earnings", expand: true },
    core: {},
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_profit",
        description: "Profit / (Loss)",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_dividend",
        description: "Dividend paid",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_rebf",
        description: "Retained earnings b/f",
        type: "Default",
        bfLink: null,
        calcType: "B/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_recf",
            description: "Retained earnings c/f",
          },
        ],
      },
      {
        id: "default_formulas_recf",
        description: "Retained earnings c/f",
        type: "Default",
        bfLink: null,
        calcType: "C/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_rebf",
            description: "Retained earnings b/f",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "+",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_profit",
            description: "Profit / (Loss)",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_dividend",
            description: "Dividend paid",
          },
        ],
      },
      {
        id: "default_formulas_dividend",
        description: "Dividend paid",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "SOCF",
        params: [
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_dividend",
            description: "Dividend paid",
          },
        ],
      },
    ],
    outputs: [
      { id: "default_formulas_rebf", impact: "SOFP", description: "Retained earnings b/f", long: [] },
      { id: "default_formulas_recf", impact: "SOFP", description: "Retained earnings c/f", long: [] },
      { id: "default_formulas_dividend", impact: "SOCF", description: "Dividend paid", long: [] },
    ],
  },
  contReceivables: {
    general: { description: "Receivables", expand: true },
    core: {},
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_revenue",
        description: "Revenue",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_dso",
        description: "DSO",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_receivablesbf",
        description: "Receivables b/f",
        type: "Default",
        bfLink: null,
        calcType: "B/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_receivablescf",
            description: "Receivables c/f",
          },
        ],
      },
      {
        id: "default_formulas_receivablescf",
        description: "Receivables c/f",
        type: "Default",
        bfLink: null,
        calcType: "C/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "last12MonthsOf",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_revenue",
            description: "Revenue",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "*",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_dso",
            description: "DSO",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "/",
          },
          {
            id: nanoid(),
            linkType: "constant",
            link: "",
            description: "365",
          },
        ],
      },
      {
        id: "default_formulas_change",
        description: "Change in receivables",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "SOCF",
        params: [
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "(",
          },
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_receivablescf",
            description: "Receivables c/f",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_receivablesbf",
            description: "Receivables b/f",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: ")",
          },
        ],
      },
    ],
    outputs: [
      { id: "default_formulas_receivablesbf", impact: "SOFP", description: "Receivables b/f", long: [] },
      { id: "default_formulas_receivablescf", impact: "SOFP", description: "Receivables c/f", long: [] },
      { id: "default_formulas_change", impact: "SOCF", description: "Change in receivables", long: [] },
    ],
  },
  contPayables: {
    general: { description: "Payables", expand: true },
    core: {},
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_expenses",
        description: "Expenses",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_dpo",
        description: "DPO",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_payablesbf",
        description: "Payables b/f",
        type: "Default",
        bfLink: null,
        calcType: "B/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_payablescf",
            description: "Payables c/f",
          },
        ],
      },
      {
        id: "default_formulas_payablescf",
        description: "Payables c/f",
        type: "Default",
        bfLink: null,
        calcType: "C/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "last12MonthsOf",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_expenses",
            description: "Expenses",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "*",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_dpo",
            description: "DPO",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "/",
          },
          {
            id: nanoid(),
            linkType: "constant",
            link: "",
            description: "365",
          },
        ],
      },
      {
        id: "default_formulas_change",
        description: "Change in payables",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "SOCF",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_payablescf",
            description: "Payables c/f",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_payablesbf",
            description: "Payables b/f",
          },
        ],
      },
    ],
    outputs: [
      { id: "default_formulas_payablesbf", impact: "SOFP", description: "Payables b/f", long: [] },
      { id: "default_formulas_payablescf", impact: "SOFP", description: "Payables c/f", long: [] },
      { id: "default_formulas_change", impact: "SOCF", description: "Change in payables", long: [] },
    ],
  },
  contCash: {
    general: { description: "Cash", expand: true },
    core: {},
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_operating",
        description: "Operating",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_investing",
        description: "Investing",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_financing",
        description: "Financing",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_cashbf",
        description: "Cash b/f",
        type: "Default",
        bfLink: null,
        calcType: "B/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_cashcf",
            description: "Cash c/f",
          },
        ],
      },
      {
        id: "default_formulas_movement",
        description: "Movement",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "",
        params: [
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_operating",
            description: "Operating",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "+",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_investing",
            description: "Investing",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "+",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_financing",
            description: "Financing",
          },
        ],
      },
      {
        id: "default_formulas_cashcf",
        description: "Cash c/f",
        type: "Default",
        bfLink: null,
        calcType: "C/F",
        impact: "SOFP",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_cashbf",
            description: "Cash b/f",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "+",
          },
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_movement",
            description: "Movement",
          },
        ],
      },
    ],
    outputs: [
      { id: "default_formulas_cashbf", impact: "SOFP", description: "Cash b/f", long: [] },
      { id: "default_formulas_movement", impact: "", description: "Movement", long: [] },
      { id: "default_formulas_cashcf", impact: "SOFP", description: "Cash c/f", long: [] },
    ],
  },
  contSofp: {
    general: { description: "SOFP", expand: true },
    core: {},
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_assets",
        description: "Assets",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_equity",
        description: "Equity",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_liabilities",
        description: "Liabilities",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_check",
        description: "Check",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "",
        params: [
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_assets",
            description: "Assets",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_equity",
            description: "Equity",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_liabilities",
            description: "Liabilities",
          },
        ],
      },
    ],
    outputs: [{ id: "default_formulas_check", impact: "", description: "Check", long: [] }],
  },

  contBlank: {
    general: { description: "Blank", expand: true },
    core: {},
    priors: [],
    inputs: [],
    assignments: [],
    formulas: [],
    outputs: [],
  },
  contMarketing: {
    general: { description: "Marketing", expand: true },
    core: { platform: "Facebook", output: "Leads", leadsCalcType: "Easy" },
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_budget",
        description: "Budget",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_cpl",
        description: "CPL",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_leads",
        description: "Leads",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "",
        params: [
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_budget",
            description: "Budget",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "/",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_cpl",
            description: "CPL",
          },
        ],
      },
    ],
    outputs: [
      {
        id: "default_formulas_leads",
        description: "Leads",
        impact: "",
        long: [],
      },
    ],
  },
  contSales: {
    general: { description: "Sales", expand: true },
    core: { channel: "Retail" },
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_leads",
        description: "Leads",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_convRate",
        description: "Conv Rate",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_sales",
        description: "Sales",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "",
        params: [
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_leads",
            description: "Leads",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "*",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_convRate",
            description: "Conv Rate",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "/",
          },
          {
            id: nanoid(),
            linkType: "constant",
            link: "",
            description: "100",
          },
        ],
      },
    ],
    outputs: [
      {
        id: "default_formulas_sales",
        description: "Sales",
        impact: "",
        long: [],
      },
    ],
  },
  contRevenue: {
    general: { description: "Revenue", expand: true },
    core: { stream: "Recurring" },
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_sales",
        description: "Sales",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_price",
        description: "Price per unit",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_revenue",
        description: "Revenue",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "",
        params: [
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_sales",
            description: "Sales",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "*",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_price",
            description: "Price",
          },
        ],
      },
    ],
    outputs: [
      {
        id: "default_formulas_revenue",
        description: "Revenue",
        impact: "",
        long: [],
      },
    ],
  },
  contStaff: {
    general: { description: "Staff", expand: true },
    core: {},
    priors: [],
    inputs: [],
    assignments: [],
    formulas: [],
    individuals: [],
    outputs: [
      {
        id: "default_staff_staffCosts",
        description: "Staff costs",
        impact: "SOPL",
        bfLink: null,
        long: [],
      },
    ],
  },
  contDirectCosts: {
    general: { description: "Direct Costs", expand: true },
    priors: [],
    inputsLink: [],
    inputsSelf: [], // + children
    outputs: [
      {
        id: nanoid(),
        description: "Direct Costs Total",
        impact: "SOPL",
        long: [],
      },
    ], // level 1 + total
  },
  contIndirectCosts: {
    general: { description: "Indirect Costs", expand: true },
    priors: [],
    inputsLink: [],
    inputsSelf: [], // + children
    outputs: [
      {
        id: nanoid(),
        description: "Indirect Costs Total",
        impact: "SOPL",
        long: [],
      },
    ], // level 1 + total
  },
  contExpenses: {
    general: { description: "Expenses", expand: true },
    core: {},
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_directCosts",
        description: "Direct costs",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_indirectCosts",
        description: "Indirect costs",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_expenses",
        description: "Expenses",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "SOPL",
        params: [
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_directCosts",
            description: "Direct costs",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "+",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_indirectCosts",
            description: "Indirect costs",
          },
        ],
      },
    ],
    outputs: [
      {
        id: "default_formulas_expenses",
        description: "Expenses",
        impact: "SOPL",
        long: [],
      },
    ],
  },
  contProfit: {
    general: { description: "Profit / (Loss)", expand: true },
    core: {},
    priors: [],
    inputs: [],
    assignments: [
      {
        id: "default_assignments_revenue",
        description: "Revenue",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_expenses",
        description: "Expenses",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_intExp",
        description: "Interest expense",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
      {
        id: "default_assignments_taxRate",
        description: "Tax Rate",
        type: "Default",
        bfLink: null,
        linkCustom: "Custom",
        custom: { value: 0, min: 0, max: 0, change: 0, start: "" },
        long: [],
        longCustom: [],
      },
    ],
    formulas: [
      {
        id: "default_formulas_pbit",
        description: "PBIT",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "SOPL",
        params: [
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_revenue",
            description: "Sales",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_expenses",
            description: "Expenses",
          },
        ],
      },
      {
        id: "default_formulas_pbt",
        description: "PBT",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "SOPL",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_pbit",
            description: "PBIT",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_intExp",
            description: "Interest expense",
          },
        ],
      },
      {
        id: "default_formulas_tax",
        description: "Tax Expense",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "SOPL",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_pbt",
            description: "PBT",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "*",
          },
          {
            id: nanoid(),
            linkType: "linkAssign",
            link: "default_assignments_taxRate",
            description: "Tax Rate",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "/",
          },
          {
            id: nanoid(),
            linkType: "constant",
            link: "",
            description: "100",
          },
        ],
      },
      {
        id: "default_formulas_profit",
        description: "Profit / (Loss)",
        type: "Default",
        bfLink: null,
        calcType: "Basic",
        impact: "SOPL",
        params: [
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_pbt",
            description: "PBT",
          },
          {
            id: nanoid(),
            linkType: "operator",
            link: "",
            description: "-",
          },
          {
            id: nanoid(),
            linkType: "linkFormula",
            link: "default_formulas_tax",
            description: "Tax Expense",
          },
        ],
      },
    ],
    outputs: [
      {
        id: "default_formulas_profit",
        description: "Profit / (Loss)",
        impact: "SOPL",
        long: [],
      },
    ],
  },
};

export const useStore = create((set, get) => ({
  assumptions: {
    currency: "$",
    months: 24,
    startDate: "2024-1",
    periodEnd: 12,
  },
  nodes: [],
  edges: [],
  view: "view0",
  contextId: "",
  allEdgeVisible: true,

  // AUTH

  logout() {
    // console.log("logout");

    const initialState = {
      assumptions: {
        currency: "$",
        months: 24,
        startDate: "2024-1",
        periodEnd: 12,
      },
      nodes: [],
      edges: [],
      view: "view0",
      contextId: "",
      allEdgeVisible: true,
    };
    set(initialState);
  },

  // UPDATE REPORTS

  updateAll() {
    // console.log("updateAll");

    updatePriorYears();
    updateFlow();
    updateStatements();
    updateStatementsAnnual();
    updateActual("others");
    updateDifferences();
    updateValuation();
    updateDashboard();
    updateReport();
    set({ allEdgeVisible: get().edges.every((edge) => edge.visible !== false) });
  },

  updateAssumptions(key, value) {
    // console.log("updateAssumptions");

    // months - check number valid
    if (key === "months") {
      if (isNaN(value) || !isFinite(value) || value <= 0) {
        value = 1;
      }
      if (value > 120) {
        value = 120;
      }
    }

    // startDate - update all nodes startDate as well
    if (key === "startDate") {
      const oldValue = get().assumptions[key];

      const updateExpenses = (input, oldValue, newValue) => {
        if (input.children.length === 0) {
          if (input.custom.start === oldValue) {
            const updatedInput = { ...input, custom: { ...input.custom, start: newValue } };
            return updatedInput;
          }
          return input;
        } else {
          const updatedChildren = input.children.map((child) => updateExpenses(child, oldValue, newValue));
          return { ...input, children: updatedChildren };
        }
      };
      const updateNormal = (assignment, oldValue, newValue) => {
        if (assignment.custom.start === oldValue) {
          const updatedAssignment = { ...assignment, custom: { ...assignment.custom, start: newValue } };
          return updatedAssignment;
        }
        return assignment;
      };

      const updateNode = (node, oldValue, newValue) => {
        if (node.container === "directCosts" || node.container === "indirectCosts") {
          const inputsSelf = node.data.inputsSelf.map((input) => updateExpenses(input, oldValue, newValue));
          node = { ...node, data: { ...node.data, inputsSelf: inputsSelf } };
        } else {
          if (node.data.assignments) {
            const assignments = node.data.assignments.map((assignment) => updateNormal(assignment, oldValue, newValue));
            node = { ...node, data: { ...node.data, assignments: assignments } };
          }
        }
        return node;
      };

      const updatedNodes = get().nodes.map((node) => updateNode(node, oldValue, value));

      set({ nodes: updatedNodes });
    }

    set({ assumptions: { ...get().assumptions, [key]: value } });

    get().updateAll();
  },

  // NODES & EDGES

  createNode(type, view, container, contextId, x, y) {
    // console.log("createNode");

    const onlyOnce = ["profit", "cash", "sofp"];
    if (onlyOnce.includes(container)) {
      const filteredNodes = get().nodes.filter((node) => node.contextId === contextId);
      const exists = filteredNodes.some((node) => node.container === container);
      if (exists) {
        return;
      }
    }

    const id = nanoid();
    const position = { x: x ? x : 0, y: y ? y : 0 };
    const data = cloneDeep(dataItems[type]);

    const newNode = {
      id: id,
      position: position,
      data: data,
      view: view, // view0 view1 ...
      type: type, // contMarketing contSales ...
      container: container, // marketing sales ...
      contextId: contextId,
    };
    set({ nodes: [...get().nodes, newNode] });

    if (view === "view0" || container === "sofp" || container === "profit" || container === "cash") {
      get().updateAll();
    } else {
      updateFlow();
    }
  },

  updateNode(id, data) {
    // console.log("updateNode");

    get().nodes.forEach((node, index) => {
      if (node.id === id) {
        const updatedNodes = [...get().nodes];
        updatedNodes[index] = { ...node, data: { ...node.data, ...data } };

        set({ nodes: updatedNodes });
      }
    });

    get().updateAll();
  },

  updateAllNodes(nodes) {
    // console.log("updateAllNodes");

    set({ nodes: nodes });

    get().updateAll();
  },

  onNodesChange(changes) {
    // console.log("onNodesChange");

    // if delete context, delete nodes and edges inside
    changes.forEach((change) => {
      if (change.type === "remove") {
        const deletedNode = get().nodes.find((node) => node.id === change.id);
        if (deletedNode && deletedNode.view === "view0") {
          const updatedNodes = get().nodes.filter((node) => node.contextId !== deletedNode.id);
          const updatedEdges = get().edges.filter((edge) => edge.contextId !== deletedNode.id);
          set({ nodes: updatedNodes });
          set({ edges: updatedEdges });
        }
      }
    });

    const oldNodes = get().nodes;
    set({ nodes: applyNodeChanges(changes, oldNodes) });

    get().updateAll();
  },

  onEdgesChange(changes) {
    // console.log("onEdgesChange");

    const oldEdges = get().edges;
    set({ edges: applyEdgeChanges(changes, oldEdges) });

    get().updateAll();
  },

  addEdge(data, contextId) {
    // console.log("addEdge");

    // check for existing edge to prevent duplication
    const { source, target, sourceHandle, targetHandle } = data;
    const existingEdge = get().edges.find(
      (edge) =>
        edge.source === source &&
        edge.target === target &&
        edge.sourceHandle === sourceHandle &&
        edge.targetHandle === targetHandle
    );
    if (existingEdge) {
      return;
    }

    const newEdge = {
      id: nanoid(6),
      contextId: contextId,
      source: source,
      target: target,
      sourceHandle: sourceHandle,
      targetHandle: targetHandle,
      visible: true,
    };

    const updatedEdges = [...get().edges, newEdge];
    const nodes = get().nodes;

    // check for cycle in topological sort
    const result = topologicalSortNodes(nodes, updatedEdges);
    if (!result.success) {
      return;
    }

    set({ edges: [...get().edges, newEdge] });

    get().updateAll();
  },

  setEdges(newEdges) {
    // console.log("setEdges");

    set({ edges: newEdges });
  },

  deleteAll(view, contextId) {
    // console.log("deleteAll");

    let nodes = [];
    let edges = [];

    if (view === "view1") {
      nodes = get().nodes.filter((node) => node.contextId !== contextId);
      edges = get().edges.filter((edge) => edge.contextId !== contextId);
    }

    set({ nodes: nodes });
    set({ edges: edges });

    get().updateAll();
  },

  // SAMPLE

  generateFull() {
    // console.log("sampleGenerate");

    set({ nodes: sampleData.nodes });
    set({ edges: sampleData.edges });

    get().updateAll();
  },

  generateEmptySaas(contextId) {
    // console.log("saasGenerate");

    const originalNodes = get().nodes.filter((node) => node.contextId !== contextId);
    const originalEdges = get().edges.filter((edge) => edge.contextId !== contextId);

    const saasDataCopy = cloneDeep(saasData);

    const idMapping = {};
    const newNodes = saasDataCopy.nodes.map((node) => {
      const newId = nanoid();
      idMapping[node.id] = newId;
      return { ...node, id: newId, contextId: contextId };
    });
    const newEdges = saasDataCopy.edges.map((edge) => {
      const newId = nanoid();
      return {
        ...edge,
        id: newId,
        contextId: contextId,
        source: idMapping[edge.source] || edge.source,
        target: idMapping[edge.target] || edge.target,
      };
    });

    const nodes = [...originalNodes, ...newNodes];
    const edges = [...originalEdges, ...newEdges];
    set({ nodes: nodes });
    set({ edges: edges });

    get().updateAll();
  },

  // OTHERS

  setView(view, contextId) {
    set({ view: view });
    set({ contextId: contextId });
  },

  expand() {
    const nodes = get().nodes;
    const updatedNodes = nodes.map((node) => {
      const container = { ...node };
      if (container.data && container.data.general) {
        container.data.general = { ...container.data.general, expand: true };
      }
      return container;
    });

    set({ nodes: updatedNodes });
  },

  collapse() {
    const nodes = get().nodes;
    const updatedNodes = nodes.map((node) => {
      const container = { ...node };
      if (container.data && container.data.general) {
        container.data.general = { ...container.data.general, expand: false };
      }
      return container;
    });

    set({ nodes: updatedNodes });
  },

  setEdgeVisible() {
    const edges = get().edges;
    const allEdgeVisible = get().allEdgeVisible;

    if (allEdgeVisible) {
      const updatedEdges = edges.map((edge) => ({
        ...edge,
        visible: false,
      }));
      set({ edges: updatedEdges, allEdgeVisible: false });
    } else {
      const updatedEdges = edges.map((edge) => ({
        ...edge,
        visible: true,
      }));
      set({ edges: updatedEdges, allEdgeVisible: true });
    }
  },

  setSelfEdgeVisible(id) {
    const updatedEdges = get().edges.map((edge) => {
      if (edge.source === id || edge.target === id) {
        return { ...edge, visible: true };
      }
      return edge;
    });

    set({ edges: updatedEdges });
    set({ allEdgeVisible: get().edges.every((edge) => edge.visible !== false) });
  },

  getNode(id) {
    const node = get().nodes.find((node) => node.id === id);
    if (node) {
      return node;
    }
    return null;
  },

  getContainer(id) {
    const node = get().nodes.find((node) => node.id === id);
    if (node) {
      return node.container;
    }
    return null;
  },
}));
