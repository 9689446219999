import styles from "../styles.module.css";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

import { useStore } from "../../store";
import { usePriorYearsStore } from "../../../../stores/usePriorYearsStore";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudDownloadRoundedIcon from "@mui/icons-material/CloudDownloadRounded";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";

const HeaderTemplate = ({ contextId }) => {
  const nodes = useStore.getState().nodes;
  const assumptions = useStore.getState().assumptions;
  const updateAllNodes = useStore.getState().updateAllNodes;
  const priorYears = usePriorYearsStore.getState().priorYears;
  const priorLength = priorYears.find((item) => item.contextId === contextId).priorLength;
  const { months } = assumptions;

  const downloadTemplate = async () => {
    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Create worksheets
    const normalWorksheet = workbook.addWorksheet("Normal");
    const staffWorksheet = workbook.addWorksheet("Staff");
    const expensesWorksheet = workbook.addWorksheet("Expenses");
    const capexWorksheet = workbook.addWorksheet("Capex");
    const loanWorksheet = workbook.addWorksheet("Loan");

    // Define basic columns for all worksheets
    const nodeColumns = [
      { header: "contextId", key: "contextId", width: 20 },
      { header: "nodeId", key: "nodeId", width: 20 },
      { header: "nodeContainer", key: "nodeContainer", width: 20 },
    ];
    const customLongColumns = Array.from({ length: months }, (_, index) => ({
      header: `month_${index + 1}`,
      key: `month_${index + 1}`,
      width: 15,
    }));

    normalWorksheet.columns = [
      ...nodeColumns,
      { header: "id", key: "id", width: 20 },
      { header: "description", key: "description", width: 20 },
      { header: "start", key: "start", width: 15 },
      { header: "value", key: "value", width: 15 },
      { header: "min", key: "min", width: 15 },
      { header: "max", key: "max", width: 15 },
      { header: "change", key: "change", width: 15 },
      ...customLongColumns,
    ];
    staffWorksheet.columns = [
      ...nodeColumns,
      { header: "id", key: "id", width: 20 },
      { header: "description", key: "description", width: 20 },
      { header: "start", key: "start", width: 15 },
      { header: "param_id", key: "param_id", width: 20 },
      { header: "param_description", key: "param_description", width: 20 },
      { header: "value", key: "value", width: 15 },
      { header: "min", key: "min", width: 15 },
      { header: "max", key: "max", width: 15 },
      { header: "change", key: "change", width: 15 },
      ...customLongColumns,
    ];
    expensesWorksheet.columns = [
      ...nodeColumns,
      { header: "id", key: "id", width: 20 },
      { header: "description", key: "description", width: 20 },
      { header: "start", key: "start", width: 15 },
      { header: "value", key: "value", width: 15 },
      { header: "min", key: "min", width: 15 },
      { header: "max", key: "max", width: 15 },
      { header: "change", key: "change", width: 15 },
      ...customLongColumns,
    ];
    capexWorksheet.columns = [
      ...nodeColumns,
      { header: "id", key: "id", width: 20 },
      { header: "description", key: "description", width: 20 },
      { header: "type", key: "type", width: 20 },
      { header: "start", key: "start", width: 15 },
      { header: "quantitySingle", key: "quantitySingle", width: 15 },
      { header: "value", key: "value", width: 15 },
      { header: "usefulLife", key: "usefulLife", width: 15 },
      { header: "residualValue", key: "residualValue", width: 15 },
      ...customLongColumns,
    ];
    loanWorksheet.columns = [
      ...nodeColumns,
      { header: "id", key: "id", width: 20 },
      { header: "description", key: "description", width: 20 },
      { header: "start", key: "start", width: 15 },
      { header: "amount", key: "amount", width: 15 },
      { header: "eir", key: "eir", width: 15 },
      { header: "repayment", key: "repayment", width: 15 },
    ];

    // Add rows to respective worksheets
    const nodesToUse = nodes.filter((node) => node.contextId === contextId);
    nodesToUse.forEach((node) => {
      if (node.view === "view0") {
        return;
      }

      if (node.container === "expenses") {
        // do nothing
      } else if (node.container === "staff") {
        node.data.assignments.forEach((assignment) => {
          assignment.params.forEach((param) => {
            const row = {
              contextId: node.contextId,
              nodeId: node.id,
              nodeContainer: node.container,
              id: assignment.id,
              description: assignment.description,
              start: assignment.start,
              param_id: param.id,
              param_description: param.description,
              value: param.custom.value,
              min: param.custom.min,
              max: param.custom.max,
              change: param.custom.change,
            };
            param.longCustom.forEach((value, index) => {
              if (index >= priorLength) {
                row[`month_${index - priorLength + 1}`] = value;
              }
            });
            staffWorksheet.addRow(row);
          });
        });
      } else if (node.container === "directCosts" || node.container === "indirectCosts") {
        node.data.inputsSelf.forEach((input) => {
          const row = {
            contextId: node.contextId,
            nodeId: node.id,
            nodeContainer: node.container,
            id: input.id,
            description: input.description,
            start: input.custom.start,
            value: input.custom.value,
            min: input.custom.min,
            max: input.custom.max,
            change: input.custom.change,
          };
          input.longCustom.forEach((value, index) => {
            if (index >= priorLength) {
              row[`month_${index - priorLength + 1}`] = value;
            }
          });
          expensesWorksheet.addRow(row);
        });
      } else if (node.container === "capex") {
        node.data.assignments.forEach((assignment) => {
          const row = {
            contextId: node.contextId,
            nodeId: node.id,
            nodeContainer: node.container,
            id: assignment.id,
            description: assignment.description,
            type: assignment.type,
            start: assignment.start,
            quantitySingle: assignment.quantitySingle,
            value: assignment.value,
            usefulLife: assignment.usefulLife,
            residualValue: assignment.residualValue,
          };
          assignment.quantityMultiple.forEach((value, index) => {
            if (index >= priorLength) {
              row[`month_${index - priorLength + 1}`] = value;
            }
          });
          capexWorksheet.addRow(row);
        });
      } else if (node.container === "loan") {
        node.data.assignments.forEach((assignment) => {
          const row = {
            contextId: node.contextId,
            nodeId: node.id,
            nodeContainer: node.container,
            id: assignment.id,
            description: assignment.description,
            start: assignment.start,
            amount: assignment.amount,
            eir: assignment.eir,
            repayment: assignment.repayment,
          };
          loanWorksheet.addRow(row);
        });
      } else {
        node.data.assignments.forEach((assignment) => {
          if (assignment.linkCustom === "Custom") {
            const row = {
              contextId: node.contextId,
              nodeId: node.id,
              nodeContainer: node.container,
              id: assignment.id,
              description: assignment.description,
              start: assignment.custom.start,
              value: assignment.custom.value,
              min: assignment.custom.min,
              max: assignment.custom.max,
              change: assignment.custom.change,
            };
            assignment.longCustom.forEach((value, index) => {
              if (index >= priorLength) {
                row[`month_${index - priorLength + 1}`] = value;
              }
            });
            normalWorksheet.addRow(row);
          }
        });
      }
    });

    // Create a buffer and save the file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, "flow.xlsx");
  };

  const uploadTemplate = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (event) => {
      const buffer = event.target.result;
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(buffer);

      const normalWorksheet = workbook.getWorksheet("Normal");
      const staffWorksheet = workbook.getWorksheet("Staff");
      const expensesWorksheet = workbook.getWorksheet("Expenses");
      const capexWorksheet = workbook.getWorksheet("Capex");
      const loanWorksheet = workbook.getWorksheet("Loan");

      const deepCopyNodes = deepCopy(nodes);
      const unaffectedNodes = deepCopyNodes.filter((node) => node.contextId !== contextId);
      const updatedNodes = deepCopyNodes.filter((node) => node.contextId === contextId);

      if (normalWorksheet) {
        const normalWorksheetData = [];
        let isFirstRow = true;

        normalWorksheet.eachRow((row, rowNumber) => {
          if (isFirstRow) {
            isFirstRow = false;
            return;
          }

          const rowData = {};
          const longCustom = Array(months).fill(null);

          row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            const columnNames = [
              "contextId",
              "nodeId",
              "nodeContainer",
              "id",
              "description",
              "start",
              "value",
              "min",
              "max",
              "change",
              ...Array.from({ length: months }, (_, index) => `month_${index + 1}`),
            ];

            if (colNumber > 10 && colNumber <= 10 + months) {
              // longCustom
              longCustom[colNumber - 11] = cell.value;
            } else {
              // others
              rowData[columnNames[colNumber - 1]] = cell.value;
            }
          });
          rowData.longCustom = longCustom;

          normalWorksheetData.push(rowData);
        });

        updatedNodes.forEach((node) => {
          const dataMatches = normalWorksheetData.filter((item) => item.nodeId === node.id);
          if (dataMatches.length > 0) {
            dataMatches.forEach((data) => {
              const assignmentIndex = node.data.assignments.findIndex((assignment) => assignment.id === data.id);
              if (assignmentIndex !== -1) {
                const updatedAssignments = { ...node.data.assignments[assignmentIndex] };
                updatedAssignments.custom.start = data.start;
                updatedAssignments.custom.value = data.value;
                updatedAssignments.custom.min = data.min;
                updatedAssignments.custom.max = data.max;
                updatedAssignments.custom.change = data.change;
                updatedAssignments.longCustom = [...Array(priorLength).fill(0), ...data.longCustom];

                node.data.assignments[assignmentIndex] = updatedAssignments;
              }
            });
          }
        });
      }

      if (staffWorksheet) {
        const staffWorksheetData = [];
        let isFirstRow = true;

        staffWorksheet.eachRow((row, rowNumber) => {
          if (isFirstRow) {
            isFirstRow = false;
            return;
          }

          const rowData = {};
          const longCustom = Array(months).fill(null);

          row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            const columnNames = [
              "contextId",
              "nodeId",
              "nodeContainer",
              "id",
              "description",
              "start",
              "param_id",
              "param_description",
              "value",
              "min",
              "max",
              "change",
              ...Array.from({ length: months }, (_, index) => `month_${index + 1}`),
            ];

            if (colNumber > 12 && colNumber <= 12 + months) {
              // longCustom
              longCustom[colNumber - 13] = cell.value;
            } else {
              // others
              rowData[columnNames[colNumber - 1]] = cell.value;
            }
          });
          rowData.longCustom = longCustom;

          staffWorksheetData.push(rowData);
        });

        updatedNodes.forEach((node) => {
          const dataMatches = staffWorksheetData.filter((item) => item.nodeId === node.id);
          if (dataMatches.length > 0) {
            dataMatches.forEach((data) => {
              const assignmentIndex = node.data.assignments.findIndex((assignment) => assignment.id === data.id);
              const paramIndex = node.data.assignments[assignmentIndex].params.findIndex(
                (param) => param.id === data.param_id
              );
              if (assignmentIndex !== -1 && paramIndex !== -1) {
                const updated = { ...node.data.assignments[assignmentIndex] };
                updated.start = data.start;
                updated.params[paramIndex].custom.value = data.value;
                updated.params[paramIndex].custom.min = data.min;
                updated.params[paramIndex].custom.max = data.max;
                updated.params[paramIndex].custom.change = data.change;
                updated.params[paramIndex].longCustom = [...Array(priorLength).fill(0), ...data.longCustom];

                node.data.assignments[assignmentIndex] = updated;
              }
            });
          }
        });
      }

      if (expensesWorksheet) {
        const expensesWorksheetData = [];
        let isFirstRow = true;

        expensesWorksheet.eachRow((row, rowNumber) => {
          if (isFirstRow) {
            isFirstRow = false;
            return;
          }

          const rowData = {};
          const longCustom = Array(months).fill(null);

          row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            const columnNames = [
              "contextId",
              "nodeId",
              "nodeContainer",
              "id",
              "description",
              "start",
              "value",
              "min",
              "max",
              "change",
              ...Array.from({ length: months }, (_, index) => `month_${index + 1}`),
            ];

            if (colNumber > 10 && colNumber <= 10 + months) {
              // longCustom
              longCustom[colNumber - 11] = cell.value;
            } else {
              // others
              rowData[columnNames[colNumber - 1]] = cell.value;
            }
          });
          rowData.longCustom = longCustom;

          expensesWorksheetData.push(rowData);
        });

        updatedNodes.forEach((node) => {
          const dataMatches = expensesWorksheetData.filter((item) => item.nodeId === node.id);
          if (dataMatches.length > 0) {
            dataMatches.forEach((data) => {
              const inputIndex = node.data.inputsSelf.findIndex((input) => input.id === data.id);
              if (inputIndex !== -1) {
                const updatedInputsSelf = { ...node.data.inputsSelf[inputIndex] };
                updatedInputsSelf.custom.start = data.start;
                updatedInputsSelf.custom.value = data.value;
                updatedInputsSelf.custom.min = data.min;
                updatedInputsSelf.custom.max = data.max;
                updatedInputsSelf.custom.change = data.change;
                updatedInputsSelf.longCustom = [...Array(priorLength).fill(0), ...data.longCustom];

                node.data.inputsSelf[inputIndex] = updatedInputsSelf;
              }
            });
          }
        });
      }

      if (capexWorksheet) {
        const capexWorksheetData = [];
        let isFirstRow = true;

        capexWorksheet.eachRow((row, rowNumber) => {
          if (isFirstRow) {
            isFirstRow = false;
            return;
          }

          const rowData = {};
          const quantityMultiple = Array(months).fill(null);

          row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            const columnNames = [
              "contextId",
              "nodeId",
              "nodeContainer",
              "id",
              "description",
              "type",
              "start",
              "quantitySingle",
              "value",
              "usefulLife",
              "residualValue",
              ...Array.from({ length: months }, (_, index) => `month_${index + 1}`),
            ];

            if (colNumber > 11 && colNumber <= 11 + months) {
              // longCustom
              quantityMultiple[colNumber - 12] = cell.value;
            } else {
              // others
              rowData[columnNames[colNumber - 1]] = cell.value;
            }
          });

          rowData.quantityMultiple = quantityMultiple;

          capexWorksheetData.push(rowData);
        });

        updatedNodes.forEach((node) => {
          const dataMatches = capexWorksheetData.filter((item) => item.nodeId === node.id);
          if (dataMatches.length > 0) {
            dataMatches.forEach((data) => {
              const assignmentIndex = node.data.assignments.findIndex((assignment) => assignment.id === data.id);
              if (assignmentIndex !== -1) {
                const updated = { ...node.data.assignments[assignmentIndex] };
                updated.type = data.type;
                updated.start = data.start;
                updated.quantitySingle = data.quantitySingle;
                updated.value = data.value;
                updated.usefulLife = data.usefulLife;
                updated.residualValue = data.residualValue;
                updated.quantityMultiple = [...Array(priorLength).fill(0), ...data.quantityMultiple];

                node.data.assignments[assignmentIndex] = updated;
              }
            });
          }
        });
      }

      if (loanWorksheet) {
        const loanWorksheetData = [];
        let isFirstRow = true;

        loanWorksheet.eachRow((row, rowNumber) => {
          if (isFirstRow) {
            isFirstRow = false;
            return;
          }

          const rowData = {};

          row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            const columnNames = [
              "contextId",
              "nodeId",
              "nodeContainer",
              "id",
              "description",
              "start",
              "amount",
              "eir",
              "repayment",
            ];
            rowData[columnNames[colNumber - 1]] = cell.value;
          });

          loanWorksheetData.push(rowData);
        });

        updatedNodes.forEach((node) => {
          const dataMatches = loanWorksheetData.filter((item) => item.nodeId === node.id);
          if (dataMatches.length > 0) {
            dataMatches.forEach((data) => {
              const assignmentIndex = node.data.assignments.findIndex((assignment) => assignment.id === data.id);
              if (assignmentIndex !== -1) {
                const updatedAssignments = { ...node.data.assignments[assignmentIndex] };
                updatedAssignments.start = data.start;
                updatedAssignments.amount = data.amount;
                updatedAssignments.eir = data.eir;
                updatedAssignments.repayment = data.repayment;

                node.data.assignments[assignmentIndex] = updatedAssignments;
              }
            });
          }
        });
      }

      updateAllNodes([...unaffectedNodes, ...updatedNodes]);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div className={styles.header_info}>
      <FileDownload fileDownload={downloadTemplate} />
      <FileUpload fileUpload={uploadTemplate} />
    </div>
  );
};

export default HeaderTemplate;

const deepCopy = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  const newObj = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    newObj[key] = deepCopy(obj[key]);
  }

  return newObj;
};

const FileDownload = ({ fileDownload }) => {
  return (
    <Button
      role={undefined}
      variant="outlined"
      tabIndex={-1}
      startIcon={<CloudDownloadRoundedIcon />}
      sx={{ textTransform: "none" }}
      onClick={fileDownload}
    >
      Download Template
    </Button>
  );
};

const FileUpload = ({ fileUpload }) => {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <Button
      component="label"
      role={undefined}
      variant="outlined"
      tabIndex={-1}
      startIcon={<CloudUploadRoundedIcon />}
      sx={{ textTransform: "none" }}
    >
      Upload Template
      <VisuallyHiddenInput type="file" onChange={fileUpload} />
    </Button>
  );
};
