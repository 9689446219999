import styles from "../../styles.module.css";

const Actual = () => (
  <svg className={styles.nav_svg} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.25 2A.75.75 0 0 1 2 1.25h20a.75.75 0 0 1 0 1.5h-1.25v7.808c0 1.483 0 2.676-.133 3.614c-.138.975-.434 1.784-1.105 2.42c-.666.632-1.503.904-2.512 1.033c-.981.125-2.233.125-3.804.125h-.446v2.787l1.585.792a.75.75 0 1 1-.67 1.342L12 21.839l-1.665.832a.75.75 0 1 1-.67-1.342l1.585-.793V17.75h-.446c-1.571 0-2.823 0-3.804-.125c-1.01-.129-1.846-.401-2.512-1.033c-.671-.636-.967-1.445-1.105-2.42c-.133-.938-.133-2.131-.133-3.614V2.75H2A.75.75 0 0 1 1.25 2m3.5.75v7.75c0 1.554.002 2.64.118 3.46c.113.795.32 1.228.652 1.544c.338.32.812.524 1.67.633c.875.112 2.03.113 3.667.113h2.286c1.636 0 2.792-.001 3.667-.113c.858-.11 1.332-.313 1.67-.633c.332-.316.54-.749.652-1.543c.116-.82.118-1.907.118-3.461V2.75zm10.78 5.22a.75.75 0 0 1 0 1.06l-1.293 1.293l-.024.025a4.868 4.868 0 0 1-.452.416a1.24 1.24 0 0 1-.761.279a1.24 1.24 0 0 1-.761-.279a4.868 4.868 0 0 1-.452-.416l-.024-.025l-.586-.586L11 9.562l-.177.175L9.53 11.03a.75.75 0 1 1-1.06-1.06l1.293-1.293l.024-.025c.145-.145.302-.302.452-.416A1.24 1.24 0 0 1 11 7.957c.334 0 .585.145.761.279c.15.114.307.271.452.416l.024.025l.586.586l.177.175l.177-.175L14.47 7.97a.75.75 0 0 1 1.06 0"
      clipRule="evenodd"
    />
  </svg>
);

export default Actual;
