import { useStore } from "../store";
import { usePriorYearsStore } from "../../../stores/usePriorYearsStore";

export const updatePriorYears = () => {
  const nodes = useStore.getState().nodes;
  const priorYears = usePriorYearsStore.getState().priorYears;

  const contextIds = nodes.filter((node) => node.contextId === "").map((node) => node.id);

  // remove deleted context
  for (let i = priorYears.length - 1; i >= 0; i--) {
    if (!contextIds.includes(priorYears[i].contextId)) {
      priorYears.splice(i, 1);
    }
  }

  // add new context
  contextIds.forEach((contextId) => {
    const priorYearIndex = priorYears.findIndex((priorYear) => priorYear.contextId === contextId);
    if (priorYearIndex === -1) {
      priorYears.push({
        contextId: contextId,
        priorLength: 3,
        headers: [
          ["Period ending", "2021-12", "2022-12", "2023-12", " "],
          ["Number of months", 12, 12, 12, " "],
          ["Description", "", "", "", "Link to container"],
        ],
        rows: [
          ["", 0, 0, 0, "N/A"],
          ["", 0, 0, 0, "N/A"],
          ["", 0, 0, 0, "N/A"],
        ],
      });
    }
  });
};
