import { create } from "zustand";

export const useReportStore = create((set) => ({
  report: [],

  logout() {
    set({ report: [] });
  },

  setReport(data) {
    set({ report: data });
  },
}));
