import DashboardCSS from "../../styles/pages/Dashboard.module.css";
import StatementsCSS from "../../styles/pages/Statements.module.css";
import { LineGraph } from "./LineGraph";
import { formatValue2Dp } from "./utils/Format";

const GridItem = ({ title, xData, yData1, yData2, tableItems }) => {
  return (
    <div className={DashboardCSS.grid_item}>
      <div className={DashboardCSS.graph}>
        <LineGraph title={title} xLabel="Months" xData={xData} yLabel="Amount" yData1={yData1} yData2={yData2} />
      </div>
      <div className={StatementsCSS.tableContainer}>
        <table className={StatementsCSS.table}>
          <thead>
            <tr>
              <th>Month</th>
              {xData.map((month, index) => (
                <th key={index}>{month}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Projection</td>
              {yData1.map((value, index) => (
                <td key={index} className={StatementsCSS.number}>
                  {formatValue2Dp(value)}
                </td>
              ))}
            </tr>
            <tr>
              <td>Actual</td>
              {yData2.map((value, index) => (
                <td key={index} className={StatementsCSS.number}>
                  {formatValue2Dp(value)}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GridItem;
