import styles from "./styles.module.css";
import { memo } from "react";
import { useStore } from "../store";
import { selector } from "../constants";
import { Panel } from "@xyflow/react";

const View0Panel = ({ view, contextId, onDragStart }) => {
  const store = useStore(selector(view, contextId));

  return (
    <Panel className={styles.panel}>
      <p>Context</p>
      <button
        className={styles.general}
        onClick={() => store.addContContext()}
        onDragStart={(event) => onDragStart(event, "addContContext")}
        draggable
      >
        <span></span>Context
      </button>
    </Panel>
  );
};

export default memo(View0Panel);
