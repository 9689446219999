import FlowCSS from "../../../../styles/pages/Flow.module.css";
import { useStore } from "../../store";
import { usePriorYearsStore } from "../../../../stores/usePriorYearsStore";
import SideCircle from "../../components/SideCircle";
import { DisplayOutputs } from "../utils/NodeIO";
import { ButtonShow } from "../utils/Button";
import { selector } from "../../contextNodes/ContContextNode";

export default function ContLoanNode({ id, data }) {
  const {
    // setExpand,
    setVisible,
    node,
    container,
  } = useStore(selector(id, data));

  const getPriorLength = usePriorYearsStore.getState().getPriorLength;
  const priorLength = getPriorLength(node);

  return (
    <div className={FlowCSS.node}>
      <SideCircle container={container} />

      <div>
        <div className={FlowCSS.container_header}>
          <h2>{data.general.description}</h2>
          <ButtonShow onClick={setVisible} />
          {/* <button onClick={setExpand}>{data.general.expand ? "🔼" : "🔽"}</button> */}
        </div>

        {/* <div>
          <span>Id</span>
          <span>{id}</span>
        </div> */}

        <DisplayOutputs outputs={data.outputs} priorLength={priorLength} />
      </div>
    </div>
  );
}
