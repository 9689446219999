import styles from "./styles.module.css";
import { Modal, Box, Button } from "@mui/material";

import { InputNumberSimple } from "./InputNumber";
import { MonthPickerSimple } from "./MonthPicker";
import { SelectCustom } from "./Select";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30rem",
  bgcolor: "white",
  border: "2px solid black",
  boxShadow: 24,
  p: "1rem",
};

const currencyChoices = [
  { label: "$", value: "$" },
  { label: "€", value: "€" },
  { label: "£", value: "£" },
  { label: "¥", value: "¥" },
];

const monthsChoices = [
  { label: "March", value: 3 },
  { label: "June", value: 6 },
  { label: "September", value: 9 },
  { label: "December", value: 12 },
];

export const ModalEditAssumptions = ({ open, handleClose, assumptions, setAssumptions }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <h1 className={styles.title}>Edit Assumptions</h1>

        <>
          <SelectCustom
            item={assumptions}
            ques="Currency"
            keyQues="currency"
            onChange={(e) => {
              setAssumptions("currency", e.target.value);
            }}
            choices={currencyChoices}
          />
          <InputNumberSimple item={assumptions} ques="Months" keyQues="months" setOutputs={setAssumptions} />
          <MonthPickerSimple item={assumptions} ques="Start date" keyQues="startDate" setOutputs={setAssumptions} />
          <SelectCustom
            item={assumptions}
            ques="Period end"
            keyQues="periodEnd"
            onChange={(e) => {
              setAssumptions("periodEnd", e.target.value);
            }}
            choices={monthsChoices}
          />
        </>

        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            textTransform: "none",
            fontFamily: "Poppins",
          }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};
