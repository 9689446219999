import RightBarCSS from "../../styles/pages/RightBar.module.css";
import { memo } from "react";
import { useStore } from "./store";

import ContContextDetail from "./contextNodes/ContContextDetail";

import ContBlankDetail from "./containerNodes/SOPL/ContBlankDetail";
import ContMarketingDetail from "./containerNodes/SOPL/ContMarketingDetail";
import ContSalesDetail from "./containerNodes/SOPL/ContSalesDetail";
import ContRevenueDetail from "./containerNodes/SOPL/ContRevenueDetail";
import ContStaffDetail from "./containerNodes/SOPL/ContStaffDetail";
import ContDirectCostsDetail from "./containerNodes/SOPL/ContDirectCostsDetail";
import ContIndirectCostsDetail from "./containerNodes/SOPL/ContIndirectCostsDetail";
import ContExpensesDetail from "./containerNodes/SOPL/ContExpensesDetail";
import ContProfitDetail from "./containerNodes/SOPL/ContProfitDetail";
import ContCapexDetail from "./containerNodes/SOFP/ContCapexDetail";
import ContLoanDetail from "./containerNodes/SOFP/ContLoanDetail";
import ContReceivablesDetail from "./containerNodes/SOFP/ContReceivablesDetail";
import ContPayablesDetail from "./containerNodes/SOFP/ContPayablesDetail";
import ContShareCapDetail from "./containerNodes/SOFP/ContShareCapDetail";
import ContRetainedDetail from "./containerNodes/SOFP/ContRetainedDetail";
import ContCashDetail from "./containerNodes/SOFP/ContCashDetail";
import ContSofpDetail from "./containerNodes/SOFP/ContSofpDetail";

const RightBar = ({ id }) => {
  const initialNodes = useStore((state) => state.nodes);
  const selectedNode = initialNodes.find((node) => node.id === id);
  const show = selectedNode ? true : false;

  return (
    <div className={`${RightBarCSS.rightBar} ${show ? RightBarCSS.show : ""}`}>
      {selectedNode && selectedNode.type === "contContext" && <ContContextDetail node={selectedNode} />}

      {selectedNode && selectedNode.type === "contBlank" && <ContBlankDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contMarketing" && <ContMarketingDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contSales" && <ContSalesDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contRevenue" && <ContRevenueDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contStaff" && <ContStaffDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contDirectCosts" && <ContDirectCostsDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contIndirectCosts" && <ContIndirectCostsDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contExpenses" && <ContExpensesDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contProfit" && <ContProfitDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contCapex" && <ContCapexDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contLoan" && <ContLoanDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contReceivables" && <ContReceivablesDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contPayables" && <ContPayablesDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contShareCap" && <ContShareCapDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contRetained" && <ContRetainedDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contCash" && <ContCashDetail node={selectedNode} />}
      {selectedNode && selectedNode.type === "contSofp" && <ContSofpDetail node={selectedNode} />}
    </div>
  );
};

export default memo(RightBar);
