import StatementsCSS from "../../styles/pages/Statements.module.css";
import { useRef, createRef } from "react";
import { useReportStore } from "../../stores/useReportStore";
import { useGeneralStore } from "../../stores/useGeneralStore";
// import { useSaveContext } from "../../saveAndRestore/useSaveContext";

import { saveAs } from "file-saver";
import { TextRun, Paragraph, Table, TableRow, TableCell, patchDocument, PatchType, ImageRun } from "docx";
import Graph from "./Graph";
import { getColumns, getColumns2 } from "./../valuation/reactgrid/getColumns";
import {
  getRowsFcf,
  getRowsWacc,
  getRowsDiscounted,
  getRowsTv,
  getRowsEnterprise,
} from "./../valuation/reactgrid/getRows";
import { useDashboardStore } from "../../stores/useDashboardStore";
import { getMonthsNames } from "../dashboard/utils/getMonths";

export const selector = (contextId) => (store) => ({
  setCompanyName: (data) => {
    const updated = store.report.map((layer1) => {
      if (layer1.contextId !== contextId) return layer1;

      return {
        ...layer1,
        companyName: data,
      };
    });

    store.setReport(updated);
  },
  setSummary: (data) => {
    const updated = store.report.map((layer1) => {
      if (layer1.contextId !== contextId) return layer1;

      return {
        ...layer1,
        summary: data,
      };
    });

    store.setReport(updated);
  },
});

const Download = ({ assumptions, priorYears, monthsDetails, valuation, report }) => {
  const contextId = useGeneralStore((state) => state.contextId);
  // const { autoSave } = useSaveContext();
  const { setCompanyName, setSummary } = useReportStore(selector(contextId));
  const { companyName, summary } = report;
  const dashboards = useDashboardStore((state) => state.dashboard);
  const layouts = dashboards.find((item) => (item.contextId = contextId)).layout;
  const { months, startDate } = assumptions;
  const xData = getMonthsNames(months, startDate, priorYears);

  const graphRefs = useRef(layouts.map(() => createRef()));

  const handleExport = async (e) => {
    e.preventDefault();

    // read template
    const response = await fetch("/assets/template/template.docx");
    const buffer = await response.arrayBuffer();

    // prepare data
    const columns = getColumns({ monthsDetails });
    const columns2 = getColumns2();

    const rowsFcf = getRowsFcf({ monthsDetails, valuation });
    const rowsWacc = getRowsWacc({ valuation });
    const rowsDiscounted = getRowsDiscounted({ monthsDetails, valuation });
    const rowsTv = getRowsTv({ valuation });
    const rowsEnterprise = getRowsEnterprise({ valuation });

    // create graphs
    const createImageFromGraph = async (ref) => {
      if (ref.current) {
        const chartInstance = ref.current.getChartInstance();
        const chartCanvas = chartInstance.canvas;
        return chartCanvas.toDataURL("image/png");
      }
      return null;
    };

    const graphImages = await Promise.all(graphRefs.current.map(createImageFromGraph));

    // create table
    const createTableFromRows = (rows, columns) => {
      if (!columns || columns.length === 0 || !rows || rows.length === 0) {
        return null;
      }
      const dataRows = rows.map(
        (row) =>
          new TableRow({
            children: row.cells.map((cell) => {
              const cellContent = cell.text !== undefined && cell.text !== null ? cell.text : cell.value;

              return new TableCell({
                children: [new Paragraph({ children: [new TextRun(String(cellContent) || "")] })],
              });
            }),
          })
      );

      return new Table({
        rows: [...dataRows],
      });
    };

    const fcfTable = createTableFromRows(rowsFcf, columns);
    const waccTable = createTableFromRows(rowsWacc, columns2);
    const discountedTable = createTableFromRows(rowsDiscounted, columns);
    const tvTable = createTableFromRows(rowsTv, columns2);
    const enterpriseTable = createTableFromRows(rowsEnterprise, columns2);

    // edit template
    const editedUint8 = await patchDocument(buffer, {
      patches: {
        getCompanyName: {
          type: PatchType.PARAGRAPH,
          children: [new TextRun(companyName || "")],
        },
        getSummary: {
          type: PatchType.PARAGRAPH,
          children: [new TextRun(summary || "")],
        },
        fcfTable: {
          type: PatchType.DOCUMENT,
          children: [fcfTable],
        },
        waccTable: {
          type: PatchType.DOCUMENT,
          children: [waccTable],
        },
        discountedTable: {
          type: PatchType.DOCUMENT,
          children: [discountedTable],
        },
        tvTable: {
          type: PatchType.DOCUMENT,
          children: [tvTable],
        },
        enterpriseTable: {
          type: PatchType.DOCUMENT,
          children: [enterpriseTable],
        },
        graph: {
          type: PatchType.DOCUMENT,
          children: graphImages.map(
            (image, index) =>
              new Paragraph({
                children: [
                  new ImageRun({
                    data: image,
                    transformation: {
                      width: 600,
                      height: 300,
                    },
                  }),
                ],
              })
          ),
        },
      },
    });

    // convert edited to blob
    const editedBlob = new Blob([editedUint8], {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    // download edited
    saveAs(editedBlob, "report.docx");
  };

  return (
    <>
      <form onSubmit={handleExport}>
        <input
          name="input"
          type="text"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          placeholder="Enter Company Name"
          style={{ display: "block", width: "60%", margin: "30px 0px", padding: "10px" }}
        />
        <textarea
          name="textarea"
          value={summary}
          onChange={(e) => setSummary(e.target.value)}
          placeholder="Enter Executive Summary"
          style={{
            display: "block",
            width: "60%",
            marginBottom: "30px",
            padding: "10px",
            height: "150px",
            overflowY: "scroll",
            resize: "none",
          }}
        />
        <button type="submit" className={StatementsCSS.downloadButton}>
          Download
        </button>
      </form>
      {layouts.map((item, index) => (
        <Graph
          key={index}
          ref={graphRefs.current[index]}
          title={item.data.title}
          xLabel="Months"
          xData={xData}
          yLabel="Amount"
          yData1={item.data.yData1}
          yData2={item.data.yData2}
        />
      ))}
    </>
  );
};

export default Download;
