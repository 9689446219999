import RightBarCSS from "../../../../styles/pages/RightBar.module.css";
import { useStore } from "../../store";
import { usePriorYearsStore } from "../../../../stores/usePriorYearsStore";
import { selector } from "./ContDirectCostsDetail";
import { Table } from "../utils/Table";
import { NestedList } from "../utils/NestedList";

const ContIndirectCostsDetail = ({ node }) => {
  const { id, data, contextId } = node;
  const {
    assumptions,
    setGeneral,
    setInputs,
    setInputsCustom,
    setCustomLong,
    resetCustomLong,
    addItem,
    deleteItem,
    sortItems,
  } = useStore(selector(id, data));

  const getPriorYears = usePriorYearsStore.getState().getPriorYears;
  const priorYears = getPriorYears(contextId);

  return (
    <div className={RightBarCSS.details}>
      <div>
        <div className={RightBarCSS.container_header}>
          <h2>Indirect Costs</h2>
        </div>

        <>
          <h3>General</h3>
          <div className={RightBarCSS.container}>
            <div className={RightBarCSS.general}>
              <div className={RightBarCSS.whole_line}>
                <span className={RightBarCSS.description}>Description</span>
                <input
                  type="text"
                  name="text"
                  className={RightBarCSS.inputTextLong}
                  value={data.general.description}
                  onChange={(e) => {
                    setGeneral("description", e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </>

        <>
          <h3>Inputs (link)</h3>
          <Table items={data.inputsLink} assumptions={assumptions} priorYears={priorYears} />
        </>

        <>
          <h3>Inputs (self)</h3>
          <NestedList
            items={data.inputsSelf}
            setInputs={setInputs}
            setInputsCustom={setInputsCustom}
            setCustomLong={setCustomLong}
            resetCustomLong={resetCustomLong}
            addItem={addItem}
            deleteItem={deleteItem}
            sortItems={sortItems}
            assumptions={assumptions}
            priorYears={priorYears}
          />
        </>
      </div>
    </div>
  );
};

export default ContIndirectCostsDetail;
