import { useEffect, useState } from "react";
import { useStore } from "./store";
import { useSaveContext } from "../../saveAndRestore/useSaveContext";
import FlowHandlers from "./FlowHandlers";

import "@xyflow/react/dist/style.css";
import { ReactFlowProvider, ReactFlow, Background, Controls, MiniMap } from "@xyflow/react";
import { useNodesState, useEdgesState } from "@xyflow/react";
import { selector, nodeTypes, edgeTypes } from "./constants";

import { getColor } from "./minimapColors";
import { onDragStart, onDragOver, onDrop } from "./utilsFunctions/dragAndDrop";
import RightBar from "./RightBar";

const View = ({ view, contextId, Panel }) => {
  const store = useStore(selector(view, contextId));
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const { autoSave } = useSaveContext();
  const [selectedId, setSelectedId] = useState(null);

  const [nodes, setNodes, onNodesChange] = useNodesState();
  const [edges, setEdges, onEdgesChange] = useEdgesState();

  const { filteredNodes, filteredEdges, handleNodesChange, handleEdgesChange, handleConnect } = FlowHandlers({
    contextId,
    onNodesChange,
    onEdgesChange,
    autoSave,
    setSelectedId,
  });

  useEffect(() => {
    setNodes(filteredNodes);
    setEdges(filteredEdges);
  }, [filteredNodes, filteredEdges, setNodes, setEdges]);

  return (
    <ReactFlowProvider>
      <div
        style={{
          width: "100%",
          height: "calc(100vh - var(--navbar) - 3rem)",
        }}
      >
        <ReactFlow
          // normal
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          nodes={nodes}
          edges={edges}
          onNodesChange={handleNodesChange}
          onEdgesChange={handleEdgesChange}
          onConnect={handleConnect}
          // dnd
          onInit={setReactFlowInstance}
          onDrop={(event) => onDrop(event, reactFlowInstance, store)}
          onDragOver={onDragOver}
          // ui/ux
          fitView
          deleteKeyCode={["Backspace", "Delete"]}
        >
          <Panel view={view} contextId={contextId} onDragStart={onDragStart} />
          <Background />
          <Controls />
          <MiniMap nodeColor={(n) => getColor(n.type, 0.4)} />
          <RightBar id={selectedId} />
        </ReactFlow>
      </div>
    </ReactFlowProvider>
  );
};

export default View;
