import styles from "./styles.module.css";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";

import { useSaveContext } from "../../../../saveAndRestore/useSaveContext";

import SaveProgress from "../../../../components/save/SaveProgress";
import SelectContext from "../../../../components/selectContext/SelectContext";

import HeaderExport from "./HeaderExport";

const Header = () => {
  const style = { fontSize: "2rem" };

  // save
  const { onSave } = useSaveContext();

  return (
    <div className={styles.header}>
      <div className={styles.header_item}>
        <SelectContext />
      </div>
      <div className={styles.header_item}>
        <HeaderExport />
      </div>
      <div className={styles.header_item}>
        <Tooltip title="Save">
          <IconButton onClick={onSave}>
            <SaveIcon style={style} />
          </IconButton>
        </Tooltip>
        <SaveProgress />
      </div>
    </div>
  );
};

export default Header;
