import { useAuthContext } from "./useAuthContext";

import axios from "../axios/axios";

export const useRefreshToken = () => {
  const { dispatch } = useAuthContext();

  const refresh = async () => {
    try {
      const response = await axios.get("api/user/refreshToken", {
        withCredentials: true,
      });

      const { email, accessToken } = response.data;
      dispatch({ type: "LOGIN", payload: { email, accessToken } });

      return response.data.accessToken;
    } catch (err) {
      console.log("refresh", err.response.data);
    }
  };

  return refresh;
};
