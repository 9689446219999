import styles from "./styles.module.css";
import { useState } from "react";
import { useLogin } from "../auth/useLogin";
import { useNavigate, useLocation } from "react-router-dom";

const Login = ({ toSignupPage }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, error, isLoading } = useLogin();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async (e) => {
    e.preventDefault();
    await login(email, password);
  };

  const handleClick = (e) => {
    e.preventDefault();
    toSignupPage();
  };

  const handleOauth = () => {
    sessionStorage.setItem("path", location.pathname);
    navigate("/redirect");
  };

  return (
    <div className={styles.login}>
      <p className={styles.login_title}>Sign in to Simplyfi Forecast</p>
      <p className={styles.login_desc}>Create a profile to save your progress!</p>

      {process.env.REACT_APP_NODE_ENV === "production" && (
        <button onClick={handleOauth}>Log In with Simplyfi Hub</button>
      )}

      {process.env.REACT_APP_NODE_ENV === "development" && (
        <>
          <form onSubmit={handleLogin}>
            <label htmlFor="email">Login</label>
            <input
              type="email"
              id="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Email"
              autoComplete="email"
            />
            <input
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              placeholder="Password"
              autoComplete="current-password"
            />
            <button disabled={isLoading}>Log In</button>
            {error && <div className={styles.error}>{error}</div>}
          </form>

          <div className={styles.form_link}>
            <span>
              Don't have an account?&nbsp;
              <a href="/" onClick={handleClick}>
                Sign Up
              </a>
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default Login;
