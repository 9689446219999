import RightBarCSS from "../../../../styles/pages/RightBar.module.css";
import { TableSingle } from "./Table";
import { ButtonPlus, ButtonDelete, ButtonEdit } from "./Button";
import { useState } from "react";
import { ModalEditNumberNested } from "./ModalEdit";

import { Reorder } from "framer-motion";

export const NestedList = ({
  items,
  setInputs,
  setInputsCustom,
  setCustomLong,
  resetCustomLong,
  addItem,
  deleteItem,
  sortItems,
  assumptions,
  priorYears,
}) => {
  const depth = 0;
  const indices = [];

  const [modalState, setModalState] = useState([0, []]);
  const handleOpenModal = (depth, index) => {
    setModalState([depth, index]);
  };
  const handleCloseModal = () => {
    setModalState([0, []]);
  };

  const renderItems = (items, depth, indices) => {
    return (
      <Reorder.Group axis="y" values={items.map((item) => item.id)} onReorder={sortItems}>
        {items.map((item, index) => {
          const depthNext = depth + 1;
          const indicesNext = [...indices, index];

          return (
            <div key={index} className={RightBarCSS.nested}>
              <Reorder.Item key={item.id} value={item.id}>
                <>
                  <div className={RightBarCSS.whole_line}>
                    {/* <ButtonPlus onClick={() => addItem(depthNext, indicesNext)} /> */}
                    <input
                      type="text"
                      name="text"
                      className={RightBarCSS.inputTextLong}
                      value={item.description}
                      onChange={(e) => setInputs(depthNext, indicesNext, "description", e.target.value)}
                    />
                    {item.children.length === 0 && (
                      <>
                        <ButtonEdit onClick={() => handleOpenModal(depthNext, indicesNext)} />
                        {modalState[0] === depthNext &&
                          JSON.stringify(modalState[1]) === JSON.stringify(indicesNext) && (
                            <ModalEditNumberNested
                              open={true}
                              handleClose={handleCloseModal}
                              item={item}
                              depthNext={depthNext}
                              indicesNext={indicesNext}
                              setOutputs={setInputsCustom}
                              setCustomLong={setCustomLong}
                              resetCustomLong={resetCustomLong}
                              assumptions={assumptions}
                              priorYears={priorYears}
                            />
                          )}
                      </>
                    )}
                    <ButtonDelete onClick={() => deleteItem(depthNext, indicesNext)} />
                  </div>
                  <TableSingle item={item} assumptions={assumptions} priorYears={priorYears} />
                </>
                {renderItems(item.children, depthNext, indicesNext)}
              </Reorder.Item>
            </div>
          );
        })}
      </Reorder.Group>
    );
  };

  return (
    <div className={RightBarCSS.container}>
      <ButtonPlus onClick={() => addItem(depth, indices)} />
      {renderItems(items, depth, indices)}
    </div>
  );
};
