import DashboardCSS from "../../styles/pages/Dashboard.module.css";
import { useDashboardStore } from "../../stores/useDashboardStore";
import { useGeneralStore } from "../../stores/useGeneralStore";
import { useSaveContext } from "../../saveAndRestore/useSaveContext";

import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import GridItem from "./GridItem";
import Navigator from "./Navigator";
import { getMonthsNames } from "./utils/getMonths";

export const selector = (contextId) => (store) => ({
  updateSelected: (option) => {
    const updated = store.dashboard.map((layer1) => {
      if (layer1.contextId !== contextId) return layer1;

      const isSelected = layer1.selected.includes(option);
      const updatedSelected = isSelected
        ? layer1.selected.filter((item) => item !== option)
        : [...layer1.selected, option];

      return {
        ...layer1,
        selected: updatedSelected,
      };
    });

    store.setDashboard(updated);
  },
  updateLayout: (newLayout) => {
    const updated = store.dashboard.map((layer1) => {
      if (layer1.contextId !== contextId) return layer1;

      const updatedLayout = layer1.layout.map((layer2, index) => {
        return {
          ...layer2,
          ...newLayout[index],
        };
      });

      return {
        ...layer1,
        layout: updatedLayout,
      };
    });

    store.setDashboard(updated);
  },
});

const Dashboard = ({ assumptions, priorYears, dashboard }) => {
  const contextId = useGeneralStore((state) => state.contextId);
  const { autoSave } = useSaveContext();
  const { updateSelected, updateLayout } = useDashboardStore(selector(contextId));

  const { months, startDate } = assumptions;
  const monthsNames = getMonthsNames(months, startDate, priorYears);

  const { selected, layout } = dashboard;

  const handleOnSelect = (option) => {
    updateSelected(option);
    autoSave();
  };

  const handleLayoutChange = (newLayout) => {
    updateLayout(newLayout);
    autoSave();
  };

  return (
    <div className={DashboardCSS.dashboard}>
      <Navigator selected={selected} onSelect={handleOnSelect} />
      <GridLayout
        layout={layout}
        cols={4} // number of columns
        rowHeight={250} // height of the rows
        width={1200} // width of the grid
        onLayoutChange={handleLayoutChange}
      >
        {layout.map((item) => (
          <div key={item.i} className={DashboardCSS.grid}>
            <GridItem
              title={item.data.title}
              xData={monthsNames}
              yData1={item.data.yData1}
              yData2={item.data.yData2}
              tableItems={item.data.tableItems}
            />
          </div>
        ))}
      </GridLayout>
    </div>
  );
};

export default Dashboard;
