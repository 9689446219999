import StatementsCSS from "../../styles/pages/Statements.module.css";
import { useState } from "react";

import { useGeneralStore } from "../../stores/useGeneralStore";
import { usePriorYearsStore } from "../../stores/usePriorYearsStore";

import Prior from "./Prior";

const Route = () => {
  const [page, setPage] = useState("Prior");

  const contextId = useGeneralStore((state) => state.contextId);

  const priorYearss = usePriorYearsStore((state) => state.priorYears);
  const priorYears = priorYearss.find((priorYear) => priorYear.contextId === contextId);

  if (!priorYears) {
    return <div>Select a contextId.</div>;
  }

  return (
    <div className={StatementsCSS.statements}>
      <div className={StatementsCSS.routes}>
        <button
          className={`${StatementsCSS.route} ${page === "Prior" ? StatementsCSS.active : ""}`}
          onClick={() => setPage("Prior")}
        >
          Prior
        </button>
      </div>

      {page === "Prior" && priorYears && <Prior priorYears={priorYears} />}
    </div>
  );
};

export default Route;
