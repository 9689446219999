import Button from "@mui/material/Button";
import FileSaver from "file-saver";

import { useStore } from "../../../flow/store";
import { useStatementsStore } from "../../../../stores/useStatementsStore";
import { useStatementsAnnualStore } from "../../../../stores/useStatementsAnnualStore";
import { useActualStore } from "../../../../stores/useActualStore";
import { useDifferencesStore } from "../../../../stores/useDifferencesStore";
import { useValuationStore } from "../../../../stores/useValuationStore";

const HeaderExport = () => {
  const onSaveToFile = () => {
    const assumptions = useStore.getState().assumptions;
    const priorYears = useStore.getState().priorYears;
    const statements = useStatementsStore.getState().statements;
    const statementsAnnual = useStatementsAnnualStore.getState().statementsAnnual;
    const actual = useActualStore.getState().actual;
    const differences = useDifferencesStore.getState().differences;
    const valuation = useValuationStore.getState().valuation;

    const flow = {
      assumptions,
      priorYears,
      statements,
      statementsAnnual,
      actual,
      differences,
      valuation,
    };

    const blob = new Blob([JSON.stringify(flow, null, 2)], { type: "application/json" });
    FileSaver.saveAs(blob, "flow-data.json");
  };

  return <CustomButton description="Export to JSON file" onClick={onSaveToFile} />;
};

export default HeaderExport;

const CustomButton = ({ description, onClick }) => {
  return (
    <Button role={undefined} variant="outlined" tabIndex={-1} sx={{ textTransform: "none" }} onClick={onClick}>
      {description}
    </Button>
  );
};
