import StatementsCSS from "../../styles/pages/Statements.module.css";
import { useValuationStore } from "../../stores/useValuationStore";
import { useGeneralStore } from "../../stores/useGeneralStore";
import { useSaveContext } from "../../saveAndRestore/useSaveContext";

import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "../statements/reactgrid/styles.css";
import { getColumns, getColumns2 } from "./reactgrid/getColumns";
import { getRowsFcf, getRowsWacc, getRowsDiscounted, getRowsTv, getRowsEnterprise } from "./reactgrid/getRows";

export const selector = (contextId) => (store) => ({
  updateValuationDetail: (changes) => {
    const updated = store.valuation.map((layer1) => {
      if (layer1.contextId !== contextId) return layer1;

      return {
        ...layer1,
        ...changes.reduce(
          (layer1Acc, change) => {
            const [section1, section2] = change.rowId.split(".");
            const value = change.newCell.value;
            layer1Acc[section1][section2] = value;
            return layer1Acc;
          },
          { ...layer1 }
        ),
      };
    });

    store.updateValuationDetail(updated);
  },
});

const Valuation = ({ monthsDetails, valuation }) => {
  const contextId = useGeneralStore((state) => state.contextId);
  const { autoSave } = useSaveContext();
  const { updateValuationDetail } = useValuationStore(selector(contextId));

  const columns = getColumns({ monthsDetails });
  const columns2 = getColumns2();

  const rowsFcf = getRowsFcf({ monthsDetails, valuation });
  const rowsWacc = getRowsWacc({ valuation });
  const rowsDiscounted = getRowsDiscounted({ monthsDetails, valuation });
  const rowsTv = getRowsTv({ valuation });
  const rowsEnterprise = getRowsEnterprise({ valuation });

  const handleChanges = (changes) => {
    updateValuationDetail(changes);
    autoSave();
  };

  return (
    <div className={StatementsCSS.tableContainer}>
      <h1>Calculating Free Cash Flow</h1>
      <>
        <div className="reactgrid">
          <ReactGrid rows={rowsFcf} columns={columns} enableRangeSelection />
        </div>
      </>
      <h1>Calculating WACC</h1>
      <>
        <div className="reactgrid">
          <ReactGrid rows={rowsWacc} columns={columns2} onCellsChanged={handleChanges} enableRangeSelection />
        </div>
      </>
      <h1>Calculating Discounted FCF</h1>
      <>
        <div className="reactgrid">
          <ReactGrid rows={rowsDiscounted} columns={columns} onCellsChanged={handleChanges} enableRangeSelection />
        </div>
      </>
      <h1>Calculating Terminal Value</h1>
      <>
        <div className="reactgrid">
          <ReactGrid rows={rowsTv} columns={columns2} onCellsChanged={handleChanges} enableRangeSelection />
        </div>
      </>
      <h1>Calculating Enterprise Value</h1>
      <>
        <div className="reactgrid">
          <ReactGrid rows={rowsEnterprise} columns={columns2} onCellsChanged={handleChanges} enableRangeSelection />
        </div>
      </>
    </div>
  );
};

export default Valuation;
