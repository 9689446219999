import styles from "./styles.module.css";
import { useSaveContext } from "../../saveAndRestore/useSaveContext";

const SaveProgress = () => {
  const { isSaved } = useSaveContext();

  return (
    <div className={styles.main}>
      {isSaved ? (
        <span className={`${styles.progress} ${styles.saved}`}>Saved</span>
      ) : (
        <span className={`${styles.progress} ${styles.saving}`}>Saving . . .</span>
      )}
    </div>
  );
};

export default SaveProgress;
