export const nonEditable = (cell) => {
  return {
    ...cell,
    nonEditable: true,
    className: cell.className ? `${cell.className} disabled` : "disabled",
  };
};

export const showZero = (cell) => {
  return {
    ...cell,
    nanToZero: true,
    hideZero: false,
  };
};

export const emptyTextCell = () => {
  return { type: "text", text: "" };
};

export const textCell = (text, className) => {
  return {
    type: "text",
    text,
    className,
  };
};

export const numberCell = (value, className) => {
  const numberFormat = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return {
    type: "number",
    value,
    className,
    format: numberFormat,
  };
};

export const decimalCell = (value, className, decimal) => {
  const numberFormat = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });

  return {
    type: "number",
    value,
    className,
    format: numberFormat,
  };
};

export const dateCell = (text, className) => {
  return {
    type: "text",
    text,
    className,
  };
};

export const dropdownCell = (selectedValue, className) => {
  const values = [
    { label: "N/A", value: "N/A" },
    { label: "blank", value: "blank" },
    { label: "marketing", value: "marketing" },
    { label: "sales", value: "sales" },
    { label: "revenue", value: "revenue" },
    { label: "staff", value: "staff" },
    { label: "directCosts", value: "directCosts" },
    { label: "indirectCosts", value: "indirectCosts" },
    { label: "expenses", value: "expenses" },
    { label: "profit", value: "profit" },
    { label: "capex", value: "capex" },
    { label: "loan", value: "loan" },
    { label: "receivables", value: "receivables" },
    { label: "payables", value: "payables" },
    { label: "shareCap", value: "shareCap" },
    { label: "retained", value: "retained" },
    { label: "cash", value: "cash" },
    { label: "sofp", value: "sofp" },
  ];

  return {
    type: "dropdown",
    selectedValue,
    values,
    isDisabled: false,
    isOpen: false,
    className,
  };
};

export const deleteColButtonCell = (label, colIndex) => {
  return {
    type: "deleteColButton",
    label,
    colIndex,
  };
};

export const deleteRowButtonCell = (label, rowIndex) => {
  return {
    type: "deleteRowButton",
    label,
    rowIndex,
  };
};
