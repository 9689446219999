export const fbGetLoginStatus = () => {
  console.log("----- getLoginStatus -----");
  return new Promise((resolve, reject) => {
    window.FB.getLoginStatus((response) => {
      resolve(response);
    });
  });
};

export const fbLogin = () => {
  console.log("----- login -----");
  return new Promise((resolve, reject) => {
    window.FB.login(
      (response) => {
        resolve(response);
      },
      { auth_type: "reauthenticate" }
    );
  });
};

export const fbLogout = () => {
  console.log("----- logout -----");
  return new Promise((resolve, reject) => {
    window.FB.logout((response) => {
      resolve(response);
    });
  });
};

export const fbGetMe = () => {
  console.log("----- getMe -----");
  return new Promise((resolve, reject) => {
    window.FB.api("/me", (response) => {
      resolve(response);
    });
  });
};

export const fbGetAdAccountId = () => {
  console.log("----- getAdAccountId -----");
  return new Promise((resolve, reject) => {
    window.FB.api("/me/adaccounts", (response) => {
      resolve(response);
    });
  });
};

export const fbGetAdAccountInsights = (adAccountId) => {
  console.log("----- getAdAccountInsights -----");
  const fields = "impressions,reach,frequency,spend,account_currency";
  const date_preset = "maximum";
  return new Promise((resolve, reject) => {
    window.FB.api(`/${adAccountId}/insights`, "GET", { fields: fields, date_preset: date_preset }, function (response) {
      resolve(response);
    });
  });
};
