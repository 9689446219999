import styles from "./styles.module.css";
import MascotResting from "./MascotResting";

const Loading = () => {
  return (
    <div className={styles.main}>
      <MascotResting />
      <h1>Loading</h1>
    </div>
  );
};

export default Loading;
