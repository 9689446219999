import StatementsCSS from "../../styles/pages/Statements.module.css";
import { useActualStore } from "../../stores/useActualStore";
import { useGeneralStore } from "../../stores/useGeneralStore";
import { useSaveContext } from "../../saveAndRestore/useSaveContext";

import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "../statements/reactgrid/styles.css";
import { getColumns } from "./reactgrid/getColumns";
import { getRowsSOFP } from "./reactgrid/getRows";

export const selector = (contextId) => (store) => ({
  updateData: (changes) => {
    const updated = store.actual.map((layer1) => {
      if (layer1.contextId !== contextId) return layer1;

      return {
        ...layer1,
        ...changes.reduce(
          (layer1Acc, change) => {
            const [category, description, id] = change.rowId.split("|");
            const col = change.columnId;
            const value = change.newCell.value;

            layer1Acc[category] = layer1Acc[category].map((layer2) => {
              if (layer2.description !== description) return layer2;

              return {
                ...layer2,
                long: layer2.long.map((layer3) => {
                  if (layer3.id !== id) return layer3;

                  return {
                    ...layer3,
                    long: layer3.long.map((layer4, index) => {
                      if (index !== col) return layer4;

                      return value;
                    }),
                  };
                }),
              };
            });
            return layer1Acc;
          },
          { ...layer1 }
        ),
      };
    });

    store.updateActualDetail(updated);
  },
});

const SOFP = ({ monthsDetails, differences }) => {
  const contextId = useGeneralStore((state) => state.contextId);
  const { autoSave } = useSaveContext();
  const { updateData } = useActualStore(selector(contextId));

  const columns = getColumns({ monthsDetails });
  const rows = getRowsSOFP({ monthsDetails, differences });

  const handleChanges = (changes) => {
    updateData(changes);
    autoSave();
  };

  return (
    <div className={StatementsCSS.tableContainer}>
      <h1>Statement of Financial Position</h1>
      <div className="outer">
        <div className="reactgrid">
          <ReactGrid
            rows={rows}
            columns={columns}
            onCellsChanged={handleChanges}
            stickyTopRows={2}
            stickyLeftColumns={1}
            enableRangeSelection
          />
        </div>
      </div>
    </div>
  );
};

export default SOFP;
