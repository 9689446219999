import { usePriorYearsStore } from "../../../../stores/usePriorYearsStore";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudDownloadRoundedIcon from "@mui/icons-material/CloudDownloadRounded";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import { containersList } from "./containersList";

const HeaderTemplate = ({ contextId }) => {
  const updatePriorYearsDetail = usePriorYearsStore.getState().updatePriorYearsDetail;
  const priorYearss = usePriorYearsStore.getState().priorYears;
  const priorYears = priorYearss.find((priorYear) => priorYear.contextId === contextId);
  const priorLength = priorYears ? priorYears.priorLength : 0;

  const downloadTemplate = async () => {
    // Create a new workbook
    const workbook = new ExcelJS.Workbook();

    // Create worksheets
    const priorWorksheet = workbook.addWorksheet("priorYears");

    const columnCount = priorYears.headers[0].length;
    const columns = [
      { width: 25 }, // First column
      ...Array(columnCount - 2).fill({ width: 15 }), // Middle columns
      { width: 25 }, // Last column
    ];

    priorWorksheet.columns = columns.map((col, index) => ({
      key: `col${index}`,
      width: col.width,
    }));

    priorYears.headers.forEach((header) => {
      priorWorksheet.addRow(header);
    });

    priorYears.rows.forEach((row) => {
      priorWorksheet.addRow(row);
    });

    // Create a buffer and save the file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, "priorYears.xlsx");
  };

  const uploadTemplate = async (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (event) => {
      const buffer = event.target.result;
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(buffer);

      const priorWorksheet = workbook.getWorksheet("priorYears");

      const index = priorYearss.findIndex((priorYear) => priorYear.contextId === contextId);
      const updated = deepCopy(priorYearss);
      const updatedPriorYear = { ...updated[index] };

      if (priorWorksheet) {
        // Read headers
        updatedPriorYear.headers = priorWorksheet.getRows(1, 3).map((row, rowIndex) => {
          const rowValues = [];
          row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            if (colNumber > priorLength + 2) {
              return;
            }
            rowValues.push(cell.value === undefined || cell.value === null ? "" : cell.value);
          });
          return rowValues;
        });

        // Read rows
        const startRow = 4;
        updatedPriorYear.rows = priorWorksheet.getRows(startRow, priorWorksheet.rowCount - startRow + 1).map((row) => {
          const values = [];
          row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            if (colNumber > priorLength + 2) {
              return;
            }
            if (colNumber === 1) {
              // First column
              values.push(cell.value === null ? "" : cell.value);
            } else if (colNumber === row.cellCount) {
              // Last column
              const cellValue = cell.value === null ? "N/A" : cell.value;
              values.push(containersList.includes(cellValue) ? cellValue : "N/A");
            } else {
              // Middle columns
              if (cell.value === null) {
                values.push(0);
              } else if (typeof cell.value === "object" && cell.value.result !== null) {
                values.push(cell.value.result);
              } else {
                values.push(cell.value);
              }
            }
          });
          return values;
        });
      }
      updated[index] = updatedPriorYear;
      updatePriorYearsDetail(updated);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <>
      <FileDownload fileDownload={downloadTemplate} />
      <FileUpload fileUpload={uploadTemplate} />
    </>
  );
};

export default HeaderTemplate;

const deepCopy = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  const newObj = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    newObj[key] = deepCopy(obj[key]);
  }

  return newObj;
};

const FileDownload = ({ fileDownload }) => {
  return (
    <Button
      role={undefined}
      variant="outlined"
      tabIndex={-1}
      startIcon={<CloudDownloadRoundedIcon />}
      sx={{ textTransform: "none" }}
      onClick={fileDownload}
    >
      Download Template
    </Button>
  );
};

const FileUpload = ({ fileUpload }) => {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <Button
      component="label"
      role={undefined}
      variant="outlined"
      tabIndex={-1}
      startIcon={<CloudUploadRoundedIcon />}
      sx={{ textTransform: "none" }}
    >
      Upload Template
      <VisuallyHiddenInput type="file" onChange={fileUpload} />
    </Button>
  );
};
