import FacebookCSS from "../../styles/pages/Facebook.module.css";

import { useEffect } from "react";
import { fbGetLoginStatus, fbLogin, fbLogout, fbGetMe, fbGetAdAccountId, fbGetAdAccountInsights } from "./FacebookSDK";

import { useStore, selector } from "./store";

const Main = () => {
  const store = useStore(selector);

  useEffect(() => {
    const getLoginStatus = async () => {
      const response = await fbGetLoginStatus();
      console.log(response);
      if (response.status === "connected") {
        await getMe();
        await getAdAccountIds();
      } else {
        clearStore();
      }
    };

    if (window.FB) {
      getLoginStatus();
    } else {
      setTimeout(getLoginStatus, 100);
    }
    // eslint-disable-next-line
  }, []);

  const login = async () => {
    const response = await fbLogin();
    console.log(response);
    if (response.status === "connected") {
      await getMe();
      await getAdAccountIds();
    } else {
      console.log("error (login):", response);
    }
  };

  const logout = async () => {
    const response = await fbLogout();
    console.log(response);
    if (response.status === "unknown") {
      clearStore();
    } else {
      console.log("error (logout):", response);
    }
  };

  const getMe = async () => {
    const response = await fbGetMe();
    console.log(response);
    store.setStatus("Logged in");
    store.setName(response.name);
    store.setId(response.id);
  };

  const getAdAccountIds = async () => {
    const response = await fbGetAdAccountId();
    console.log(response);
    const adAccountIds = response.data.map((data) => data.id);
    store.setAdAccountIds(adAccountIds);
  };

  const getAdAccountInsights = async () => {
    let response;
    if (store.adAccountId !== "") {
      response = await fbGetAdAccountInsights(store.adAccountId);
      console.log(response);
    }
    if (response && response.data.length > 0) {
      store.setInsights(response.data[0]);
    } else {
      store.setInsights({});
    }
  };

  const clearStore = () => {
    store.setStatus("Logged out");
    store.setName("");
    store.setId("");
    store.setAdAccountIds([]);
    store.setAdAccountId("");
    store.setInsights({});
  };

  return (
    <div className={FacebookCSS.main}>
      <div className={FacebookCSS.container}>
        <h1>FACEBOOK - LOG IN / OUT</h1>
        <button onClick={login}>Login</button>
        <button onClick={logout}>Logout</button>
      </div>

      <div className={FacebookCSS.container}>
        <h1>FACEBOOK - ACCOUNT DETAILS</h1>
        <h2>Status: {store.status}</h2>
        <h2>name: {store.name}</h2>
        <h2>id: {store.id}</h2>
      </div>

      <div className={FacebookCSS.container}>
        <h1>FACEBOOK ADS - INSIGHTS</h1>
        <h2>adAccountId:</h2>
        <select value={store.adAccountId} onChange={(event) => store.setAdAccountId(event.target.value)}>
          <option key={-1} value={""}>
            -
          </option>
          {store.adAccountIds.map((data, index) => (
            <option key={index} value={data}>
              {data}
            </option>
          ))}
        </select>

        <button onClick={getAdAccountInsights}>getAdAccountInsights</button>
        <table>
          <thead>
            <tr>
              <th>No.</th>
              <th>Field</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(store.insights).map(([key, value], index) => (
              <tr key={key}>
                <td>{index + 1}</td>
                <td>{key}</td>
                <td>{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Main;
