import RightBarCSS from "../../../../styles/pages/RightBar.module.css";
import { useStore } from "../../store";
import { usePriorYearsStore } from "../../../../stores/usePriorYearsStore";
import { selector } from "./ContMarketingDetail";
import { PriorsBox, AssignmentsBox, FormulasBox, OutputsBoxNormal } from "../utils/RightBarBox";

const ContBlankDetail = ({ node }) => {
  const { id, data, contextId } = node;
  const {
    assumptions,
    setGeneral,
    // setCore,
    setAssignsCustom,
    setAssigns,
    addAssign,
    deleteAssign,
    setCustomLong,
    resetCustomLong,
    setFormulas,
    addFormula,
    deleteFormula,
    addFormulasParam,
    deleteFormulasParam,
    sortParamsIndex,
  } = useStore(selector(id, data));

  const getPriorYears = usePriorYearsStore.getState().getPriorYears;
  const priorYears = getPriorYears(contextId);

  return (
    <div className={RightBarCSS.details}>
      <div>
        <div className={RightBarCSS.container_header}>
          <h2>Blank</h2>
        </div>

        <>
          <h3>General</h3>
          <div className={RightBarCSS.container}>
            <div className={RightBarCSS.general}>
              <div className={RightBarCSS.whole_line}>
                <span className={RightBarCSS.description}>Description</span>
                <input
                  type="text"
                  name="text"
                  className={RightBarCSS.inputTextLong}
                  value={data.general.description}
                  onChange={(e) => {
                    setGeneral("description", e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </>

        {data.priors.length > 0 && (
          <>
            <h3>Priors</h3>
            <div className={RightBarCSS.container}>
              <div className={RightBarCSS.priors}>
                <PriorsBox priors={data.priors} startDate={assumptions.startDate} priorYears={priorYears} />
              </div>
            </div>
          </>
        )}

        <>
          <h3>Inputs</h3>
          <div className={RightBarCSS.container}>
            <div className={RightBarCSS.assignments}>
              <AssignmentsBox
                assignments={data.assignments}
                setAssignsCustom={setAssignsCustom}
                setAssigns={setAssigns}
                addAssign={addAssign}
                deleteAssign={deleteAssign}
                setCustomLong={setCustomLong}
                resetCustomLong={resetCustomLong}
                assumptions={assumptions}
                priorYears={priorYears}
              />
            </div>
          </div>
        </>

        <>
          <h3>Formulas</h3>
          <div className={RightBarCSS.container}>
            <div className={RightBarCSS.formulas}>
              <FormulasBox
                formulas={data.formulas}
                setFormulas={setFormulas}
                sortParamsIndex={sortParamsIndex}
                addFormula={addFormula}
                deleteFormula={deleteFormula}
                addFormulasParam={addFormulasParam}
                deleteFormulasParam={deleteFormulasParam}
              />
            </div>
          </div>
        </>

        <>
          <h3>Outputs</h3>
          <div className={RightBarCSS.container}>
            <div className={RightBarCSS.outputs}>
              <OutputsBoxNormal outputs={data.outputs} assumptions={assumptions} priorYears={priorYears} />
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default ContBlankDetail;
