import StatementsCSS from "../../styles/pages/Statements.module.css";

import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "./reactgrid/styles.css";
import { getColumns } from "./reactgrid/getColumns";
import { getRowsSOFP } from "./reactgrid/getRows";

const SOFP = ({ monthly, monthsDetails, statement, annual }) => {
  const columns = getColumns({ monthly, monthsDetails });
  let rows = [];
  if (monthly) {
    rows = getRowsSOFP({ monthly, monthsDetails, statement });
  } else {
    rows = getRowsSOFP({ monthly, monthsDetails, statement: annual });
  }

  return (
    <div className={StatementsCSS.tableContainer}>
      <h1>Statement of Financial Position</h1>
      <div className="outer">
        <div className="reactgrid">
          <ReactGrid rows={rows} columns={columns} stickyTopRows={3} stickyLeftColumns={1} enableRangeSelection />
        </div>
      </div>
    </div>
  );
};

export default SOFP;
