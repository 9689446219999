const colors = {
  contContext: "#000000",

  contBlank: "#000000",
  contMarketing: "#f57d20",
  contSales: "#2dc5b8",
  contRevenue: "#2dc5b8",
  contStaff: "#3abeff",
  contDirectCosts: "#ff3366",
  contIndirectCosts: "#ff3366",
  contExpenses: "#ff3366",
  contProfit: "#7d6aeb",
  contCapex: "#f9c710",
  contLoan: "#f9c710",
  contReceivables: "#f9c710",
  contPayables: "#f9c710",
  contShareCap: "#7d6aeb",
  contRetained: "#7d6aeb",
  contCash: "#7d6aeb",
  contSofp: "#7d6aeb",
};

const hexToRGBA = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r},${g},${b},${alpha})`;
};

export const getColor = (type, alpha = 1) => {
  const color = colors[type];
  if (color) {
    const rgbaColor = hexToRGBA(color, alpha);
    return rgbaColor;
  }
  return "#000000";
};
