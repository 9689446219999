import styles from "../styles.module.css";
import SaveProgress from "../../../../components/save/SaveProgress";

const HeaderSave = () => {
  return (
    <div className={styles.header_info}>
      <SaveProgress />
    </div>
  );
};

export default HeaderSave;
