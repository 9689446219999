const formatDate = (dateString) => {
  const [year, month] = dateString.split("-").map(Number);
  const date = new Date(year, month - 1, 1);
  return new Intl.DateTimeFormat("en-US", { month: "short", year: "2-digit" }).format(date);
};

const getDate = (year, month) => {
  const date = new Date(year, month - 1, 1);
  return new Intl.DateTimeFormat("en-US", { month: "short", year: "2-digit" }).format(date);
};

export const getMonthsNames = ({ months, startDate, priorYear }) => {
  // b/f
  const dateStrings = priorYear.headers[0].slice(1, -1);
  const bfNames = dateStrings.map((dateString) => formatDate(dateString));

  // forecast
  const forecastNames = Array.from({ length: months }, (_, i) => {
    const [startYear, startMonth] = startDate.split("-").map(Number);
    return getDate(startYear, startMonth + i);
  });

  const monthsNames = [...bfNames, ...forecastNames];
  return monthsNames;
  // result: "Dec 21", "Dec 22", "Dec 23", "Jan 24", "Feb 24", "Mar 24"
};

export const getMonthsArray = ({ months, priorYear }) => {
  // b/f
  const { priorLength } = priorYear;
  const bfArray = Array.from({ length: priorLength }, () => "b/f");

  // forecast
  const forecastArray = Array.from({ length: months }, (_, i) => (i + 1).toString());

  const monthsArray = [...bfArray, ...forecastArray];
  return monthsArray;
  // result: "b/f", "b/f", "b/f", "1", "2", "3"
};

export const getMonthsNumbers = ({ months, priorYear }) => {
  // b/f
  const bfNumbers = priorYear.headers[1].slice(1, -1).map(String);

  // forecast
  const forecastNumbers = Array.from({ length: months }, () => "1");

  const monthsNumbers = [...bfNumbers, ...forecastNumbers];
  return monthsNumbers;
  // result: "12", "12", "12", "1", "1", "1"
};
