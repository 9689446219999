import { nanoid } from "nanoid";

const COL_HEADER_WIDTH = 360;
const COL_CONTENT_WIDTH = 120;

export const getColumns = ({ monthsDetails }) => {
  const { totalMonths, priorLength } = monthsDetails;

  const columns = [
    {
      columnId: nanoid(),
      width: COL_HEADER_WIDTH,
    },
  ];

  for (let i = 0; i < totalMonths; i++) {
    if (i >= priorLength) {
      columns.push({
        columnId: nanoid(),
        width: COL_CONTENT_WIDTH,
      });
      columns.push({
        columnId: i,
        width: COL_CONTENT_WIDTH,
      });
      columns.push({
        columnId: nanoid(),
        width: COL_CONTENT_WIDTH,
      });
      columns.push({
        columnId: nanoid(),
        width: COL_CONTENT_WIDTH,
      });
    }
  }

  return columns;
};
