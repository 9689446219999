import styles from "../styles.module.css";
import { useStore } from "../../store";
import { useSaveContext } from "../../../../saveAndRestore/useSaveContext";

import Button from "@mui/material/Button";

// import FileSaver from "file-saver";

const HeaderGenerateFull = () => {
  const generateFull = useStore.getState().generateFull;

  // save
  const { autoSave } = useSaveContext();
  const handleGenerate = () => {
    generateFull();
    autoSave();
  };

  // const onSaveToFile = () => {
  //   const nodes = store.nodes;
  //   const edges = store.edges;
  //   const flow = { nodes: nodes, edges: edges };
  //   const blob = new Blob([JSON.stringify(flow, null, 2)], { type: "application/json" });
  //   FileSaver.saveAs(blob, "flow-data.json");
  // };

  return (
    <div className={styles.header_info}>
      {/* <button onClick={onSaveToFile}>Save to File</button> */}
      <CustomButton description="Generate Full Sample Model" onClick={handleGenerate} />
    </div>
  );
};

export default HeaderGenerateFull;

const CustomButton = ({ description, onClick }) => {
  return (
    <Button role={undefined} variant="outlined" tabIndex={-1} sx={{ textTransform: "none" }} onClick={onClick}>
      {description}
    </Button>
  );
};
