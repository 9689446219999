import FlowCSS from "../../../../styles/pages/Flow.module.css";
import { useStore } from "../../store";
import { usePriorYearsStore } from "../../../../stores/usePriorYearsStore";
import SideCircle from "../../components/SideCircle";
import { InputNode, DisplayOutputs } from "../utils/NodeIO";
import { ButtonShow } from "../utils/Button";
import { selector } from "../../contextNodes/ContContextNode";

export default function ContPayablesNode({ id, data }) {
  const {
    // setExpand,
    setVisible,
    node,
    container,
  } = useStore(selector(id, data));

  const getPriorLength = usePriorYearsStore.getState().getPriorLength;
  const priorLength = getPriorLength(node);

  return (
    <div className={FlowCSS.node}>
      <SideCircle container={container} />

      <div>
        <div className={FlowCSS.container_header}>
          <h2>{data.general.description}</h2>
          <ButtonShow onClick={setVisible} />
          {/* <button onClick={setExpand}>{data.general.expand ? "🔼" : "🔽"}</button> */}
        </div>

        {/* <div>
          <span>Id</span>
          <span>{id}</span>
        </div> */}

        {/* <>
          <h3>General</h3>
          <div className={FlowCSS.big}>
            <span>Description: {data.general.description}</span>
          </div>
        </> */}

        <>
          <h3>Inputs</h3>
          <div className={FlowCSS.big}>
            {data.assignments.map((item) => (
              <InputNode key={item.id} name={item.description} value={item.long[priorLength]} handleId={item.id} />
            ))}
          </div>
        </>

        <DisplayOutputs outputs={data.outputs} priorLength={priorLength} />
      </div>
    </div>
  );
}
