import { useRef, forwardRef, useImperativeHandle } from "react";
import { Line } from "react-chartjs-2";

const Graph = forwardRef((props, ref) => {
  const chartRef = useRef(null);

  // customize the ref handle
  useImperativeHandle(ref, () => ({
    getChartInstance: () => {
      return chartRef.current;
    },
  }));

  const { title, xLabel, xData, yLabel, yData1, yData2 } = props;

  const colors = [
    "#FF6384", // Red
    "#36A2EB", // Blue
    "#FFCE56", // Yellow
    "#4BC0C0", // Teal
    "#9966FF", // Purple
    "#FF9F40", // Orange
    "#FF6384", // Red (Repeated for more datasets)
    "#36A2EB", // Blue
    "#FFCE56", // Yellow
    "#4BC0C0", // Teal
  ];

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: title,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        title: {
          display: true,
          text: xLabel,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: yLabel,
        },
      },
    },
  };

  const datasets = [
    {
      label: "Projection",
      data: yData1,
      borderColor: colors[0],
      backgroundColor: colors[0],
      tension: 0.6,
      fill: false,
    },
    {
      label: "Actual",
      data: yData2,
      borderColor: colors[1],
      backgroundColor: colors[1],
      tension: 0.6,
      fill: false,
    },
  ];

  const data = {
    labels: xData,
    datasets: datasets,
  };

  return (
    <div style={{ width: "60%" }}>
      <Line ref={chartRef} data={data} options={options} style={{ width: "100%", height: "20rem", display: "none" }} />
    </div>
  );
});

export default Graph;
