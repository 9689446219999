import { useStore } from "../store";
import { usePriorYearsStore } from "../../../stores/usePriorYearsStore";
import { useStatementsStore } from "../../../stores/useStatementsStore";

export const updateStatements = () => {
  const nodes = useStore.getState().nodes;
  const edges = useStore.getState().edges;
  const assumptions = useStore.getState().assumptions;
  const priorYears = usePriorYearsStore.getState().priorYears;
  const statements = useStatementsStore.getState().statements;

  const contextIds = nodes.filter((node) => node.contextId === "").map((node) => node.id);

  // remove deleted context
  for (let i = statements.length - 1; i >= 0; i--) {
    if (!contextIds.includes(statements[i].contextId)) {
      statements.splice(i, 1);
    }
  }

  // add new context
  contextIds.forEach((contextId) => {
    const statementIndex = statements.findIndex((statement) => statement.contextId === contextId);
    const description = nodes.find((node) => node.id === contextId).data.general.description;
    if (statementIndex !== -1) {
      statements[statementIndex].description = description;
    } else {
      statements.push({
        contextId: contextId,
        description: description,
        sofp: [],
        sopl: [],
        socf: [],
      });
    }
  });

  statements.forEach((statement, index) => {
    const { months } = assumptions;
    const { priorLength } = priorYears[index];
    const totalMonths = months + priorLength;

    const sofpNode = nodes.find((node) => node.contextId === statement.contextId && node.container === "sofp");
    const soplNode = nodes.find((node) => node.contextId === statement.contextId && node.container === "profit");
    const socfNode = nodes.find((node) => node.contextId === statement.contextId && node.container === "cash");

    if (sofpNode) {
      const linkedSofp = findAllPrevNodes(nodes, edges, sofpNode);
      statement.sofp = updateSOFP(linkedSofp, edges, sofpNode, totalMonths);
    } else {
      statement.sofp = [];
    }
    if (soplNode) {
      const linkedSopl = findAllPrevNodes(nodes, edges, soplNode);
      statement.sopl = updateSOPL(linkedSopl, edges, soplNode, totalMonths);
    } else {
      statement.sopl = [];
    }
    if (socfNode) {
      const linkedSocf = findAllPrevNodes(nodes, edges, socfNode);
      statement.socf = updateSOCF(linkedSocf, edges, socfNode, totalMonths);
    } else {
      statement.socf = [];
    }
  });
};

const updateSOFP = (nodes, edges, sofpNode, totalMonths) => {
  // get all connecting nodes and edges
  const [assetsNodes, assetsEdges] = getConnecting("default_assignments_assets", nodes, edges, sofpNode);
  const [equityNodes, equityEdges] = getConnecting("default_assignments_equity", nodes, edges, sofpNode);
  const [liabilitiesNodes, liabilitiesEdges] = getConnecting("default_assignments_liabilities", nodes, edges, sofpNode);

  // get list of outputs from nodes
  const assetsOutputs = getOutputsList(assetsNodes, assetsEdges);
  const equityOutputs = getOutputsList(equityNodes, equityEdges);
  const liabilitiesOutputs = getOutputsList(liabilitiesNodes, liabilitiesEdges);

  // get long figures - return array of objects
  const assetsLongs = getLongs(assetsOutputs);
  const equityLongs = getLongs(equityOutputs);
  const liabilitiesLongs = getLongs(liabilitiesOutputs);

  // filter long figures into current and non-current
  const nonCurrentAssetsLongs = assetsLongs.filter((item) => item.container === "capex");
  const currentAssetsLongs = assetsLongs.filter((item) => item.container !== "capex");
  const nonCurrentLiabilitiesLongs = liabilitiesLongs.filter((item) => item.container === "loan");
  const currentLiabilitiesLongs = liabilitiesLongs.filter((item) => item.container !== "loan");

  // get total figures - return array of numbers
  const totalAssetsLong = getTotalLong(assetsLongs, totalMonths);
  const totalNonCurrentAssetsLong = getTotalLong(nonCurrentAssetsLongs, totalMonths);
  const totalCurrentAssetsLong = getTotalLong(currentAssetsLongs, totalMonths);
  const totalEquityLong = getTotalLong(equityLongs, totalMonths);
  const totalLiabilitiesLong = getTotalLong(liabilitiesLongs, totalMonths);
  const totalNonCurrentLiabilitiesLong = getTotalLong(nonCurrentLiabilitiesLongs, totalMonths);
  const totalCurrentLiabilitiesLong = getTotalLong(currentLiabilitiesLongs, totalMonths);
  const checkLong = Array.from({ length: totalMonths }).map((_, index) => {
    const assets = totalAssetsLong[index] || 0;
    const equity = totalEquityLong[index] || 0;
    const liabilities = totalLiabilitiesLong[index] || 0;
    return assets - equity - liabilities;
  });

  const sofp = [
    { description: "assetsLongs", type: "object", long: assetsLongs },
    { description: "equityLongs", type: "object", long: equityLongs },
    { description: "liabilitiesLongs", type: "object", long: liabilitiesLongs },
    { description: "nonCurrentAssetsLongs", type: "object", long: nonCurrentAssetsLongs },
    { description: "currentAssetsLongs", type: "object", long: currentAssetsLongs },
    { description: "nonCurrentLiabilitiesLongs", type: "object", long: nonCurrentLiabilitiesLongs },
    { description: "currentLiabilitiesLongs", type: "object", long: currentLiabilitiesLongs },

    { description: "totalAssetsLong", type: "array", long: totalAssetsLong },
    { description: "totalNonCurrentAssetsLong", type: "array", long: totalNonCurrentAssetsLong },
    { description: "totalCurrentAssetsLong", type: "array", long: totalCurrentAssetsLong },
    { description: "totalEquityLong", type: "array", long: totalEquityLong },
    { description: "totalLiabilitiesLong", type: "array", long: totalLiabilitiesLong },
    { description: "totalNonCurrentLiabilitiesLong", type: "array", long: totalNonCurrentLiabilitiesLong },
    { description: "totalCurrentLiabilitiesLong", type: "array", long: totalCurrentLiabilitiesLong },
    { description: "checkLong", type: "array", long: checkLong },
  ];
  return sofp;
};

const updateSOPL = (nodes, edges, soplNode, totalMonths) => {
  // get all connecting nodes and edges
  const [revenueNodes, revenueEdges] = getConnecting("default_assignments_revenue", nodes, edges, soplNode);
  const [expensesNodes, expensesEdges] = getConnecting("default_assignments_expenses", nodes, edges, soplNode);
  const [intExpNodes, intExpEdges] = getConnecting("default_assignments_intExp", nodes, edges, soplNode);

  // get list of outputs from nodes
  const revenueOutputs = getOutputsList(revenueNodes, revenueEdges);
  const expensesOutputs = getOutputsList(expensesNodes, expensesEdges);
  const intExpOutputs = getOutputsList(intExpNodes, intExpEdges);
  const taxOutputs = [
    {
      id: soplNode.id,
      container: soplNode.container,
      description: soplNode.data.general.description,
      outputs: soplNode.data.outputs.find((output) => output.id === "default_formulas_tax"),
    },
  ];

  // get long figures
  const revenueLongs = getLongs(revenueOutputs);
  const expensesLongs = getLongs(expensesOutputs);
  const intExpLongs = getLongs(intExpOutputs);
  const taxLongs = getLongs(taxOutputs);

  // get total figures
  const totalRevenueLong = getTotalLong(revenueLongs, totalMonths);
  const totalExpensesLong = getTotalLong(expensesLongs, totalMonths);
  const totalIntExpLong = getTotalLong(intExpLongs, totalMonths);
  const totalTaxLong = getTotalLong(taxLongs, totalMonths);

  const totalPbitLong = Array.from({ length: totalMonths }).map((_, index) => {
    const revenue = totalRevenueLong[index] || 0;
    const expenses = totalExpensesLong[index] || 0;
    return revenue - expenses;
  });
  const totalPbtLong = Array.from({ length: totalMonths }).map((_, index) => {
    const pbit = totalPbitLong[index] || 0;
    const intExp = totalIntExpLong[index] || 0;
    return pbit - intExp;
  });
  const totalProfitLong = Array.from({ length: totalMonths }).map((_, index) => {
    const pbt = totalPbtLong[index] || 0;
    const tax = totalTaxLong[index] || 0;
    return pbt - tax;
  });

  const sopl = [
    { description: "revenueLongs", type: "object", long: revenueLongs },
    { description: "expensesLongs", type: "object", long: expensesLongs },
    { description: "intExpLongs", type: "object", long: intExpLongs },
    { description: "taxLongs", type: "object", long: taxLongs },

    { description: "totalRevenueLong", type: "array", long: totalRevenueLong },
    { description: "totalExpensesLong", type: "array", long: totalExpensesLong },
    { description: "totalIntExpLong", type: "array", long: totalIntExpLong },
    { description: "totalTaxLong", type: "array", long: totalTaxLong },
    { description: "totalPbitLong", type: "array", long: totalPbitLong },
    { description: "totalPbtLong", type: "array", long: totalPbtLong },
    { description: "totalProfitLong", type: "array", long: totalProfitLong },
  ];
  return sopl;
};

const updateSOCF = (nodes, edges, socfNode, totalMonths) => {
  // get all connecting nodes and edges
  const [opNodes, opEdges] = getConnecting("default_assignments_operating", nodes, edges, socfNode);
  const [inNodes, inEdges] = getConnecting("default_assignments_investing", nodes, edges, socfNode);
  const [fiNodes, fiEdges] = getConnecting("default_assignments_financing", nodes, edges, socfNode);

  // get list of outputs from nodes
  const operatingOutputs = getOutputsList(opNodes, opEdges);
  const investingOutputs = getOutputsList(inNodes, inEdges);
  const financingOutputs = getOutputsList(fiNodes, fiEdges);

  // get long figures
  const operatingLongs = getLongs(operatingOutputs);
  const investingLongs = getLongs(investingOutputs);
  const financingLongs = getLongs(financingOutputs);

  // get total figures
  const totalOperatingLong = getTotalLong(operatingLongs, totalMonths);
  const totalInvestingLong = getTotalLong(investingLongs, totalMonths);
  const totalFinancingLong = getTotalLong(financingLongs, totalMonths);
  const totalLong = Array.from({ length: totalMonths }).map((_, index) => {
    const operating = totalOperatingLong[index] || 0;
    const investing = totalInvestingLong[index] || 0;
    const financing = totalFinancingLong[index] || 0;
    return operating + investing + financing;
  });

  const socf = [
    { description: "operatingLongs", type: "object", long: operatingLongs },
    { description: "investingLongs", type: "object", long: investingLongs },
    { description: "financingLongs", type: "object", long: financingLongs },

    { description: "totalOperatingLong", type: "array", long: totalOperatingLong },
    { description: "totalInvestingLong", type: "array", long: totalInvestingLong },
    { description: "totalFinancingLong", type: "array", long: totalFinancingLong },
    { description: "totalLong", type: "array", long: totalLong },
  ];
  return socf;
};

const findAllPrevNodes = (nodes, edges, targetNode) => {
  const result = [];

  const traverse = (currentNode) => {
    const incomingEdges = edges.filter((edge) => edge.target === currentNode.id);
    incomingEdges.forEach((edge) => {
      const sourceNode = nodes.find((node) => node.id === edge.source);
      if (sourceNode && !result.includes(sourceNode)) {
        result.push(sourceNode);
        traverse(sourceNode);
      }
    });
  };
  traverse(targetNode);

  result.push(targetNode);
  return result;
};

const getConnecting = (handle, nodes, edges, reportNode) => {
  const typeOrder = [
    "contRevenue",
    "contExpenses",
    "contProfit",
    "contCapex",
    "contReceivables",
    "contCash",
    "contShareCap",
    "contRetained",
    "contLoan",
    "contPayables",
    "contSofp",
  ];

  const sortNodesByType = (nodes) => {
    const customSort = (a, b) => {
      const indexA = typeOrder.indexOf(a.node.type);
      const indexB = typeOrder.indexOf(b.node.type);
      return indexA - indexB;
    };
    return nodes.sort(customSort);
  };

  // Get edges connecting to Report
  const reportEdges = edges.filter((edge) => edge.target === reportNode.id);
  // Get edges connecting to Report's handle
  const myEdges = reportEdges.filter((edge) => edge.targetHandle === handle);
  // Get nodes
  let nodeEdgePairs = myEdges.map((edge) => {
    const node = nodes.find((node) => node.id === edge.source);
    return { node, edge };
  });
  // Sort nodes and edges
  nodeEdgePairs = sortNodesByType(nodeEdgePairs);
  const sortedNodes = nodeEdgePairs.map((pair) => pair.node);
  const sortedEdges = nodeEdgePairs.map((pair) => pair.edge);

  return [sortedNodes, sortedEdges];
};

const getOutputsList = (nodes, edges) => {
  const outputs = edges.map((edge, index) => {
    return {
      id: nodes[index].id,
      container: nodes[index].container,
      description: nodes[index].data.general.description,
      outputs: nodes[index].data.outputs.find((output) => output.id === edge.sourceHandle),
    };
  });

  return outputs;
};

const getLongs = (outputs) => {
  const longs = [];

  outputs.forEach((output) => {
    const existing = longs.find((long) => long.id === output.id);

    if (existing) {
      existing.long = existing.long.map((num, idx) => (num || 0) + (output.outputs.long[idx] || 0));
    } else {
      longs.push({
        id: output.id,
        container: output.container,
        // description: `${output.description} - ${output.outputs.description}`,
        description: output.outputs.description,
        long: output.outputs.long,
      });
    }
  });

  return longs;
};

const getTotalLong = (longs, totalMonths) => {
  if (longs.length === 0) return Array(totalMonths).fill(0);

  return longs.reduce((acc, curr) => {
    return acc.map((num, idx) => num + curr.long[idx]);
  }, Array(totalMonths).fill(0));
};
