import StatementsCSS from "../../styles/pages/Statements.module.css";
import { useState } from "react";

import { useGeneralStore } from "../../stores/useGeneralStore";
import { useStore } from "../flow/store";
import { usePriorYearsStore } from "../../stores/usePriorYearsStore";
import { useDashboardStore } from "../../stores/useDashboardStore";

import Dashboard from "./Dashboard";

const Route = () => {
  const [page, setPage] = useState("Dashboard");

  const contextId = useGeneralStore((state) => state.contextId);

  const assumptions = useStore((state) => state.assumptions);
  const priorYearss = usePriorYearsStore((state) => state.priorYears);
  const dashboards = useDashboardStore((state) => state.dashboard);

  const priorYears = priorYearss.find((priorYear) => priorYear.contextId === contextId);
  const dashboard = dashboards.find((dashboard) => dashboard.contextId === contextId);

  if (!dashboard) {
    return <div>Select a contextId.</div>;
  }

  return (
    <div className={StatementsCSS.statements}>
      <div className={StatementsCSS.routes}>
        <button
          className={`${StatementsCSS.route} ${page === "Dashboard" ? StatementsCSS.active : ""}`}
          onClick={() => setPage("Dashboard")}
        >
          Dashboard
        </button>
      </div>

      {page === "Dashboard" && <Dashboard assumptions={assumptions} priorYears={priorYears} dashboard={dashboard} />}
    </div>
  );
};

export default Route;
