import styles from "./styles.module.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export const SelectCustom = ({ item, ques, keyQues, onChange, choices }) => {
  return (
    <div className={styles.line}>
      <span className={styles.question}>{ques}</span>
      <Select
        name={keyQues}
        value={item[keyQues]}
        onChange={(e) => {
          const selectedValue = e.target.value;
          onChange({ target: { name: keyQues, value: selectedValue } });
        }}
        sx={{
          fontFamily: "Poppins",
          width: "10rem",
        }}
      >
        {choices.map((choice, index) => (
          <MenuItem
            key={index}
            value={choice.value}
            sx={{
              fontFamily: "Poppins",
            }}
          >
            {choice.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
