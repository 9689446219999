import { Routes, Route } from "react-router-dom";

import Main from "./Main";

const CompareRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
    </Routes>
  );
};

export default CompareRoutes;
