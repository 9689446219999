import { useStore } from "../store";
import { usePriorYearsStore } from "../../../stores/usePriorYearsStore";
import { useStatementsStore } from "../../../stores/useStatementsStore";
import { useActualStore } from "../../../stores/useActualStore";

export const updateActual = (factor) => {
  const assumptions = useStore.getState().assumptions;
  const priorYears = usePriorYearsStore.getState().priorYears;
  const statements = useStatementsStore.getState().statements;
  const actual = useActualStore.getState().actual;

  const contextIds = statements.map((statement) => statement.contextId);

  // remove deleted context
  for (let i = actual.length - 1; i >= 0; i--) {
    if (!contextIds.includes(actual[i].contextId)) {
      actual.splice(i, 1);
    }
  }

  // add new context
  contextIds.forEach((contextId) => {
    const actualIndex = actual.findIndex((actual) => actual.contextId === contextId);
    const description = statements.find((statement) => statement.contextId === contextId).description;
    if (actualIndex !== -1) {
      actual[actualIndex].description = description;
    } else {
      actual.push({
        contextId: contextId,
        description: description,
        sofp: [],
        sopl: [],
        socf: [],
      });
    }
  });

  // copy over
  statements.forEach((_, index) => {
    copyOver("sofp", statements[index], actual[index]);
    copyOver("sopl", statements[index], actual[index]);
    copyOver("socf", statements[index], actual[index]);
  });

  if (factor === "prior") {
    statements.forEach((_, index) => {
      syncLengthPrior("sofp", statements[index], actual[index]);
      syncLengthPrior("sopl", statements[index], actual[index]);
      syncLengthPrior("socf", statements[index], actual[index]);
    });
  } else {
    // sync length
    statements.forEach((_, index) => {
      syncLength("sofp", statements[index], actual[index]);
      syncLength("sopl", statements[index], actual[index]);
      syncLength("socf", statements[index], actual[index]);
    });
  }

  // update prior figures
  statements.forEach((_, index) => {
    updatePriorYears("sofp", statements[index], actual[index], priorYears[index]);
    updatePriorYears("sopl", statements[index], actual[index], priorYears[index]);
    updatePriorYears("socf", statements[index], actual[index], priorYears[index]);
  });

  // calculate totals
  actual.forEach((actualItem, index) => {
    const { months } = assumptions;
    const { priorLength } = priorYears[index];
    const totalMonths = months + priorLength;

    if (actualItem.sofp.length > 0) {
      actualItem.sofp = updateSOFP(actualItem.sofp, totalMonths);
    }
    if (actualItem.sopl.length > 0) {
      actualItem.sopl = updateSOPL(actualItem.sopl, totalMonths);
    }
    if (actualItem.socf.length) {
      actualItem.socf = updateSOCF(actualItem.socf, totalMonths);
    }
  });
};

const copyOver = (section, statement, actual) => {
  const actualSection = actual[section];
  const statementSection = statement[section];

  // if statements = 0, actual > 0
  if (statementSection.length === 0 && actualSection.length > 0) {
    actual[section] = [];
  }

  // if statements > 0, actual = 0
  if (statementSection.length > 0 && actualSection.length === 0) {
    actual[section] = statementSection.map((item) => ({
      ...item,
      long: item.type === "array" ? [] : item.long.map((innerItem) => ({ ...innerItem, long: [] })),
    }));
  }

  // if statements > 0, actual > 0
  if (statementSection.length > 0 && actualSection.length > 0) {
    const actualSectionMap = new Map(actualSection.map((item) => [item.description, item]));

    statementSection.forEach((layer1) => {
      if (!actualSectionMap.has(layer1.description)) {
        // statements has, actual doesn't
        actual[section].push({
          ...layer1,
          long: layer1.type === "array" ? [] : layer1.long.map((innerItem) => ({ ...innerItem, long: [] })),
        });
      } else {
        // both have
        const actualLayer1 = actualSectionMap.get(layer1.description);
        actualLayer1.long = layer1.type === "array" ? [] : actualLayer1.long;

        if (layer1.type === "object") {
          const statementItems = layer1.long.map((item) => item.id);
          const actualItems = actualLayer1.long.map((item) => item.id);
          layer1.long.forEach((layer2, layer2Index) => {
            if (!actualItems.includes(layer2.id)) {
              actualLayer1.long.push({ ...layer2, long: [] });
            }
          });
          actualLayer1.long = actualLayer1.long.filter((actualLayer2) => statementItems.includes(actualLayer2.id));
        }
      }
    });

    // actual has, statements doesn't
    actual[section] = actual[section].filter((item) =>
      statementSection.some((statementItem) => statementItem.description === item.description)
    );

    // rearrange
    const rearrangedActualSection = statementSection.map((statementItem) => {
      return actualSection.find((actualItem) => actualItem.description === statementItem.description);
    });
    actual[section] = rearrangedActualSection;
  }
};

const syncLength = (section2, statement, actual) => {
  statement[section2].forEach((section, sectionIndex) => {
    if (section.type === "object") {
      const longs = section.long;
      longs.forEach((inner, innerIndex) => {
        while (inner.long.length > actual[section2][sectionIndex].long[innerIndex].long.length) {
          actual[section2][sectionIndex].long[innerIndex].long.push(0);
        }
        while (inner.long.length < actual[section2][sectionIndex].long[innerIndex].long.length) {
          actual[section2][sectionIndex].long[innerIndex].long.pop();
        }
      });
    }
    // else if (section.type === "array") {
    //   auto-calculate
    // }
  });
};

const syncLengthPrior = (section2, statement, actual) => {
  statement[section2].forEach((section, sectionIndex) => {
    if (section.type === "object") {
      const longs = section.long;
      longs.forEach((inner, innerIndex) => {
        while (inner.long.length > actual[section2][sectionIndex].long[innerIndex].long.length) {
          actual[section2][sectionIndex].long[innerIndex].long.unshift(0);
        }
        while (inner.long.length < actual[section2][sectionIndex].long[innerIndex].long.length) {
          actual[section2][sectionIndex].long[innerIndex].long.shift();
        }
      });
    }
    // else if (section.type === "array") {
    //   auto-calculate
    // }
  });
};

const updatePriorYears = (section2, statement, actual, priorYear) => {
  const { priorLength } = priorYear;

  statement[section2].forEach((section, sectionIndex) => {
    if (section.type === "object") {
      const longs = section.long;
      longs.forEach((inner, innerIndex) => {
        const innerLongs = inner.long;
        innerLongs.forEach((layer2, layer2Index) => {
          if (layer2Index < priorLength) {
            actual[section2][sectionIndex].long[innerIndex].long[layer2Index] = layer2;
          }
        });
      });
    }
    // else if (section.type === "array") {
    //   auto-calculate
    // }
  });
};

const updateSOFP = (items, totalMonths) => {
  const mapFormat = items.reduce((acc, { description, long }) => {
    acc[description] = long;
    return acc;
  }, {});

  const {
    // assetsLongs, // no use here
    equityLongs,
    // liabilitiesLongs, // no use here
    nonCurrentAssetsLongs,
    currentAssetsLongs,
    nonCurrentLiabilitiesLongs,
    currentLiabilitiesLongs,
  } = mapFormat;

  // get total figures - return array of numbers
  const totalNonCurrentAssetsLong = getTotalLong(nonCurrentAssetsLongs, totalMonths);
  const totalCurrentAssetsLong = getTotalLong(currentAssetsLongs, totalMonths);
  const totalAssetsLong = Array.from({ length: totalMonths }).map((_, index) => {
    const nca = totalNonCurrentAssetsLong[index] || 0;
    const ca = totalCurrentAssetsLong[index] || 0;
    return nca + ca;
  });
  const totalEquityLong = getTotalLong(equityLongs, totalMonths);
  const totalNonCurrentLiabilitiesLong = getTotalLong(nonCurrentLiabilitiesLongs, totalMonths);
  const totalCurrentLiabilitiesLong = getTotalLong(currentLiabilitiesLongs, totalMonths);
  const totalLiabilitiesLong = Array.from({ length: totalMonths }).map((_, index) => {
    const ncl = totalNonCurrentLiabilitiesLong[index] || 0;
    const cl = totalCurrentLiabilitiesLong[index] || 0;
    return ncl + cl;
  });
  const checkLong = Array.from({ length: totalMonths }).map((_, index) => {
    const assets = totalAssetsLong[index] || 0;
    const equity = totalEquityLong[index] || 0;
    const liabilities = totalLiabilitiesLong[index] || 0;
    return assets - equity - liabilities;
  });

  const result = {
    totalAssetsLong,
    totalNonCurrentAssetsLong,
    totalCurrentAssetsLong,
    totalEquityLong,
    totalLiabilitiesLong,
    totalNonCurrentLiabilitiesLong,
    totalCurrentLiabilitiesLong,
    checkLong,
  };

  items.forEach((item) => {
    if (result.hasOwnProperty(item.description)) {
      item.long = result[item.description];
    }
  });

  return items;
};

const updateSOPL = (items, totalMonths) => {
  const mapFormat = items.reduce((acc, { description, long }) => {
    acc[description] = long;
    return acc;
  }, {});

  const { revenueLongs, expensesLongs, intExpLongs, taxLongs } = mapFormat;

  // get total figures
  const totalRevenueLong = getTotalLong(revenueLongs, totalMonths);
  const totalExpensesLong = getTotalLong(expensesLongs, totalMonths);
  const totalIntExpLong = getTotalLong(intExpLongs, totalMonths);
  const totalTaxLong = getTotalLong(taxLongs, totalMonths);

  const totalPbitLong = Array.from({ length: totalMonths }).map((_, index) => {
    const revenue = totalRevenueLong[index] || 0;
    const expenses = totalExpensesLong[index] || 0;
    return revenue - expenses;
  });
  const totalPbtLong = Array.from({ length: totalMonths }).map((_, index) => {
    const pbit = totalPbitLong[index] || 0;
    const intExp = totalIntExpLong[index] || 0;
    return pbit - intExp;
  });
  const totalProfitLong = Array.from({ length: totalMonths }).map((_, index) => {
    const pbt = totalPbtLong[index] || 0;
    const tax = totalTaxLong[index] || 0;
    return pbt - tax;
  });

  const result = {
    totalRevenueLong,
    totalExpensesLong,
    totalIntExpLong,
    totalTaxLong,
    totalPbitLong,
    totalPbtLong,
    totalProfitLong,
  };

  items.forEach((item) => {
    if (result.hasOwnProperty(item.description)) {
      item.long = result[item.description];
    }
  });

  return items;
};

const updateSOCF = (items, totalMonths) => {
  const mapFormat = items.reduce((acc, { description, long }) => {
    acc[description] = long;
    return acc;
  }, {});

  const { operatingLongs, investingLongs, financingLongs } = mapFormat;

  // get total figures
  const totalOperatingLong = getTotalLong(operatingLongs, totalMonths);
  const totalInvestingLong = getTotalLong(investingLongs, totalMonths);
  const totalFinancingLong = getTotalLong(financingLongs, totalMonths);
  const totalLong = Array.from({ length: totalMonths }).map((_, index) => {
    const operating = totalOperatingLong[index] || 0;
    const investing = totalInvestingLong[index] || 0;
    const financing = totalFinancingLong[index] || 0;
    return operating + investing + financing;
  });

  const result = {
    totalOperatingLong,
    totalInvestingLong,
    totalFinancingLong,
    totalLong,
  };

  items.forEach((item) => {
    if (result.hasOwnProperty(item.description)) {
      item.long = result[item.description];
    }
  });

  return items;
};

const getTotalLong = (longs, totalMonths) => {
  if (longs.length === 0) return Array(totalMonths).fill(0);

  return longs.reduce((acc, curr) => {
    return acc.map((num, idx) => num + curr.long[idx]);
  }, Array(totalMonths).fill(0));
};
