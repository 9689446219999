import styles from "../styles.module.css";
import { useState } from "react";
import { useStore } from "../../store";
import { useSaveContext } from "../../../../saveAndRestore/useSaveContext";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import { ModalEditAssumptions } from "./modal//ModalEditAssumptions";

const HeaderSettings = ({ style }) => {
  const assumptions = useStore.getState().assumptions;
  const updateAssumptions = useStore.getState().updateAssumptions;

  // save
  const { autoSave } = useSaveContext();
  const handleSetAssumptions = (key, value) => {
    updateAssumptions(key, value);
    autoSave();
  };

  // modal
  const [modalState, setModalState] = useState(false);
  const handleOpenModal = (status) => {
    setModalState(status);
  };
  const handleCloseModal = () => {
    setModalState(false);
  };

  return (
    <div className={styles.header_info}>
      <Tooltip title="Settings">
        <IconButton onClick={() => handleOpenModal(true)}>
          <SettingsRoundedIcon style={style} />
        </IconButton>
      </Tooltip>
      {modalState && (
        <ModalEditAssumptions
          open={true}
          handleClose={handleCloseModal}
          assumptions={assumptions}
          setAssumptions={handleSetAssumptions}
        />
      )}
    </div>
  );
};

export default HeaderSettings;
