import styles from "../../styles.module.css";

const Forecast = () => (
  <svg
    className={styles.nav_svg}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.58247 8.36255H5.59498C5.12248 8.36255 4.73999 8.74501 4.73999 9.21751V13.0575H7.58247V8.36255V8.36255Z"
      stroke="currentColor"
      strokeWidth="1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.57093 4.94995H8.43092C7.95842 4.94995 7.57593 5.33246 7.57593 5.80496V13.05H10.4184V5.80496C10.4184 5.33246 10.0434 4.94995 9.57093 4.94995Z"
      stroke="currentColor"
      strokeWidth="1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4111 9.63745H10.4236V13.05H13.2661V10.4925C13.2586 10.02 12.8761 9.63745 12.4111 9.63745Z"
      stroke="currentColor"
      strokeWidth="1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Forecast;
