import { useStore } from "../store";
import { usePriorYearsStore } from "../../../stores/usePriorYearsStore";
import { useStatementsStore } from "../../../stores/useStatementsStore";
import { useStatementsAnnualStore } from "../../../stores/useStatementsAnnualStore";

export const updateStatementsAnnual = () => {
  const assumptions = useStore.getState().assumptions;
  const priorYears = usePriorYearsStore.getState().priorYears;
  const statements = useStatementsStore.getState().statements;
  const annual = useStatementsAnnualStore.getState().statementsAnnual;

  const contextIds = statements.map((statement) => statement.contextId);

  // remove deleted context
  for (let i = annual.length - 1; i >= 0; i--) {
    if (!contextIds.includes(annual[i].contextId)) {
      annual.splice(i, 1);
    }
  }

  // add new context
  contextIds.forEach((contextId, index) => {
    const annualIndex = annual.findIndex((annual) => annual.contextId === contextId);
    const description = statements.find((statement) => statement.contextId === contextId).description;
    const totalPeriod = calculateTotalPeriod(assumptions, priorYears[index]);

    if (annualIndex !== -1) {
      annual[annualIndex].description = description;
      annual[annualIndex].totalPeriod = totalPeriod;
    } else {
      annual.push({
        contextId: contextId,
        description: description,
        totalPeriod: totalPeriod,
        sofp: [],
        sopl: [],
        socf: [],
      });
    }
  });

  // copy over and calculate
  statements.forEach((_, index) => {
    copyOver("sofp", statements[index], annual[index], priorYears[index], assumptions);
    copyOver("sopl", statements[index], annual[index], priorYears[index], assumptions);
    copyOver("socf", statements[index], annual[index], priorYears[index], assumptions);
  });
};

const copyOver = (section, statement, annual, priorYear, assumptions) => {
  const { priorLength } = priorYear;

  const statementSection = statement[section];

  annual[section] = statementSection.map((layer1, layer1Index) => {
    const statementLayer1 = statementSection[layer1Index];

    return {
      description: layer1.description,
      type: layer1.type,

      ...(layer1.type === "array" && {
        long: calculate(section, statementLayer1.long, assumptions, priorLength),
      }),

      ...(layer1.type === "object" && {
        long: layer1.long.map((layer2, layer2Index) => {
          const statementLayer2 = statementLayer1.long[layer2Index];

          return {
            id: layer2.id,
            container: layer2.container,
            description: layer2.description,
            long: calculate(section, statementLayer2.long, assumptions, priorLength),
          };
        }),
      }),
    };
  });
};

const calculate = (section, long, assumptions, priorLength) => {
  const { months, startDate, periodEnd } = assumptions;
  const totalMonths = months + priorLength;
  const startMonth = parseInt(startDate.split("-")[1]);

  let result = [];
  let index = -1;
  let remaining = totalMonths;

  if (section === "sofp") {
    // b/f
    while (index < priorLength - 1) {
      index += 1;
      result.push(long[index]);
      remaining -= 1;
    }
    // index start at -1

    // forecast - first
    if (remaining > 0) {
      let diff = 0;
      if (periodEnd >= startMonth) {
        diff = periodEnd - startMonth + 1;
      } else {
        diff = periodEnd + 12 - startMonth + 1;
      }

      if (remaining > diff) {
        index += diff;
        result.push(long[index]);
        remaining -= diff;
      } else {
        index += remaining;
        result.push(long[index]);
        remaining -= remaining;
      }
    }

    // forecast - middle
    while (remaining >= 12) {
      index += 12;
      result.push(long[index]);
      remaining -= 12;
    }

    // forecast - last
    if (remaining > 0) {
      index += remaining;
      result.push(long[index]);
      remaining -= remaining;
    }
  }

  if (section === "sopl" || section === "socf") {
    // b/f
    while (index < priorLength - 1) {
      index += 1;
      result.push(long[index]);
      remaining -= 1;
    }
    index += 1; // index start at 0

    // forecast - first
    if (remaining > 0) {
      let diff = 0;
      if (periodEnd >= startMonth) {
        diff = periodEnd - startMonth + 1;
      } else {
        diff = periodEnd + 12 - startMonth + 1;
      }

      if (remaining > diff) {
        const sum = long.slice(index, index + diff).reduce((acc, val) => acc + val, 0);
        index += diff;
        result.push(sum);
        remaining -= diff;
      } else {
        const sum = long.slice(index, index + remaining).reduce((acc, val) => acc + val, 0);
        index += remaining;
        result.push(sum);
        remaining -= remaining;
      }
    }

    // forecast - middle
    while (remaining >= 12) {
      const sum = long.slice(index, index + 12).reduce((acc, val) => acc + val, 0);
      index += 12;
      result.push(sum);
      remaining -= 12;
    }

    // forecast - last
    if (remaining > 0) {
      const sum = long.slice(index, index + remaining).reduce((acc, val) => acc + val, 0);
      index += remaining;
      result.push(sum);
      remaining -= remaining;
    }
  }

  return result;
};

const calculateTotalPeriod = (assumptions, priorYear) => {
  const { months, startDate, periodEnd } = assumptions;
  const { priorLength } = priorYear;

  const startMonth = parseInt(startDate.split("-")[1]);

  let remaining = months + priorLength;
  let totalPeriod = 0;

  // priorLength
  totalPeriod += priorLength;
  remaining -= priorLength;

  // initial
  if (remaining > 0) {
    totalPeriod += 1;
    if (periodEnd >= startMonth) {
      remaining -= periodEnd - startMonth + 1;
    } else {
      remaining -= periodEnd + 12 - startMonth + 1;
    }
  }

  totalPeriod += Math.ceil(remaining / 12);
  remaining -= remaining;

  return totalPeriod;
};
