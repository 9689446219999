import { formatDate } from "./Format";

export const getMonthsArray = (months, priorLength) => {
  const bfArray = Array.from({ length: priorLength }, () => "b/f");

  const forecastArray = Array.from({ length: months }, (_, i) => i + 1);

  const monthsArray = [...bfArray, ...forecastArray];
  return monthsArray;
};

export const getMonthsNames = (months, startDate, priorYears) => {
  let bfNames = [];
  const dateStrings = priorYears.headers[0].slice(1, -1);
  bfNames = dateStrings.map((dateString) => formatDate(dateString));

  const forecastNames = Array.from({ length: months }, (_, i) => {
    const [startYear, startMonth] = startDate.split("-").map(Number);
    const date = new Date(startYear, startMonth - 1 + i, 1);
    return new Intl.DateTimeFormat("en-US", { month: "short", year: "2-digit" }).format(date);
  });

  const monthsNames = [...bfNames, ...forecastNames];
  return monthsNames;
};
