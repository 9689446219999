import { useCallback, useMemo, useRef } from "react";
import { useStore } from "./store";
import { useDebounce } from "../../hooks/useDebounce";

import { getColor } from "./minimapColors";

const FlowHandlers = ({ contextId, onNodesChange, onEdgesChange, autoSave, setSelectedId }) => {
  const initialNodes = useStore.getState().nodes;
  const initialEdges = useStore.getState().edges;

  // changes to nodes and edges
  const nodesChangeQueue = useRef([]);
  const edgesChangeQueue = useRef([]);
  const connectQueue = useRef([]);

  const filteredNodes = useMemo(() => {
    return initialNodes.filter((node) => node.contextId === contextId);
  }, [initialNodes, contextId]);
  const filteredEdges = useMemo(() => {
    return initialEdges
      .filter((edge) => edge.contextId === contextId)
      .filter((edge) => edge.visible === true)
      .map((edge) => {
        const type = filteredNodes.find((node) => node.id === edge.source).type;
        const color = getColor(type);
        return {
          ...edge,
          type: "buttonedge",
          animated: true,
          interactionWidth: 20,
          style: { stroke: color, strokeWidth: "0.2rem" },
          className: "edgeShadow",
        };
      });
  }, [initialEdges, contextId, filteredNodes]);

  // Process nodes changes
  const processNodesChanges = useCallback(() => {
    if (nodesChangeQueue.current.length > 0) {
      const storeNodesChange = useStore.getState().onNodesChange;
      storeNodesChange(nodesChangeQueue.current);
      nodesChangeQueue.current = [];
    }
  }, []);

  // Process edges changes
  const processEdgesChanges = useCallback(() => {
    if (edgesChangeQueue.current.length > 0) {
      const storeEdgesChange = useStore.getState().onEdgesChange;
      storeEdgesChange(edgesChangeQueue.current);
      edgesChangeQueue.current = [];
    }
  }, []);

  // Process connect changes
  const processConnectChanges = useCallback(() => {
    if (connectQueue.current.length > 0) {
      const storeAddEdge = useStore.getState().addEdge;
      connectQueue.current.forEach((connection) => storeAddEdge(connection, contextId));
      connectQueue.current = [];
    }
  }, [contextId]);

  // Handle nodes changes
  const debouncedNodesChangeStore = useDebounce(processNodesChanges, 300);
  const handleNodesChange = useCallback(
    (changes) => {
      for (const change of changes) {
        if (change.type === "select") {
          if (change.selected === true) {
            setSelectedId(change.id);
            break;
          } else {
            setSelectedId(null);
          }
        }
      }

      onNodesChange(changes);
      nodesChangeQueue.current.push(...changes);
      debouncedNodesChangeStore();
      autoSave();
    },
    [onNodesChange, debouncedNodesChangeStore, autoSave, setSelectedId]
  );

  // Handle edges changes
  const handleEdgesChange = useCallback(
    (changes) => {
      onEdgesChange(changes);
      edgesChangeQueue.current.push(...changes);
      processEdgesChanges();
      autoSave();
    },
    [onEdgesChange, processEdgesChanges, autoSave]
  );

  // Handle connect changes
  const handleConnect = useCallback(
    (connection) => {
      connectQueue.current.push(connection);
      processConnectChanges();
      autoSave();
    },
    [processConnectChanges, autoSave]
  );

  return {
    filteredNodes,
    filteredEdges,
    handleNodesChange,
    handleEdgesChange,
    handleConnect,
  };
};

export default FlowHandlers;
