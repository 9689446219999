import { useRef } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const colors = [
  "#FF6384", // Red
  "#36A2EB", // Blue
  "#FFCE56", // Yellow
  "#4BC0C0", // Teal
  "#9966FF", // Purple
  "#FF9F40", // Orange
  "#FF6384", // Red
  "#36A2EB", // Blue
  "#FFCE56", // Yellow
  "#4BC0C0", // Teal
];

export const LineGraph = ({ title, xLabel, xData, yLabel, yData1, yData2 }) => {
  const chartRef = useRef(null);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: title,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        title: {
          display: true,
          text: xLabel,
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: yLabel,
        },
      },
    },
  };

  const datasets = [
    {
      label: "Projection",
      data: yData1,
      borderColor: colors[0],
      backgroundColor: colors[0],
      tension: 0.6,
      fill: false,
    },
    {
      label: "Actual",
      data: yData2,
      borderColor: colors[1],
      backgroundColor: colors[1],
      tension: 0.6,
      fill: false,
    },
  ];

  const data = {
    labels: xData,
    datasets: datasets,
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Line ref={chartRef} options={options} data={data} />
    </div>
  );
};
