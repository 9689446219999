import StatementsCSS from "../../styles/pages/Statements.module.css";
import { useState } from "react";

import { useGeneralStore } from "../../stores/useGeneralStore";
import { useStore } from "../flow/store";
import { usePriorYearsStore } from "../../stores/usePriorYearsStore";
import { useStatementsAnnualStore } from "../../stores/useStatementsAnnualStore";
import { useValuationStore } from "../../stores/useValuationStore";
import { useReportStore } from "../../stores/useReportStore";

import Download from "./Download";

const Route = () => {
  const [page, setPage] = useState("Download");

  const contextId = useGeneralStore((state) => state.contextId);

  const assumptions = useStore((state) => state.assumptions);
  const priorYears = usePriorYearsStore((state) => state.priorYears);
  const statementsAnnual = useStatementsAnnualStore((state) => state.statementsAnnual);
  const valuations = useValuationStore((state) => state.valuation);
  const reports = useReportStore((state) => state.report);

  const priorYear = priorYears.find((item) => item.contextId === contextId);
  const annual = statementsAnnual.find((item) => item.contextId === contextId);
  const valuation = valuations.find((item) => item.contextId === contextId);
  const report = reports.find((item) => item.contextId === contextId);

  if (!report) {
    return <div>Select a contextId.</div>;
  }

  const { months, startDate, periodEnd } = assumptions;
  const { priorLength } = priorYear;
  const totalMonths = months + priorLength;
  const totalPeriod = annual.totalPeriod;
  const monthsDetails = { months, startDate, periodEnd, priorYear, priorLength, totalMonths, totalPeriod };

  return (
    <div className={StatementsCSS.statements}>
      <div className={StatementsCSS.routes}>
        <button
          className={`${StatementsCSS.route} ${page === "Download" ? StatementsCSS.active : ""}`}
          onClick={() => setPage("Download")}
        >
          Download
        </button>
      </div>

      {page === "Download" && (
        <Download
          assumptions={assumptions}
          priorYears={priorYear}
          monthsDetails={monthsDetails}
          valuation={valuation}
          report={report}
        />
      )}
    </div>
  );
};

export default Route;
