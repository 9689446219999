import styles from "../styles.module.css";
import { useStore } from "../../store";
import { useSaveContext } from "../../../../saveAndRestore/useSaveContext";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";

const HeaderTools = ({ style, view, contextId }) => {
  const setView = useStore.getState().setView;
  const deleteAll = useStore.getState().deleteAll;
  const allEdgeVisible = useStore.getState().allEdgeVisible;
  const setEdgeVisible = useStore.getState().setEdgeVisible;

  // save
  const { autoSave, onSave } = useSaveContext();
  const handleDelete = () => {
    deleteAll(view, contextId);
    autoSave();
  };

  return (
    <div className={styles.header_info}>
      <Tooltip title="Home">
        <IconButton onClick={() => setView("view0", "")}>
          <HomeIcon style={style} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Save">
        <IconButton onClick={onSave}>
          <SaveIcon style={style} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete All">
        <IconButton onClick={handleDelete}>
          <DeleteIcon style={style} />
        </IconButton>
      </Tooltip>
      {/* <Tooltip title="Expand">
          <IconButton onClick={store.expand}>
            <ExpandMoreIcon style={style} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Collapse">
          <IconButton onClick={store.collapse}>
            <ExpandLessIcon style={style} />
          </IconButton>
        </Tooltip> */}
      <Tooltip title="Toggle edge visibility">
        <IconButton onClick={setEdgeVisible}>
          {allEdgeVisible ? (
            <VisibilityOffRoundedIcon style={style} />
          ) : (
            <VisibilityRoundedIcon style={{ ...style, color: "red" }} />
          )}
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default HeaderTools;
