import StatementsCSS from "../../styles/pages/Statements.module.css";
import { useState } from "react";

import { useGeneralStore } from "../../stores/useGeneralStore";
import { useStore } from "../flow/store";
import { usePriorYearsStore } from "../../stores/usePriorYearsStore";
import { useStatementsStore } from "../../stores/useStatementsStore";
import { useStatementsAnnualStore } from "../../stores/useStatementsAnnualStore";

import SwitchMonthly from "../../components/switchMonthly/SwitchMonthly";

import SOFP from "./SOFP";
import SOPL from "./SOPL";
import SOCF from "./SOCF";

const Route = () => {
  const [page, setPage] = useState("SOFP");

  const contextId = useGeneralStore((state) => state.contextId);
  const monthly = useGeneralStore((state) => state.monthly);

  const assumptions = useStore((state) => state.assumptions);
  const priorYears = usePriorYearsStore((state) => state.priorYears);
  const statements = useStatementsStore((state) => state.statements);
  const statementsAnnual = useStatementsAnnualStore((state) => state.statementsAnnual);

  const priorYear = priorYears.find((item) => item.contextId === contextId);
  const statement = statements.find((item) => item.contextId === contextId);
  const annual = statementsAnnual.find((item) => item.contextId === contextId);

  if (!statement) {
    return <div>Select a contextId.</div>;
  }
  if (statement.sofp.length === 0) {
    return <div>SOFP node is not present in the context.</div>;
  }
  if (statement.sopl.length === 0) {
    return <div>SOPL node is not present in the context.</div>;
  }
  if (statement.sofp.length === 0) {
    return <div>SOCF node is not present in the context.</div>;
  }

  const { months, startDate, periodEnd } = assumptions;
  const { priorLength } = priorYear;
  const totalMonths = months + priorLength;
  const totalPeriod = annual.totalPeriod;
  const monthsDetails = { months, startDate, periodEnd, priorYear, priorLength, totalMonths, totalPeriod };

  return (
    <div className={StatementsCSS.statements}>
      <div className={StatementsCSS.routes}>
        <button
          className={`${StatementsCSS.route} ${page === "SOFP" ? StatementsCSS.active : ""}`}
          onClick={() => setPage("SOFP")}
        >
          SOFP
        </button>
        <button
          className={`${StatementsCSS.route} ${page === "SOPL" ? StatementsCSS.active : ""}`}
          onClick={() => setPage("SOPL")}
        >
          SOPL
        </button>
        <button
          className={`${StatementsCSS.route} ${page === "SOCF" ? StatementsCSS.active : ""}`}
          onClick={() => setPage("SOCF")}
        >
          SOCF
        </button>
        <SwitchMonthly />
      </div>

      {page === "SOFP" && (
        <SOFP monthly={monthly} monthsDetails={monthsDetails} statement={statement} annual={annual} />
      )}
      {page === "SOPL" && (
        <SOPL monthly={monthly} monthsDetails={monthsDetails} statement={statement} annual={annual} />
      )}
      {page === "SOCF" && (
        <SOCF monthly={monthly} monthsDetails={monthsDetails} statement={statement} annual={annual} />
      )}
    </div>
  );
};

export default Route;
