import DashboardCSS from "../../styles/pages/Dashboard.module.css";

const Navigator = ({ selected, onSelect }) => {
  const categories = [
    {
      heading: "Liquidity Ratios",
      options: ["Current Ratio", "Working Capital", "Sales to Working Capital Ratio"],
    },
    {
      heading: "Activity Ratios",
      options: ["Asset Turnover"],
    },
    {
      heading: "Profitability Ratios",
      options: ["Net Profit Margin"],
    },
    {
      heading: "Coverage Ratios",
      options: ["Debt to Equity Ratio"],
    },
  ];

  return (
    <div className={DashboardCSS.navigator}>
      {categories.map((category) => (
        <div key={category.heading}>
          <h3 className={DashboardCSS.category_heading}>{category.heading}</h3>
          <div className={DashboardCSS.dropdown_content}>
            {category.options.map((option) => (
              <label key={option} className={DashboardCSS.dropdown_item}>
                <input
                  name="checkbox"
                  type="checkbox"
                  value={option}
                  checked={selected.some((item) => item === option)}
                  onChange={() => onSelect(option)}
                />
                {option}
              </label>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Navigator;
